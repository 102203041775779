import { FC, useCallback, useState } from 'react';
import {
    Box,
    Chip,
    Grid,
    Tooltip,
    Card,
    Checkbox,
    FormControlLabel,
    IconButton,
    Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ReactDOM from 'react-dom';
import { HiOutlineUserAdd } from 'react-icons/hi';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTranslation } from 'react-i18next';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { debounce } from 'lodash';
import { CONSTANTS } from 'common/constants';
import documentTypeProviderRequests from 'clients/manager/document-type-provider.request';
import { usePlatformContext } from 'routes/platform.context';
import {
    addNotificationError,
    addNotificationApiError,
    addNotificationSuccess,
    formatDate,
} from 'common/utils';
import Markdown from 'common/components/markdown';
import { AttachmentOutlinedIcon } from 'common/icons';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { TypeAccess } from 'common/interfaces/user.interface';
import { HistoryOutlined, Settings } from '@material-ui/icons';
import { DeepPartial } from 'redux';
import { getChipSegmentLabel } from 'common/utils/getters/get-chip-segment-label.utils';
import { useParams } from 'react-router';
import mime from 'mime';
import {
    DocumentTypeDocument,
    DocumentTypesProvidersHistoric,
    EvaluatingStatusType,
    SegmentationTypeEnum,
} from '../../../../interfaces/document-type-provider.interface';
import UploadFile from '../../../upload-file';
import { DocumentTypeProviderSegment } from '../../interfaces';
import { useProviderUpdateRequestWarningContext } from '../../../provider-update-request-warning/context';
import ChipStatusView from '../provider-document-item/chip-status-view';
import ModalEditDocuments from '../modal-edit-documents';
import ModalHistoricDocuments from '../modal-historic-documents';
import useStyles from './styles';

export interface ProviderSingleDocumentItemProps {
    documents: DocumentTypeProviderSegment[];
    disabled?: boolean;
}

const ProviderDocumentItem: FC<ProviderSingleDocumentItemProps> = ({
    documents,
    disabled = false,
}) => {
    const [providerDocuments, setProviderDocuments] =
        useState<DocumentTypeProviderSegment[]>(documents);
    const [providerDocumentSelected, setProviderDocumentSelected] =
        useState<DocumentTypeProviderSegment>(documents[0]);
    const classes = useStyles();
    const { t } = useTranslation();
    const { platform } = usePlatformContext();
    const { setForceHasChanges } = useProviderUpdateRequestWarningContext();
    const { authUserTokenData } = useSelector((state: AppState) => state.authUserState);
    const [selectedUpdateRequest, setSelectedUpdateRequest] = useState<boolean>();
    const maxUploadSize = Number(platform?.fileSettings?.maxUploadSizeProvider) * 1024 * 1024;
    const [openHistoricModal, setOpenHistoricModal] = useState<boolean>(false);
    const [documentsHistoric, setDocumentsHistoric] = useState<DocumentTypesProvidersHistoric[]>(
        []
    );
    const { idProvider } = useParams<{ idProvider: string }>();

    const handleChangeFile = async (
        file: File,
        providerDocument: DocumentTypeProviderSegment,
        providerId?: number
    ) => {
        if (!file) {
            addNotificationError({
                title: t('error.to-update-files-title'),
                message: t('error.to-update-files-message'),
            });

            return;
        }

        try {
            const supplyCategoryIdSingle =
                providerDocument.segmentationType === SegmentationTypeEnum.single
                    ? providerDocument?.segmentsId?.[0]
                    : undefined;

            const isManagement = authUserTokenData?.typeAccess === TypeAccess.management;
            const dataUpload = await documentTypeProviderRequests.uploadDocumentTypeProvider(
                file,
                providerDocument.id,
                supplyCategoryIdSingle,
                isManagement ? providerId : undefined
            );

            const newFileUpload =
                dataUpload.data as DocumentTypeProviderSegment['documentTypesProviders'];
            const { fileName, dateTimeUpdate, url } = newFileUpload;

            ReactDOM.unstable_batchedUpdates(() => {
                setProviderDocuments((state) => {
                    const prevDocuments = state.slice();
                    const index = prevDocuments.findIndex(
                        (doc) => doc?.segmentsId?.[0] === providerDocument?.segmentsId?.[0]
                    );

                    if (index >= 0) {
                        prevDocuments[index] = {
                            ...providerDocument,
                            dateTimeUpdate,
                            documentTypesProviders: {
                                ...newFileUpload,
                                evaluatingStatus: isManagement
                                    ? EvaluatingStatusType.APROVADO
                                    : EvaluatingStatusType.AGUARDANDO_AVALIACAO,
                                fileName,
                                url,
                            },
                        };
                    }

                    return prevDocuments;
                });
                setForceHasChanges(true);
                addNotificationSuccess();
            });
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const toogleDeclarationState = async (
        declarationAccepted: boolean,
        providerDocument: DocumentTypeProviderSegment
    ) => {
        try {
            await documentTypeProviderRequests.createDocumentTypeProviderDeclaration(
                declarationAccepted,
                providerDocument.id as number
            );

            ReactDOM.unstable_batchedUpdates(() => {
                setProviderDocuments((state) =>
                    state.map((document) => {
                        if (document?.segmentsId?.[0] === providerDocument?.segmentsId?.[0]) {
                            return {
                                ...document,
                                documentTypesProviders: {
                                    ...document?.documentTypesProviders,
                                    declarationAccepted,
                                    evaluatingStatus: EvaluatingStatusType.AGUARDANDO_AVALIACAO,
                                },
                            };
                        }
                        return document;
                    })
                );
                setForceHasChanges(true);
                addNotificationSuccess();
            });
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const getDropZoneSettings = useCallback(
        (providerDocument: DocumentTypeProviderSegment) =>
            ({
                disabled: disabled,
                multiple: false,
                maxSize: platform?.fileSettings?.maxUploadSizeProvider
                    ? maxUploadSize ?? CONSTANTS.defaultFileMaxSize
                    : CONSTANTS.defaultFileMaxSize,
                accept:
                    platform?.fileSettings?.extensions.map((extension) =>
                        mime.getType(extension)
                    ) ?? '*',
                onDrop(acceptedFiles) {
                    if (authUserTokenData?.typeAccess === TypeAccess.management) {
                        handleChangeFile(acceptedFiles[0], providerDocument, Number(idProvider));
                    } else handleChangeFile(acceptedFiles[0], providerDocument);
                },
            } as DropzoneOptions),
        [platform]
    );

    const getIconButton = (
        evaluatingStatusType: EvaluatingStatusType,
        justificationOfFailure: string
    ) => {
        let iconType: string;

        if (evaluatingStatusType === EvaluatingStatusType.REPROVADO) {
            iconType = 'rejectedIcon';
        } else if (evaluatingStatusType === EvaluatingStatusType.AGUARDANDO_ATI_VCTE) {
            iconType = 'pendingIcon';
        } else if (evaluatingStatusType === EvaluatingStatusType.NAO_ENVIADO) {
            iconType = 'notSendIcon';
        } else if (evaluatingStatusType === EvaluatingStatusType.AGUARDANDO_AVALIACAO) {
            iconType = 'awaitAvaliationIcon';
        } else {
            return null;
        }
        return (
            <IconButton classes={{ root: classes[iconType] }}>
                <Tooltip title={<span className={classes.font14}>{justificationOfFailure}</span>}>
                    <InfoIcon />
                </Tooltip>
            </IconButton>
        );
    };

    const setProviderDocumentModal = (providerDocument) => {
        setProviderDocumentSelected(providerDocument);
        setSelectedUpdateRequest(true);
    };

    const handleValuesUpdate = useCallback(
        (updatedValues: DeepPartial<DocumentTypeProviderSegment>) => {
            setProviderDocuments((prevProviderDocuments) =>
                prevProviderDocuments.map((providerDocument) => {
                    if (
                        providerDocument?.documentTypesProviders?.id &&
                        providerDocument.documentTypesProviders.id === updatedValues?.id
                    ) {
                        return {
                            ...providerDocument,
                            documentTypesProviders: {
                                ...providerDocument.documentTypesProviders,
                                ...updatedValues,
                            },
                        };
                    }
                    return providerDocument;
                })
            );
        },
        []
    );

    const listDocumentsHistoric = async (providerDocument) => {
        try {
            const listDocumentsHistoric =
                await documentTypeProviderRequests.listDocumentTypeProviderHistory(
                    providerDocument.documentTypesProviders.id as number
                );
            ReactDOM.unstable_batchedUpdates(() => {
                setDocumentsHistoric(listDocumentsHistoric);
                setOpenHistoricModal(true);
            });
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    return (
        <Box mt={1}>
            {selectedUpdateRequest && (
                <ModalEditDocuments
                    documentName={providerDocumentSelected?.name}
                    DocumentTypesProviders={providerDocumentSelected.documentTypesProviders}
                    onClose={() => setSelectedUpdateRequest(undefined)}
                    onUpdateValues={handleValuesUpdate}
                />
            )}
            {openHistoricModal && (
                <ModalHistoricDocuments
                    DocumentTypesProviders={documentsHistoric}
                    onClose={() => setOpenHistoricModal(false)}
                />
            )}
            <Card className={classes.card}>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={6} md={6} className={classes.gridColumn}>
                        <Box display='flex' alignItems='center'>
                            <Typography gutterBottom variant='subtitle2'>
                                {Boolean(providerDocuments?.[0]?.canItContainPersonalData) && (
                                    <HiOutlineUserAdd
                                        title={t('common.provider-documents.private-document')}
                                        className={classes.privateDocumentIcon}
                                    />
                                )}
                                {providerDocuments?.[0]?.name}
                            </Typography>
                            {providerDocuments?.[0]?.documentRequired ? (
                                <Typography gutterBottom color='error' className={classes.font12}>
                                    {` (${t('term.required')})`}
                                </Typography>
                            ) : null}
                        </Box>
                        {providerDocuments?.[0]?.typeDocument ===
                        DocumentTypeDocument.declaration ? undefined : (
                            <Markdown value={providerDocuments?.[0]?.description} />
                        )}
                    </Grid>
                    <>
                        {providerDocuments?.map((providerDocument) => {
                            const dropzoneProps = useDropzone(
                                getDropZoneSettings(providerDocument)
                            );

                            let updatedDate = '';
                            if (providerDocument) {
                                updatedDate = `${formatDate(
                                    providerDocument.dateTimeUpdate ??
                                        providerDocument.dateTimeInsert,
                                    'DD/MM/YY HH:mm'
                                )}`;
                            }

                            return (
                                <Grid
                                    key={providerDocument.id}
                                    container
                                    justifyContent='space-between'
                                    alignItems='center'
                                    className={classes.marginBottom10}
                                >
                                    {providerDocument.segments?.length ? (
                                        <Grid item xs={3} md={3} className={classes.gridSegment}>
                                            {providerDocument.segments.map((segment) => (
                                                <Tooltip
                                                    className={classes.tooltipSegment}
                                                    disableHoverListener={
                                                        !!segment?.segmentName &&
                                                        segment?.segmentName?.length < 40
                                                    }
                                                    title={segment.segmentName}
                                                >
                                                    <Chip
                                                        size='small'
                                                        className={classes.chipSegment}
                                                        label={getChipSegmentLabel(segment)}
                                                        color='primary'
                                                    />
                                                </Tooltip>
                                            ))}
                                        </Grid>
                                    ) : null}
                                    {providerDocument?.typeDocument ===
                                        DocumentTypeDocument.declaration && (
                                        <Grid item xs={3} md={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked={
                                                            providerDocument?.documentTypesProviders
                                                                ?.declarationAccepted
                                                        }
                                                    />
                                                }
                                                label={
                                                    <Markdown
                                                        value={providerDocument?.description}
                                                    />
                                                }
                                                onChange={debounce(
                                                    (event: React.ChangeEvent<any>) =>
                                                        toogleDeclarationState(
                                                            event.target.checked,
                                                            providerDocument
                                                        ),
                                                    500
                                                )}
                                                disabled={disabled}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={3} md={2}>
                                        {providerDocument.documentTypesProviders.url &&
                                        providerDocument?.typeDocument !==
                                            DocumentTypeDocument.declaration ? (
                                            <>
                                                <a
                                                    href={
                                                        providerDocument.documentTypesProviders.url
                                                    }
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    className={classes.textDecorationNone}
                                                >
                                                    <Typography
                                                        variant='body2'
                                                        align='center'
                                                        className={classes.viewFileText}
                                                    >
                                                        <AttachmentOutlinedIcon
                                                            className={classes.colorPaletteDark}
                                                            fontSize='small'
                                                        />
                                                        {t('info.view-file')}
                                                    </Typography>
                                                </a>
                                                <a title={t('info.uploaded-file')}>
                                                    <Typography className={classes.updatedFile}>
                                                        {updatedDate}
                                                    </Typography>
                                                </a>
                                            </>
                                        ) : (
                                            <Typography />
                                        )}
                                    </Grid>
                                    {providerDocument.typeDocument ===
                                    DocumentTypeDocument.upload ? (
                                        <>
                                            <Grid item xs={3} md={2} className={classes.boxFlex}>
                                                <Box mr={2} ml={2} width={1}>
                                                    <UploadFile
                                                        className={classes.paddingBox}
                                                        disabled={disabled}
                                                        {...dropzoneProps}
                                                        label={
                                                            <Box className={classes.boxFlex}>
                                                                <CloudUploadIcon
                                                                    className={
                                                                        classes.cloudUploadIcon
                                                                    }
                                                                />
                                                                <Typography variant='body2'>
                                                                    {t('term.upload')}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid item xs={3} md={1} className={classes.boxFlex}>
                                                {providerDocument.documentTypesProviders.id && (
                                                    <Typography
                                                        variant='body2'
                                                        align='center'
                                                        className={classes.viewHistoricText}
                                                        onClick={() => {
                                                            listDocumentsHistoric(providerDocument);
                                                        }}
                                                    >
                                                        <HistoryOutlined
                                                            className={classes.colorPaletteMain}
                                                            fontSize='small'
                                                        />
                                                        {t('info.view-historic')}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item xs={3} md={1} />
                                    )}
                                    {providerDocument.typeDocument ===
                                        DocumentTypeDocument.reference && (
                                        <Grid item xs={3} md={3} className={classes.boxFlex}>
                                            <IconButton
                                                color='secondary'
                                                size='small'
                                                className={classes.infoIcon}
                                                disabled={disabled}
                                            >
                                                <InfoIcon />
                                            </IconButton>
                                            <Typography
                                                variant='body2'
                                                className={classes.referenceDescription}
                                            >
                                                {t('info.provider-document-reference-description')}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item md={2} xs={3} className={classes.gridFlexEnd}>
                                        {providerDocument.typeDocument !==
                                        DocumentTypeDocument.reference ? (
                                            <>
                                                {providerDocument.documentTypesProviders
                                                    .justificationOfFailure &&
                                                    getIconButton(
                                                        providerDocument.documentTypesProviders
                                                            .evaluatingStatus,
                                                        providerDocument.documentTypesProviders
                                                            .justificationOfFailure
                                                    )}
                                                <ChipStatusView
                                                    status={
                                                        providerDocument.documentTypesProviders
                                                            .evaluatingStatus
                                                    }
                                                    dueDate={
                                                        providerDocument?.documentTypesProviders
                                                            .dueDate
                                                    }
                                                />
                                            </>
                                        ) : null}
                                        {providerDocument.typeDocument ===
                                            DocumentTypeDocument.reference && (
                                            <Chip
                                                size='small'
                                                className={classes.chipReference}
                                                label={t('info.provider-document-reference')}
                                                color='primary'
                                            />
                                        )}
                                    </Grid>
                                    <Grid item md={1} xs={12} className={classes.gridFlexEnd2}>
                                        {authUserTokenData?.typeAccess === TypeAccess.management &&
                                            providerDocument.documentTypesProviders.id && (
                                                <IconButton
                                                    onClick={() =>
                                                        setProviderDocumentModal(providerDocument)
                                                    }
                                                    disabled={disabled}
                                                >
                                                    <Settings />
                                                </IconButton>
                                            )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </>
                </Grid>
            </Card>
        </Box>
    );
};

export default ProviderDocumentItem;
