import { doRequest, managerApiInstance } from 'clients/api';
import {
    GenericBody,
    GenericResponse,
    PlainGenericResponse,
} from 'clients/interfaces/paginated.interface';
import {
    ListMarketplaceItemsBody,
    ListMarketplaceItemsResponse,
    ListMarketplaceProcessResponse,
    ListSupplyCategoriesByOrganizationResponse,
    MarketplaceCheckoutBody,
} from 'modules/marketplace/interfaces/marketplace.interface';
import { AxiosRequestConfig } from 'axios';
import { OkResponse } from 'clients/interfaces/ok.interface';
import { ordersRequests } from './orders.requests';
import { marketplaceCartRequests } from './marketplace-cart.requests';
import { contractsRequests } from './contracts.request';

const BASE_URL = '/marketplace';

const marketplaceRequests = {
    listMarketplaceProcesses: async () =>
        doRequest<GenericResponse<ListMarketplaceProcessResponse>>(
            managerApiInstance.post(`${BASE_URL}/listMarketplaceProcesses`, {})
        ),
    listSupplyCategoriesByOrganization: async () =>
        doRequest<GenericResponse<ListSupplyCategoriesByOrganizationResponse>>(
            managerApiInstance.post(`${BASE_URL}/listSupplyCategoriesByOrganization`)
        ),
    listMarketplaceItems: async (
        body?: GenericBody<ListMarketplaceItemsBody>,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<GenericResponse<ListMarketplaceItemsResponse>>(
            managerApiInstance.post(`${BASE_URL}/listMarketplaceItems`, body, config)
        ),
    doCheckout: async (body?: MarketplaceCheckoutBody, config?: AxiosRequestConfig<any>) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`${BASE_URL}/checkout`, body, config)
        ),
    ...ordersRequests,
    ...marketplaceCartRequests,
    ...contractsRequests,
};

export { marketplaceRequests };
