import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentTypeDocument } from 'common/interfaces/document-type-provider.interface';
import Markdown from 'common/components/markdown';
import { AttachmentOutlinedIcon } from 'common/icons';
import { TableCompareDocumentDataProps } from '../interfaces';
import { useStyles } from './styles';

const TableDocumentsView: React.FC<TableCompareDocumentDataProps> = ({ documents }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const actionDescription = (declarationAccepted: number) => {
        return `${
            declarationAccepted ? t('term.checked-statement') : t('term.unchecked-statement')
        }: `;
    };

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCellTitle}>{t('term.action')}</TableCell>
                        <TableCell className={classes.tableCellTitle}>
                            {t('term.document')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documents.map((doc) => {
                        return (
                            <TableRow>
                                <TableCell className={classes.tableCellTitle}>
                                    {t(`term.${doc.type}`)}
                                </TableCell>
                                <TableCell>
                                    {doc.data.typeDocument === DocumentTypeDocument.declaration &&
                                        actionDescription(doc.data.declarationAccepted)}
                                    {doc.data.url && (
                                        <a
                                            href={doc.data.url}
                                            target='_blank'
                                            rel='noreferrer'
                                            className={classes.viewFileLinkSimple}
                                        >
                                            <Typography
                                                variant='body2'
                                                align='center'
                                                className={classes.typoAttachment}
                                            >
                                                <AttachmentOutlinedIcon
                                                    className={classes.palettePrimaryDark}
                                                    fontSize='small'
                                                />
                                                <Typography className={classes.viewFileText}>
                                                    {t('info.view-file')}
                                                </Typography>
                                            </Typography>
                                        </a>
                                    )}
                                    <Markdown value={doc.data.fileName} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableDocumentsView;
