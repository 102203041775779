import React, { PropsWithChildren } from 'react';

import AccordionView from './accordion-view';

type AccordionProps = PropsWithChildren<{
    title: string;
    value?: string;
    open?: boolean;
    onChange?: (value?: string) => void;
    icon?: React.ReactNode;
}>;

const Accordion: React.FC<AccordionProps> = ({
    title,
    value,
    onChange,
    children,
    icon,
    open = false,
}) => {
    const handleOnChange = () => {
        onChange?.(value);
    };

    return (
        <AccordionView
            title={title}
            value={value}
            open={open}
            icon={icon}
            onChange={handleOnChange}
        >
            {children}
        </AccordionView>
    );
};

export default Accordion;
