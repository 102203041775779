import makeStyles from '@material-ui/core/styles/makeStyles';
import { formatPxToRem } from 'common/utils';

const defaultSpacing = formatPxToRem(15);

const useStyles = makeStyles(() => ({
    root: {
        background: '#FFFD',
        width: '60vw',
        padding: 20,
    },
    marginBottom: {
        marginBottom: defaultSpacing,
    },
    spinner: {
        color: 'inherit',
        marginRight: formatPxToRem(20),
    },
    twoGridColumns: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: defaultSpacing,
        marginTop: defaultSpacing,
        marginBottom: defaultSpacing,
    },
    flex: {
        display: 'flex',
        gap: formatPxToRem(10),
    },
}));

export default useStyles;
