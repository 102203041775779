import React, { FC, useState } from 'react';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { ContractStatus, ContractWithDetails } from 'clients/manager/interfaces/contract.interface';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslation } from 'react-i18next';
import { ProcessDocumentsStepsType } from 'clients/manager/interfaces/process-documents-steps.interface';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import { contractRequests } from 'clients/manager/contract.requests';
import ConfirmDialogWithJustification from 'common/components/confirm-dialog-with-justification';
import { contractActions } from 'modules/process/document-utils';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { redirectToGenerateDocument } from '../../redirect-generate-document';
import { getBackofficeRedirectUrl } from '../../../../utils';
import ModalRatingContracts from '../modal-rating-contracts';

interface ContractActionsProps {
    contractWithDetails: ContractWithDetails;
    onEditContract: () => void;
    handleContractUpdated?: (data: ContractWithDetails) => void;
    deleteContract?: (data: ContractWithDetails) => Promise<void>;
    deleteContractFromPncp?: (contractId: string, justification: string) => Promise<void>;
    actionButton?: any;
}

const ContractActions: FC<ContractActionsProps> = ({
    contractWithDetails,
    onEditContract,
    handleContractUpdated,
    deleteContract,
    deleteContractFromPncp,
    ...props
}) => {
    const { t } = useTranslation();
    const { processDocumentsStep, generatedDocument, contract } = contractWithDetails;
    const backofficeRedirectUrlPath = getBackofficeRedirectUrl();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openModalRatingContracts, setOpenModalRatingContracts] = useState(false);
    const { setCheckProcess } = useProcessFormContext();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const visibleGenerateDocumentOption =
        processDocumentsStep?.type === ProcessDocumentsStepsType.document &&
        contract.status === ContractStatus.draft &&
        !generatedDocument?.file;

    const visibleDeleteContract =
        contract.status !== ContractStatus.obsolete &&
        contract.status !== ContractStatus.concluded &&
        !contract.contractOriginId;

    const visibleDeleteContractPncp = contractActions.canDeletePncp(contract);

    const visibleReGenerateDocumentOption =
        processDocumentsStep?.type === ProcessDocumentsStepsType.document &&
        (contract.status === ContractStatus.generated ||
            contract.status === ContractStatus.signatures) &&
        generatedDocument?.file;

    const handleClickGenerateDocument = async (regenerate: boolean) => {
        handleClose();
        const isGenerated = await redirectToGenerateDocument(
            contract.id,
            regenerate,
            backofficeRedirectUrlPath
        );
        if (isGenerated && handleContractUpdated) {
            const response = await contractRequests.getContract(contract.id);
            handleContractUpdated(response.data);
        }
        setCheckProcess('generate-document');
    };

    const disablePreviewDocument = !generatedDocument?.file?.location;

    const handleClickPreviewDocument = () => {
        if (disablePreviewDocument) {
            return;
        }

        handleClose();
        window.open(generatedDocument.file?.location, '_blank');
    };

    const handleClickModalRatingContracts = () => {
        handleClose();
        setOpenModalRatingContracts(true);
    };

    const handleCloseModalRatingContracts = () => setOpenModalRatingContracts(false);

    return (
        <>
            {props.actionButton ? (
                <props.actionButton
                    aria-label='more'
                    aria-controls='long-menu'
                    aria-haspopup='true'
                    onClick={handleClick}
                />
            ) : (
                <IconButton
                    aria-label='more'
                    aria-controls='long-menu'
                    aria-haspopup='true'
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
            )}
            <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        onEditContract();
                    }}
                >
                    {t('info.view-edit-data')}
                </MenuItem>
                {visibleGenerateDocumentOption && (
                    <MenuItem onClick={() => handleClickGenerateDocument(false)}>
                        {t('info.generate-doc')}
                    </MenuItem>
                )}
                {visibleReGenerateDocumentOption && (
                    <ConfirmDialog
                        title={t('info.regenerate-contract-title')}
                        message={t('info.renegerate-contract-description')}
                        onConfirm={() => handleClickGenerateDocument(true)}
                    >
                        <MenuItem>{t('info.generate-doc-again')}</MenuItem>
                    </ConfirmDialog>
                )}
                <MenuItem disabled={disablePreviewDocument} onClick={handleClickPreviewDocument}>
                    {t('info.view-document')}
                </MenuItem>
                {visibleDeleteContract && (
                    <ConfirmDialog
                        title={t('info.delete-contract-title')}
                        message={t('info.delete-contract-description')}
                        onConfirm={() => deleteContract?.(contractWithDetails)}
                    >
                        <MenuItem>{t('term.delete-contract')}</MenuItem>
                    </ConfirmDialog>
                )}
                {visibleDeleteContractPncp && (
                    <ConfirmDialogWithJustification
                        title={t('info.delete-contract-title')}
                        message={t('info.delete-contract-description-pncp')}
                        onConfirm={(justification) =>
                            deleteContractFromPncp?.(contract.id, justification)
                        }
                    >
                        <MenuItem>{t('term.delete-contract-from-pncp')}</MenuItem>
                    </ConfirmDialogWithJustification>
                )}
                {contract?.status === 'concluded' && (
                    <MenuItem onClick={handleClickModalRatingContracts}>
                        {t('info.evaluate')}
                    </MenuItem>
                )}
            </Menu>
            {openModalRatingContracts && (
                <ModalRatingContracts
                    {...{ open: openModalRatingContracts }}
                    contractWithDetails={contractWithDetails as ContractWithDetails}
                    onClose={handleCloseModalRatingContracts}
                />
            )}
        </>
    );
};

export default ContractActions;
