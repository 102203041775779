import { FC, useEffect, useState } from 'react';
import { Badge, Box, Collapse, TableCell, TableRow, IconButton } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import PublishIcon from '@material-ui/icons/Publish';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
    ProcessDocumentsStepsSigner,
    ProcessDocumentsStepsStatus,
    ProcessDocumentsStepsType,
} from 'clients/manager/interfaces/process-documents-steps.interface';
import { ProcessDocumentsStepsResume } from 'clients/manager/documents/process-documents-steps.requests';
import { AuctionNotice } from 'clients/manager/interfaces/auction-notice.interface';
import { getTemplateTypesLabel } from 'common/utils';
import DocumentSigners from 'common/components/contracts/components/document-signers';
import { GeneratedDocumentSigner } from 'clients/manager/interfaces/generated-documents.interface';
import { documentActions, documentsTypesContract } from 'modules/process/document-utils';
import ModalCreateDocument from '../modal-create-document';
import ChipDocumentStepStatus from '../chip-document-step-status';
import StepActions from '../step-actions';
import GeneratedRowView from './generated-row-view';

interface RowViewProps {
    processDocumentsStep: ProcessDocumentsStepsResume;
    onClickDocument: () => void;
    onUpdateProcessDocumentStep: (step: ProcessDocumentsStepsResume) => void;
    onDeleteProcessDocumentStep: (step: ProcessDocumentsStepsResume) => void;
    process: AuctionNotice | undefined;
}

const RowView: FC<RowViewProps> = ({
    onClickDocument,
    processDocumentsStep: step,
    onUpdateProcessDocumentStep,
    onDeleteProcessDocumentStep,
    process,
}) => {
    const [open, setOpen] = useState(false);
    const [visibleModalEditStep, setVisibleModalEditStep] = useState(false);
    const [intendedOrReceivedSigners, setIntendedOrReceivedSigners] = useState<
        GeneratedDocumentSigner[] | ProcessDocumentsStepsSigner[] | undefined
    >(undefined);

    const { t } = useTranslation();

    const onClickEditProcessDocumentStep = () => setVisibleModalEditStep(true);

    const verifySigners = () => {
        const generatedDocumentSigner = step.generatedDocuments?.flatMap(
            (documents) => documents.signers ?? []
        );
        const signersData =
            step.status !== ProcessDocumentsStepsStatus.cancelled &&
            step.status !== ProcessDocumentsStepsStatus.draft &&
            generatedDocumentSigner?.length
                ? generatedDocumentSigner
                : step.signers;
        return signersData;
    };

    const generatedDocumentsCount = step.totalGenerated;
    const isGeneratedDocumentFromStep = () =>
        (step.type === ProcessDocumentsStepsType.document && generatedDocumentsCount > 0) ||
        [
            ProcessDocumentsStepsStatus.generated,
            ProcessDocumentsStepsStatus.awaiting_signatures,
            ProcessDocumentsStepsStatus.concluded,
        ].includes(step.status);

    useEffect(() => {
        isGeneratedDocumentFromStep();
    }, [step.status]);

    useEffect(() => {
        const signers = verifySigners();
        setIntendedOrReceivedSigners(signers);
    }, [step.signers, step.generatedDocuments]);

    const getStatus = () => {
        const isAllSignedDocuments = step.generatedDocuments?.some((document) => {
            const verifySignedAt =
                document.signers?.length && document.signers?.every(({ signedAt }) => signedAt);
            return verifySignedAt;
        });
        const generatedDocument = step?.generatedDocuments && step.generatedDocuments[0];
        if (process && step.generatedDocuments?.length && step.documentType) {
            const isProcessCanSendPncp = documentActions.canSendPncp(process, generatedDocument);
            if (generatedDocument?.pncpLink) {
                return ProcessDocumentsStepsStatus.concluded;
            }
            if (
                isProcessCanSendPncp &&
                isAllSignedDocuments &&
                step.status !== ProcessDocumentsStepsStatus.concluded
            ) {
                return ProcessDocumentsStepsStatus.signed;
            }
            if (
                !isProcessCanSendPncp &&
                isAllSignedDocuments &&
                !documentsTypesContract.includes(step.documentType)
            ) {
                return ProcessDocumentsStepsStatus.concluded;
            }
            if (documentsTypesContract.includes(step.documentType)) {
                return isAllSignedDocuments
                    ? ProcessDocumentsStepsStatus.signed
                    : ProcessDocumentsStepsStatus.awaiting_signatures;
            }
        }
        return step.status;
    };

    return (
        <>
            {visibleModalEditStep && process && (
                <ModalCreateDocument
                    onClose={() => setVisibleModalEditStep(false)}
                    process={process}
                    processDocumentsStep={step}
                    onUpdateProcessDocumentStep={onUpdateProcessDocumentStep}
                />
            )}
            <TableRow hover onClick={onClickDocument}>
                <TableCell padding='none' align='center'>
                    <IconButton size='small'>
                        {step.type === ProcessDocumentsStepsType.document && (
                            <DescriptionIcon
                                titleAccess={t('info.doc-created-by-generator')}
                                style={{
                                    color: '#818080',
                                }}
                            />
                        )}
                        {step.type === ProcessDocumentsStepsType.upload && (
                            <PublishIcon
                                titleAccess={t('info.doc-uploaded')}
                                style={{
                                    color: '#818080',
                                }}
                            />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell>{step.name}</TableCell>
                <TableCell>
                    {step.documentType ? getTemplateTypesLabel(step.documentType) : '-'}
                </TableCell>
                <TableCell>
                    {intendedOrReceivedSigners?.length ? (
                        <DocumentSigners
                            signers={intendedOrReceivedSigners.map(({ name, email, signedAt }) => {
                                return {
                                    name: name,
                                    email: email,
                                    signatureDate: signedAt ? new Date(signedAt) : null,
                                };
                            })}
                        />
                    ) : (
                        <>{t('term.none')}</>
                    )}
                </TableCell>
                <TableCell>
                    <ChipDocumentStepStatus status={getStatus()} />
                </TableCell>
                <TableCell>
                    {isGeneratedDocumentFromStep() ? t('term.yes') : t('term.no')}
                </TableCell>
                <TableCell padding='none'>
                    <StepActions
                        processDocumentsStep={step}
                        onDeleteProcessDocumentStep={() => onDeleteProcessDocumentStep(step)}
                        onUpdateProcessDocumentStep={onUpdateProcessDocumentStep}
                        onClickEditProcessDocumentStep={onClickEditProcessDocumentStep}
                    />
                </TableCell>
                <TableCell padding='none'>
                    {generatedDocumentsCount > 0 && (
                        <Badge badgeContent={generatedDocumentsCount} color='secondary'>
                            <IconButton
                                aria-label='expand row'
                                size='small'
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </Badge>
                    )}
                </TableCell>
            </TableRow>
            {open && (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                        <Collapse in={open} timeout='auto' unmountOnExit>
                            <Box pt={2} pb={2}>
                                <GeneratedRowView step={step} />
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default RowView;
