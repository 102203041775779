import { createStyles, makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
    .MuiAppBar-root {
        box-shadow: none;
    }

    .MuiAppBar-colorPrimary {
        background-color: transparent;
    }

    .MuiToolbar-root {
        justify-content: space-between;
    }

    .header-menu {
        display: flex;
    }

    .header-buttons {
        display: flex;
        gap: 15px;
    }

    .header-brand {
        @media (max-width: 500px) {
            display: none;
        }
    }
`;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            padding: 0,
        },
        appBar: { height: '70px', maxHeight: '70px', overflow: 'hidden' },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        menuItem: {
            minHeight: '50px',
        },
        separator: {
            textTransform: 'uppercase',
            background: '#fafafa',
            borderBottom: '1px solid #ddd',
            padding: theme.spacing(1),
            fontWeight: 600,
            fontSize: 13,
        },
    })
);
