import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import NotFound from 'routes/not-found';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { usePageContext } from 'common/components/base/pages/context';
import { marketplaceRequests } from 'modules/marketplace/services/marketplace.requests';
import ContractPageView from './contract-page-view';

const ContractPage = () => {
    const { t } = useTranslation();
    const { setTitle } = usePageContext();
    const history = useHistory();
    const { orderId } = useParams<{ orderId?: string }>();

    const [selectedContracts, setSelectedContracts] = useState<string[]>([]);

    if (!orderId) {
        return <NotFound />;
    }

    const handleSelectContract = (contractId: string) => {
        setSelectedContracts((state) => {
            const newState = state.filter((item) => item !== contractId);
            if (state.length > newState.length) {
                return newState;
            }
            return [...state, contractId];
        });
    };

    const verifyIfIsContractSelected = (contractId: string) => {
        return !!selectedContracts?.find((item) => item === contractId);
    };

    const goToCheckout = () => {
        const searchParams = new URLSearchParams(
            Object.entries({ selectedContracts }).flatMap(([key, values]) =>
                Array.isArray(values) ? values.map((value) => [key, value]) : [[key, values]]
            )
        );

        history.push(`/mercado/pedidos/checkout/${orderId}?${searchParams}`);
    };

    const orderFilter = useMemo(
        () => ({
            orderId: Number(orderId),
        }),
        [orderId]
    );

    const { data: contractData, loading: loadingContract } = useQueryWithBody(
        marketplaceRequests.listContractsByOrderId,
        orderFilter
    );
    const contracts = contractData?.data;

    const selectedContractsValues = contracts?.filter((item) =>
        selectedContracts.includes(item.contract.id)
    );
    const totalValuesSelected = useMemo(
        () =>
            selectedContractsValues?.reduce(
                (acc, act) => acc + (act?.contract?.accumulatedValue ?? 0),
                0
            ) ?? 0,
        [selectedContracts, contracts]
    );

    useEffect(() => {
        setTitle(t('marketplace.contract.title'));
    });

    return (
        <ContractPageView
            contracts={contracts}
            loading={loadingContract}
            selectedContracts={selectedContracts}
            onSelectContract={handleSelectContract}
            verifyContractIsSelected={verifyIfIsContractSelected}
            totalValuesSelected={totalValuesSelected}
            goToCheckout={goToCheckout}
        />
    );
};

export default ContractPage;
