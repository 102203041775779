import { Box } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { FC, useState } from 'react';
import { DocumentSignersProps } from './props';
import ModalSignersList from './modal-signers-view';
import SignerAvatarView from './signer-avatar-view';
import { useStylesDocumentSigners } from './styles';

const DocumentSigners: FC<DocumentSignersProps> = ({
    signers,
    canDeleteSigner,
    documentSignerId,
    processId,
}) => {
    const classes = useStylesDocumentSigners();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <>
            {signers && modalIsOpen && (
                <ModalSignersList
                    signers={signers}
                    onClose={() => setModalIsOpen(false)}
                    canDeleteSigner={canDeleteSigner}
                    documentSignerId={documentSignerId}
                    processId={processId}
                />
            )}
            <Box>
                <AvatarGroup
                    classes={{ root: classes.root }}
                    max={5}
                    onClick={() => setModalIsOpen(true)}
                >
                    {signers?.map((signer) => (
                        <SignerAvatarView signer={signer} />
                    ))}
                </AvatarGroup>
            </Box>
        </>
    );
};

export default DocumentSigners;
