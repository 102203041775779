import { makeStyles } from '@material-ui/core';
import { formatPxToRem } from 'common/utils';

const useStyles = makeStyles(() => ({
    cardContainer: {
        borderRadius: '8px',
        padding: formatPxToRem(20),
        marginBottom: formatPxToRem(15),
    },
    divider: {
        marginTop: formatPxToRem(20),
        marginBottom: formatPxToRem(20),
    },
    divider2: {
        marginTop: formatPxToRem(10),
        marginBottom: formatPxToRem(20),
    },
    headerTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    headerSubtitleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: formatPxToRem(10),
    },
    redirectButtonContainer: {
        display: 'flex',
        gap: formatPxToRem(10),
    },
    footerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: formatPxToRem(20),
    },
    footerPriceListContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: formatPxToRem(20),
    },
    footerPriceItem: {
        display: 'flex',
        gap: formatPxToRem(10),
    },
    footerPriceItemValue: {
        fontWeight: 700,
    },
}));

export { useStyles };
