import React, { PropsWithChildren } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMoreIcon } from 'common/icons';
import useStyles from './accordion-style';

type AccordionViewProps = PropsWithChildren<{
    title: string;
    open: boolean;
    onChange: () => void;
    value?: string;
    icon?: React.ReactNode;
}>;

const AccordionView: React.FC<AccordionViewProps> = ({
    onChange,
    open,
    title,
    value,
    icon,
    children,
}) => {
    const classes = useStyles({ open });

    return (
        <Accordion
            elevation={0}
            expanded={open}
            onChange={onChange}
            className={classes.accordionContainer}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-content-${value}`}
                id={`panel-header-${value}`}
                className={classes.accordionSummary}
            >
                <div className={classes.titleContainer}>
                    {icon && <div className={classes.icon}>{icon}</div>}
                    <Typography className={classes.title}>{title}</Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>{children}</AccordionDetails>
        </Accordion>
    );
};

export default AccordionView;
