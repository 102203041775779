import React from 'react';
import { Grid } from '@material-ui/core';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import Breadcrumb from 'common/components/base/breadcrumb';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { OrganizationData } from 'clients/manager/interfaces/organization.interface';
import { Contract } from 'clients/manager/interfaces/contract.interface';
import Payment from './components/steps/payment';
import { CheckoutFormValues, CheckoutStage } from './checkout-page';
import Confirmation from './components/steps/confirmation';
import Success from './components/steps/success';
import CheckoutSummaryCard from './components/checkout-summary-card';

interface CheckoutPageViewProps {
    form: FormikProps<CheckoutFormValues>;
    orderId?: number;
    organization?: OrganizationData;
    selectedContracts?: Contract[];
    loading?: boolean;
    isConfirmButtonDisabled?: boolean;
}

const CheckoutPageView: React.FC<CheckoutPageViewProps> = ({
    form,
    orderId,
    organization,
    selectedContracts,
    loading = false,
    isConfirmButtonDisabled = false,
}) => {
    const history = useHistory();
    const { t } = useTranslation();

    const confirmLabelOptions = {
        [CheckoutStage.PAYMENT]: t('marketplace.checkout.select-payment-method'),
        [CheckoutStage.CONFIRMATION]: t('marketplace.checkout.finish-checkout'),
        [CheckoutStage.SUCCESS]: t('marketplace.checkout.back-to-order'),
    };

    return (
        <PageWrapper>
            <Breadcrumb
                customPath='mercado/pedidos/empenho/checkout'
                paths={{
                    mercado: {
                        value: t('term.marketplace'),
                        onClick: () => history.replace('/mercado'),
                    },
                    pedidos: {
                        value: t('term.orders'),
                        onClick: () => history.replace('/mercado/pedidos'),
                    },
                    empenho: {
                        value: t('marketplace.contract.title'),
                        onClick: () => history.replace(`/mercado/pedidos/empenho/${orderId}`),
                    },
                    checkout: { value: t('term.checkout'), disabled: true },
                }}
            />
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        {form.values.stage === CheckoutStage.PAYMENT && (
                            <Payment form={form} loading={loading} />
                        )}
                        {form.values.stage === CheckoutStage.CONFIRMATION && (
                            <Confirmation organization={organization} form={form} />
                        )}
                        {form.values.stage === CheckoutStage.SUCCESS && (
                            <Success type={form?.values?.type} orderId={orderId} />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <CheckoutSummaryCard
                            title={t('marketplace.checkout.purchase-resume')}
                            items={selectedContracts ?? []}
                            orderId={orderId}
                            confirmLabel={confirmLabelOptions[form.values.stage]}
                            onConfirm={() => form.handleSubmit()}
                            disabled={isConfirmButtonDisabled}
                            loading={form.isSubmitting || loading}
                        />
                    </Grid>
                </Grid>
            </form>
        </PageWrapper>
    );
};

export default CheckoutPageView;
