import { FC } from 'react';
import { ContractWithDetails } from 'clients/manager/interfaces/contract.interface';
import { contractRequests } from 'clients/manager/contract.requests';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import { pncpRequests } from 'clients/manager/pncp.requests';
import { useTranslation } from 'react-i18next';
import { useSearchContractsContext } from '../../context/search-contracts-context';
import SearchContractsActionsView from './search-contracts-actions-view';

export interface SearchContractsActionsProps {
    contractWithDetails: ContractWithDetails;
    actionButton?: (props: any) => React.ReactNode;
}

const SearchContractsActions: FC<SearchContractsActionsProps> = ({
    contractWithDetails,
    actionButton,
}) => {
    const { setContracts } = useSearchContractsContext();
    const { t } = useTranslation();

    const deleteContract = async (contractWithDetails: ContractWithDetails) => {
        try {
            const data = await contractRequests.deleteContract(contractWithDetails.contract.id);

            if (data.data.ok) {
                return setContracts((prevState) =>
                    prevState.filter(
                        (item) => item?.contract?.id !== contractWithDetails.contract.id
                    )
                );
            }
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const deleteContractOrAta = async (
        itsPriceRegistration: boolean,
        contractId: string,
        justification: string
    ) => {
        if (itsPriceRegistration) {
            return pncpRequests.deleteAta(contractId, justification);
        }
        return pncpRequests.deleteContract(contractId, justification);
    };

    const deleteContractFromPncp = async (contractId: string, justification: string) => {
        try {
            const response = await deleteContractOrAta(
                contractWithDetails.process.itsPriceRegistration,
                contractId,
                justification
            );

            setContracts((prevState) =>
                prevState.map((item) => {
                    if (item?.contract?.id === contractWithDetails.contract.id) {
                        return {
                            ...contractWithDetails,
                            contract: {
                                ...contractWithDetails.contract,
                                ...response.data,
                            },
                        };
                    }

                    return item;
                })
            );
            addNotificationSuccess({
                message: t('info.contract-deleted-pncp'),
                title: t('term.success'),
            });
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    return (
        <SearchContractsActionsView
            setContracts={setContracts}
            deleteContract={deleteContract}
            deleteContractFromPncp={deleteContractFromPncp}
            contractWithDetails={contractWithDetails}
            actionButton={actionButton}
        />
    );
};

export default SearchContractsActions;
