import { Box, makeStyles } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { FC, useState } from 'react';
import { StepSignersProps } from './props';
import ModalSignersList from './modal-signers-view';
import SignerAvatarView from './signer-avatar-view';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiAvatar-root': {
            fontSize: '1rem',
            width: '32px',
            height: '32px',
        },
        '& .MuiAvatar-img': {
            borderRadius: '50%',
        },
    },
}));

/**
 *
 * @deprecated Use `<DocumentSigners />`
 *
 * Inicialmente, o componente foi declarado como StepSigners.
 * Porém o mesmo é utilizado nas assinaturas recebidas e não apenas
 * nas assinaturas pretendidas. Logo, o componente `<DocumentSigners />` foi implementado
 * e deve ser utilizado no lugar.
 */
const StepSigners: FC<StepSignersProps> = ({ signers, canDeleteSigner, signerId, processId }) => {
    const classes = useStyles();
    const [visibleSignersModal, setVisibleSignersModal] = useState(false);

    return (
        <>
            {visibleSignersModal && (
                <ModalSignersList
                    signers={signers}
                    onClose={() => setVisibleSignersModal(false)}
                    canDeleteSigner={canDeleteSigner}
                    documentSignerId={signerId}
                    processId={processId}
                />
            )}
            <Box>
                <AvatarGroup
                    classes={{ root: classes.root }}
                    max={5}
                    onClick={() => setVisibleSignersModal(true)}
                >
                    {signers?.map((signer) => (
                        <SignerAvatarView signer={signer} />
                    ))}
                </AvatarGroup>
            </Box>
        </>
    );
};

export default StepSigners;
