import { Box } from '@material-ui/core';
import { contractRequests } from 'clients/manager/contract.requests';
import UploadDocuments from 'modules/process/components/process-documents-data/components/upload-documents';
import {
    UploadedFile,
    UploadedFileStatusTypes,
} from 'modules/process/components/process-documents-data/components/upload-documents/props';
import { FC } from 'react';
import { UploadContractProps } from './props';
import { addNotificationError } from '../../../../../../common/utils';

const UploadContract: FC<UploadContractProps> = ({
    contractId,
    generatedDocument,
    disabled,
    onUploadedGeneratedDocument,
    onDeletedGeneratedDocument,
    viewMode,
    canDelete,
    showMessage,
}) => {
    const listFiles = async () => {
        if (!generatedDocument) {
            return [];
        }

        return [
            {
                file: undefined,
                name: generatedDocument.file?.name,
                preview: generatedDocument.file?.location,
                status: UploadedFileStatusTypes.accepted,
                id: String(contractId),
                progress: 100,
                originalId: contractId,
            },
        ];
    };

    const handleDeleteFile = async () => {
        try {
            await contractRequests.deleteContractFile(contractId);
            onDeletedGeneratedDocument();
        } catch (error) {
            addNotificationError({});
        }
    };

    const uploadFile = async (
        uploadedFile: UploadedFile,
        cbProgress: (progress: number) => void
    ) => {
        const response = await contractRequests.uploadContract(
            contractId,
            uploadedFile.file,
            cbProgress
        );

        onUploadedGeneratedDocument(response.data);
        return response;
    };

    return (
        <Box mt={1}>
            <UploadDocuments
                listFiles={listFiles}
                onDeleteFile={handleDeleteFile}
                onUploadFile={uploadFile}
                options={{
                    multiple: false,
                    accept: 'application/pdf',
                    disabled,
                }}
                skeletonRenderRows={1}
                acceptFilesInfo={false}
                uploadType='single'
                viewMode={viewMode}
                canDelete={canDelete}
                showMessage={showMessage}
            />
        </Box>
    );
};

export default UploadContract;
