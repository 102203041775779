import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    chipStatusDone: {
        backgroundColor: theme.palette.success.main,
        fontWeight: 600,
    },
    chipStatusError: {
        backgroundColor: theme.palette.error.main,
        fontWeight: 600,
    },
    chipStatusFinished: {
        backgroundColor: theme.palette.grey[600],
        fontWeight: 600,
    },
    chipStatusPending: {
        backgroundColor: theme.palette.warning.main,
        fontWeight: 600,
    },
    chipStatusInfo: {
        backgroundColor: theme.palette.info.main,
        fontWeight: 600,
    },
}));
