import React, { useEffect, useMemo } from 'react';

import { useHistory, useParams } from 'react-router';
import NotFound from 'routes/not-found';
import { marketplaceRequests } from 'modules/marketplace/services/marketplace.requests';
import { usePaginatedQuery } from 'common/hooks/use-paginated-query.hook';
import { OrderItemStatusEnum, OrderStatusEnum, PaymentStrategy } from 'common/enums';
import Yup from 'common/utils/yup-extended.utils';
import { FormikHelpers, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { organizationRequests } from 'clients/manager/organization.requests';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import {
    addNotificationApiError,
    addNotificationSuccess,
    validateCnpj,
    validateCpf,
} from 'common/utils';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import CheckoutPageView from './checkout-page-view';

export enum CheckoutStage {
    PAYMENT = 'PAYMENT',
    CONFIRMATION = 'CONFIRMATION',
    SUCCESS = 'SUCCESS',
}

export interface CheckoutFormValues {
    type: PaymentStrategy;
    stage: CheckoutStage;
    name: string;
    number: string;
    expiry: string;
    cvc: string;
    installments: string;
    useUserData: 'true' | 'false';
    useOrganizationAddress: 'true' | 'false';
    holderName?: string;
    email?: string;
    cpfCnpj?: string;
    postalCode?: string;
    addressNumber?: string;
    addressComplement?: string;
    phone?: string;
}

interface CheckoutPageProps {}

const CheckoutPage: React.FC<CheckoutPageProps> = () => {
    const { orderId } = useParams<{ orderId?: string }>();
    const searchParams = new URLSearchParams(window.location.search);
    const contractIds = searchParams.getAll('selectedContracts');

    if (!orderId || contractIds.length === 0) {
        return <NotFound />;
    }

    const history = useHistory();
    const { t } = useTranslation();

    const { authUser } = useSelector((state: AppState) => state.authUserState);

    const contractFilters = useMemo(
        () => ({
            contractIds,
        }),
        []
    );

    const { data: contractsData, loading: loadingOrder } = usePaginatedQuery(
        marketplaceRequests.listContractsWithItems,
        contractFilters
    );
    const selectedContracts = contractsData?.data;

    const { data: organization, loading: loadingOrganization } = useQueryWithBody(
        organizationRequests.getOrganization
    );

    const userDataFromToken = {
        holderName: authUser?.name ?? '',
        cpfCnpj: authUser?.cpf ?? '',
        email: authUser?.email ?? '',
        phone: authUser?.phone ?? '',
    };

    const addressDataFromOrganization = {
        postalCode: organization?.zipCode ?? '',
        addressNumber: organization?.numberPlace ?? '',
        addressComplement: organization?.complementPlace ?? '',
    };

    const initialValues: CheckoutFormValues = {
        stage: CheckoutStage.PAYMENT,
        type: PaymentStrategy.asaas_boleto,
        useOrganizationAddress: 'true',
        useUserData: 'true',
        name: '',
        number: '',
        expiry: '',
        cvc: '',
        installments: '1',
        ...userDataFromToken,
        ...addressDataFromOrganization,
    };

    const validationSchema = Yup.object().shape({
        type: Yup.mixed().oneOf(Object.values(PaymentStrategy), t('term.required-field')),
        stage: Yup.mixed().oneOf(Object.values(CheckoutStage), t('term.required-field')),
        name: Yup.string().when('type', {
            is: PaymentStrategy.asaas_credit_card,
            then: Yup.string().required(t('term.required-field')).max(20),
            otherwise: Yup.string().nullable().optional(),
        }),
        number: Yup.string().when('type', {
            is: PaymentStrategy.asaas_credit_card,
            then: Yup.string()
                .required(t('term.required-field'))
                .max(20, t('bidding.checkout.payment.max_16_characters'))
                .min(16, t('bidding.checkout.payment.max_14_characters')),
            otherwise: Yup.string().nullable().optional(),
        }),
        expiry: Yup.string().when('type', {
            is: PaymentStrategy.asaas_credit_card,
            then: Yup.string()
                .required(t('term.required-field'))
                .max(5, t('bidding.checkout.payment.max_4_characters'))
                .min(5, t('bidding.checkout.payment.min_4_characters')),
            otherwise: Yup.string().nullable().optional(),
        }),
        cvc: Yup.string().when('type', {
            is: PaymentStrategy.asaas_credit_card,
            then: Yup.string().required(t('term.required-field')).max(3).min(3),
            otherwise: Yup.string().nullable().optional(),
        }),
        holderName: Yup.string().when('type', {
            is: PaymentStrategy.asaas_credit_card,
            then: Yup.string().required(t('term.required-field')).max(60),
            otherwise: Yup.string().nullable().optional(),
        }),
        email: Yup.string().when('type', {
            is: PaymentStrategy.asaas_credit_card,
            then: Yup.string()
                .required(t('term.required-field'))
                .email(t('please-enter-a-valid-email', { ns: 'validation' })),
            otherwise: Yup.string().nullable().optional(),
        }),
        cpfCnpj: Yup.string().when('type', {
            is: PaymentStrategy.asaas_credit_card,
            then: Yup.string()
                .required(t('term.required-field'))
                .test(
                    'cpf-or-cnpj-test',
                    t('cpf-or-cnpj-invalid', { ns: 'validation' }),
                    (value) => {
                        if (value?.length === 14) return validateCnpj(value ?? '');
                        if (value?.length === 11) return validateCpf(value ?? '');
                        return false;
                    }
                ),
            otherwise: Yup.string().nullable().optional(),
        }),
        postalCode: Yup.string().when('type', {
            is: PaymentStrategy.asaas_credit_card,
            then: Yup.string()
                .required(t('please-fill-in-the-cep', { ns: 'validation' }))
                .max(9, t('cep-min-number-character', { ns: 'validation' })),
            otherwise: Yup.string().nullable().optional(),
        }),
        addressNumber: Yup.string().when('type', {
            is: PaymentStrategy.asaas_credit_card,
            then: Yup.string().required(t('number', { ns: 'validation' })),
            otherwise: Yup.string().nullable().optional(),
        }),
        phone: Yup.string().when('type', {
            is: PaymentStrategy.asaas_credit_card,
            then: Yup.string().required(t('please-enter-a-phone', { ns: 'validation' })),
            otherwise: Yup.string().nullable().optional(),
        }),
    });

    const onSubmit = async (
        values: CheckoutFormValues,
        formikHelpers: FormikHelpers<CheckoutFormValues>
    ) => {
        if (values.stage === CheckoutStage.PAYMENT) {
            formikHelpers.setFieldValue('stage', CheckoutStage.CONFIRMATION);
            return;
        }

        if (values.stage === CheckoutStage.CONFIRMATION) {
            try {
                const response = await marketplaceRequests.doCheckout({
                    orderId: Number(orderId),
                    contractIds,
                    dueDate: new Date().toISOString(),
                    strategy: values?.type,
                    installments:
                        values?.type === PaymentStrategy.asaas_credit_card ? 1 : undefined,
                    data: {
                        name: values?.name,
                        number: values?.number?.replace(' ', ''),
                        expiryMonth: values?.expiry?.split('/')?.[0],
                        expiryYear: values?.expiry?.split('/')?.[1],
                        ccv: values.cvc,
                        holderName: values?.name,
                        email: values?.email,
                        cpfCnpj: values?.cpfCnpj,
                        postalCode: values?.postalCode,
                        addressNumber: values?.addressNumber,
                        addressComplement: values?.addressComplement,
                        phone: values?.phone,
                        mobilePhone: values?.phone,
                    },
                });

                if (response.data.ok) {
                    addNotificationSuccess();
                    formikHelpers.setFieldValue('stage', CheckoutStage.SUCCESS);
                }
            } catch (error) {
                addNotificationApiError(error);
            }
        }

        if (values.stage === CheckoutStage.SUCCESS) {
            history.replace(`/mercado/pedidos/empenho/${orderId}`);
        }
    };

    const form = useFormik({ initialValues, validationSchema, onSubmit });

    const loading = loadingOrder || loadingOrganization;

    useEffect(() => {
        if (form.values.useUserData === 'true') {
            form.setValues((state) => ({ ...state, ...userDataFromToken }));
        }
    }, [form.values.useUserData]);

    useEffect(() => {
        if (form.values.useOrganizationAddress === 'true') {
            form.setValues((state) => ({ ...state, ...addressDataFromOrganization }));
        }
    }, [form.values.useOrganizationAddress, organization]);

    return (
        <CheckoutPageView
            form={form}
            orderId={Number(orderId)}
            organization={organization}
            selectedContracts={selectedContracts}
            loading={loading}
            isConfirmButtonDisabled={form.isSubmitting || loading}
        />
    );
};

export default CheckoutPage;
