import React from 'react';

import { OrganizationData } from 'clients/manager/interfaces/organization.interface';
import { FormikProps } from 'formik';
import ConfirmationView from './confirmation-view';
import { CheckoutFormValues } from '../../../checkout-page';

interface ConfirmationProps {
    organization?: OrganizationData;
    form?: FormikProps<CheckoutFormValues>;
}

const Confirmation: React.FC<ConfirmationProps> = ({ organization, form }) => {
    return <ConfirmationView organization={organization} form={form} />;
};

export default Confirmation;
