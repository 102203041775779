import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    IconButton,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { Modal } from 'common/components';
import { createStyles, makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from 'common/icons';
import { documentSignerRequests } from 'clients/manager/document-signer.requests';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import {
    DocumentSignersSignatureStatus,
    Signer,
} from 'clients/manager/interfaces/document-signers.interface';
import { useSearchContractsContext } from 'modules/contracts/pages/search-contracts/context/search-contracts-context';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import { useContractsContext } from 'modules/process/components/process-contracts-data/context/contracts.context';
import SignerAvatarView from './signer-avatar-view';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: 0,
            margin: 0,
            '& .MuiListItemIcon-root': {
                minWidth: 30,
            },
        },
        icon: {
            width: 18,
            height: 18,
        },
        icon2: {
            width: 22,
            height: 22,
        },
    })
);

interface ModalSignersListProps {
    signers: Signer[];
    onClose?: () => void;
    canDeleteSigner?: boolean;
    documentSignerId?: string;
    processId?: number;
}

const ModalSignersList: FC<ModalSignersListProps> = ({
    signers,
    onClose,
    canDeleteSigner,
    documentSignerId,
    processId,
}) => {
    const {
        i18n: { language, t },
    } = useTranslation();
    const classes = useStyles();
    moment.locale(language);

    const { refreshContracts } = useSearchContractsContext();
    const { updateContractsFromProcess } = useContractsContext();

    const removeSigner = async (indexSigner: number) => {
        const filterSigner = signers.filter((signer, index) => index !== indexSigner);
        try {
            if (documentSignerId && filterSigner) {
                const result = await documentSignerRequests.updateContractSigners(
                    {
                        params: {
                            signers: filterSigner,
                        },
                    },
                    documentSignerId
                );
                if (result.status === 'success') {
                    if (processId) {
                        updateContractsFromProcess(processId);
                    }
                    refreshContracts();
                    addNotificationSuccess({
                        title: t('info.update-contract-signatures'),
                        message: t('info.update-contract-signatures-success'),
                    });
                }
            }
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const enableRemoveContractSigner = (signer: Signer) =>
        canDeleteSigner &&
        signers.length > 2 &&
        signer.signatureStatus === DocumentSignersSignatureStatus.pendente &&
        !signer.signatureDate;

    return (
        <Modal header={<span>{t('term.subscribers')}</span>} open onClose={onClose}>
            <Box
                style={{
                    minWidth: '400px',
                    maxWidth: '400px',
                }}
            >
                <List style={{ padding: 0 }}>
                    {signers?.map((signer, index) => (
                        <>
                            <ListItem
                                classes={{ root: classes.root }}
                                key={`ModalSignersList-${index}`}
                            >
                                <ListItemIcon>
                                    <IconButton edge='start'>
                                        <SignerAvatarView signer={signer} />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText
                                    primary={signer.name || signer.email}
                                    secondary={
                                        <>
                                            <Typography variant='body2' color='textPrimary'>
                                                {signer.signatureDate ? (
                                                    `${t('info.signed-in')} ${moment(
                                                        signer.signatureDate
                                                    ).format('LLL')}`
                                                ) : (
                                                    <span>{t('info.not-signer')}</span>
                                                )}
                                            </Typography>
                                        </>
                                    }
                                />
                                {enableRemoveContractSigner(signer) && (
                                    <ConfirmDialog
                                        title={t('term.remove-contract-signer-alert')}
                                        message={t('term.remove-contract-signer')}
                                        onConfirm={() => {
                                            removeSigner(index);
                                        }}
                                    >
                                        <IconButton
                                            size='small'
                                            edge='end'
                                            title='Deletar assinatura'
                                        >
                                            <DeleteIcon className={classes.icon} />
                                        </IconButton>
                                    </ConfirmDialog>
                                )}
                            </ListItem>
                            <Divider variant='fullWidth' component='li' />
                        </>
                    ))}
                </List>
            </Box>
        </Modal>
    );
};

export default ModalSignersList;
