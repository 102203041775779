import { ChipProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ContractStatus } from 'clients/manager/interfaces/contract.interface';
import { StatusPayment } from 'common/enums';
import { getContractStatus, getStatusPayment } from 'common/utils';
import { useStyles } from './contract-status-chip-style';
import ContractStatusChipView from './contract-status-chip-view';

interface ContractStatusChipProps extends ChipProps {
    status: string;
}

const ContractStatusChip: React.FC<ContractStatusChipProps> = ({ status }) => {
    const styles = useStyles();
    const { t } = useTranslation();

    const styleMapper = {
        [ContractStatus.draft]: styles.chipStatusPending,
        [ContractStatus.signatures]: styles.chipStatusPending,
        [ContractStatus.generated]: styles.chipStatusInfo,
        [ContractStatus.concluded]: styles.chipStatusDone,
        [ContractStatus.obsolete]: styles.chipStatusError,
        [StatusPayment.pending]: styles.chipStatusPending,
        [StatusPayment.paid]: styles.chipStatusDone,
        [StatusPayment.free]: styles.chipStatusDone,
        [StatusPayment.canceled]: styles.chipStatusError,
    };

    const labelMapper = {
        [ContractStatus.draft]: getContractStatus(status as any),
        [ContractStatus.signatures]: getContractStatus(status as any),
        [ContractStatus.generated]: getContractStatus(status as any),
        [ContractStatus.concluded]: getContractStatus(status as any),
        [ContractStatus.obsolete]: getContractStatus(status as any),
        [StatusPayment.pending]: getContractStatus(status as any),
        [StatusPayment.paid]: getStatusPayment(status as any),
        [StatusPayment.free]: getStatusPayment(status as any),
        [StatusPayment.canceled]: getStatusPayment(status as any),
    };

    const defaultProps: ChipProps = {
        label: labelMapper[status] ?? t('term.pending'),
        className: styleMapper[status] ?? styles.chipStatusPending,
    };

    return <ContractStatusChipView {...defaultProps} />;
};

export default ContractStatusChip;
