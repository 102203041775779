import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import Breadcrumb from 'common/components/base/breadcrumb';
import { EmptyList, SummaryCard, MarketplaceItemCard } from 'modules/marketplace/components';
import {
    CreateMarketplaceOrderBody,
    MarketplaceCartItem,
    MarketplaceCartResponse,
} from 'modules/marketplace/interfaces/marketplace.interface';
import { Modal, ModalPosition } from 'common/components';
import AdditionalInformationForm from './components/additional-information-form';

interface CartPageViewProps {
    cartItems?: MarketplaceCartItem[];
    onAddToCart?: (id: number) => Promise<void>;
    onRemoveFromCart?: (id: number) => Promise<void>;
    onItemQuantityChange?: (params: { id: number; quantity: number }) => Promise<void>;
    marketplaceCart?: MarketplaceCartResponse;
    additionalInformationIsVisible?: boolean;
    onOpenAdditionalInformation?: () => void;
    onCloseAdditionalInformation?: () => void;
    onCreateOrder?: (values: CreateMarketplaceOrderBody) => Promise<void>;
    isCreating?: boolean;
}

const CartPageView: React.FC<CartPageViewProps> = ({
    cartItems = [],
    onAddToCart,
    onRemoveFromCart,
    onItemQuantityChange,
    onOpenAdditionalInformation,
    onCloseAdditionalInformation,
    onCreateOrder,
    additionalInformationIsVisible = false,
    isCreating = false,
    marketplaceCart,
}) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <Breadcrumb
                customPath='mercado/carrinho'
                paths={{
                    mercado: {
                        value: t('term.marketplace'),
                        onClick: () => history.replace('/mercado'),
                    },
                    carrinho: { value: t('term.cart'), disabled: true },
                }}
            />
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    {isEmpty(cartItems) && (
                        <EmptyList
                            title={t('marketplace.cart.empty-cart')}
                            subtitle={t('marketplace.cart.can-access-orders')}
                            href='/mercado/pedidos'
                        />
                    )}
                    {cartItems.map((cartItem) => (
                        <MarketplaceItemCard
                            key={cartItem.id}
                            item={cartItem.item as any}
                            onAddToCart={onAddToCart}
                            onRemoveFromCart={onRemoveFromCart}
                            onItemQuantityChange={onItemQuantityChange}
                        />
                    ))}
                </Grid>
                <Grid item xs={3}>
                    <SummaryCard
                        hideDeadline
                        title={t('term.marketplace-purchase-summary')}
                        items={marketplaceCart?.items}
                        onConfirm={onOpenAdditionalInformation}
                        isConfirming={isCreating}
                    />
                </Grid>
            </Grid>
            <Modal
                open={additionalInformationIsVisible}
                onClose={onCloseAdditionalInformation}
                position={ModalPosition.center}
                header={
                    <Typography color='inherit' variant='h5'>
                        {t('marketplace.cart.additional-informations')}
                    </Typography>
                }
            >
                <AdditionalInformationForm
                    onSubmit={onCreateOrder}
                    onClose={onCloseAdditionalInformation}
                />
            </Modal>
        </PageWrapper>
    );
};

export default CartPageView;
