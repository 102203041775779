import makeStyles from '@material-ui/core/styles/makeStyles';
import { formatPxToRem } from 'common/utils';

const useStyles = makeStyles((theme) => ({
    boxBorder: {
        padding: `1rem`,
    },
    backToPayment: {
        fontSize: formatPxToRem(15),
        display: 'flex',
        cursor: 'pointer',
        marginBottom: theme.spacing(2),
    },
    backIcon: {
        marginRight: formatPxToRem(3),
    },
    title: {
        display: 'flex',
        marginBottom: theme.spacing(3),
    },
    container: {
        padding: theme.spacing(0),
    },
    boxGrey: {
        background: theme.palette.background.default,
        padding: theme.spacing(1, 2),
    },
    planText: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        rowGap: theme.spacing(1),
    },
    iconBenefit: { marginRight: theme.spacing(1) },
    boxBenefit: { display: 'flex' },
    boxButton: {
        display: 'flex',
        marginTop: theme.spacing(3),
        justifyContent: 'flex-end',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(6),
        width: '100%',
    },
}));

export default useStyles;
