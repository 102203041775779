import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import PageWrapper from 'common/components/base/pages/components/page-wrapper';
import Breadcrumb from 'common/components/base/breadcrumb';
import { EmptyList } from 'modules/marketplace/components';
import { ListContractsByOrderIdResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { Button } from 'common/components';
import { Typography } from '@material-ui/core';
import { formatCurrency } from 'common/utils';
import MarketplaceContractCard from './components/marketplace-contract-card';
import { useStyles } from './contract-page-style';

interface ContractPageViewProps {
    contracts?: ListContractsByOrderIdResponse[];
    selectedContracts: string[];
    loading?: boolean;
    verifyContractIsSelected: (contractId: string) => boolean;
    onSelectContract: (contractId: string) => void;
    goToCheckout: () => void;
    totalValuesSelected?: number;
}

const ContractPageView: React.FC<ContractPageViewProps> = ({
    selectedContracts,
    contracts,
    goToCheckout,
    loading = false,
    totalValuesSelected = 0,
    verifyContractIsSelected,
    onSelectContract,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <PageWrapper>
            <Breadcrumb
                customPath='mercado/pedidos/empenho'
                paths={{
                    mercado: {
                        value: t('term.marketplace'),
                        onClick: () => history.replace('/mercado'),
                    },
                    pedidos: {
                        value: t('term.orders'),
                        onClick: () => history.replace('/mercado/pedidos'),
                    },
                    empenho: { value: t('marketplace.contract.title'), disabled: true },
                }}
            />
            {isEmpty(contracts) && !loading && (
                <EmptyList
                    title={t('marketplace.contract.empty-list')}
                    subtitle={t('marketplace.cart.can-access-orders')}
                    href='/mercado/pedidos'
                />
            )}
            {loading && <MarketplaceContractCard loading />}
            {contracts?.map((contract) => (
                <MarketplaceContractCard
                    key={contract.contract.id}
                    contract={contract}
                    loading={loading}
                    isSelected={verifyContractIsSelected(contract.contract.id)}
                    onSelectContract={onSelectContract}
                />
            ))}

            {(!isEmpty(contracts) || loading) && (
                <>
                    <div className={classes.divider} />
                    <footer className={classes.footerContainer}>
                        <div>
                            <div className={classes.selectionContainer}>
                                <Typography>Contratos selecionados:</Typography>
                                <Typography style={{ fontWeight: 700 }}>
                                    {selectedContracts.length}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.selectionContainer}>
                            <Typography className={classes.bold}>
                                {t('term.total-value')} {formatCurrency(totalValuesSelected)}
                            </Typography>
                            <Button
                                color='primary'
                                variant='contained'
                                size='small'
                                disabled={selectedContracts.length === 0}
                                onClick={goToCheckout}
                            >
                                {t('marketplace.checkout.continue-to-payment')}
                            </Button>
                        </div>
                    </footer>
                </>
            )}
        </PageWrapper>
    );
};

export default ContractPageView;
