import { FC } from 'react';
import { Box, Grid, Radio, FormControlLabel, MenuItem, RadioGroup } from '@material-ui/core';
import { Modal, ModalPosition, TextField, Button, MaskedTextField } from 'common/components';
import { Autocomplete } from '@material-ui/lab';
import { AccountType, MethodType } from 'clients/manager/interfaces/user-financial-data.interface';

interface ModalProviderBankDataViewProps {
    t: any;
    onClose: () => void;
    form: any;
    banks: any[];
    getDefaultValue: () => any;
    isSubmitting: boolean;
}

const ModalProviderBankDataView: FC<ModalProviderBankDataViewProps> = ({ t, onClose, form, banks, getDefaultValue, isSubmitting }) => {
    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={(event: any) => {
                event.stopPropagation();
                onClose();
            }}
            header={<span>{t('info.add-bank-data')}</span>}
        >
            <Box width='500px'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RadioGroup
                            row
                            aria-label='position'
                            name='originOfFinancialResource'
                            defaultValue={form?.values.methodType as string}
                            onChange={(event) =>
                                form?.setFieldValue('accountType', event.target.value)
                            }
                        >
                            <FormControlLabel
                                control={<Radio value={MethodType.BANK_ACCOUNT} color='primary' />}
                                label={t('term.bank-account')}
                            />
                            <FormControlLabel
                                control={<Radio disabled value={MethodType.PIX} color='primary' />}
                                label={t('term.pix-key')}
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={7}>
                        <Autocomplete
                            key={banks.length}
                            options={banks}
                            clearOnEscape
                            disableClearable
                            defaultValue={getDefaultValue()}
                            getOptionLabel={(option) => `${option.code} - ${option.name}`}
                            renderInput={(params) => (
                                <TextField {...params} label={t('term.bank')} variant='outlined' />
                            )}
                            onChange={(_, bank) => {
                                form.setFieldValue('bankCode', String(bank.code));
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            fullWidth
                            select
                            label={t('info.account-type')}
                            name='accountType'
                            value={form.values.accountType}
                            onChange={form.handleChange}
                        >
                            <MenuItem key={AccountType.CC} value={AccountType.CC}>
                                {t('info.checking-account')}
                            </MenuItem>
                            <MenuItem key={AccountType.CI} value={AccountType.CI}>
                                {t('info.savings-account')}
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            fullWidth
                            label={t('term.agency')}
                            name='accountAgency'
                            value={form.values.accountAgency}
                            onChange={form.handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <TextField
                            fullWidth
                            label={t('info.account-number')}
                            name='accountNumber'
                            value={form.values.accountNumber}
                            onChange={form.handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>                        
                        <MaskedTextField
                            fullWidth
                            label={t(
                                'sign-up.pages.stepper-provider.steps.financial.docNumberReceiver'
                            )}
                            mask='99.999.999/9999-99'
                            name='docNumberReceiver'
                            value={form.values.docNumberReceiver}
                            onChange={form.handleChange}
                            error={form.touched.docNumberReceiver && Boolean(form.errors.docNumberReceiver)}
                            helperText={form.touched.docNumberReceiver && form.errors.docNumberReceiver}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box mt={1} width={1} display='flex' justifyContent='space-between'>
                            <Button variant='outlined' size='small' onClick={() => onClose()}>
                                {t('term.cancel')}
                            </Button>
                            <Button
                                variant='contained'
                                size='small'
                                disabled={!form.dirty || !!Object.keys(form.errors).length || isSubmitting}
                                onClick={() => form.submitForm()}
                            >
                                {t('term.save')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default ModalProviderBankDataView;
