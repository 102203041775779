import React, { FC } from 'react';
import { TableCell, TableRow, Box, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StepSigners from 'common/components/contracts/components/step-signers';
import moment from 'moment';
import { ContractStatus, ContractWithDetails } from 'clients/manager/interfaces/contract.interface';
import { useTranslation } from 'react-i18next';
import ModalEditContract from 'common/components/contracts/components/modal-edit-contract';
import ChipContractStatus from 'common/components/contracts/components/chip-contract-status';
import { termType } from 'common/components/contracts/data';
import WithoutContractActions from 'common/components/contracts/components/without-contract-actions';
import ContractActions from '../../../../../../common/components/contracts/components/contract-actions';
import useStyles from './contracts-list-row-style';
import { ContractListRowViewProps } from './props/ContractListRowViewProps';

const ContractRowView: FC<ContractListRowViewProps> = ({
    contractWithDetails,
    setContracts,
    modalEditVisible,
    setModalEditVisible,
    createContract,
    deleteContract,
    deleteContractFromPncp,
    handleContractUpdated,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { contract, documentSigners, provider } = contractWithDetails;

    return (
        <>
            {modalEditVisible && (
                <ModalEditContract
                    onClose={() => setModalEditVisible(false)}
                    contractWithDetails={contractWithDetails as ContractWithDetails}
                    setContracts={setContracts}
                />
            )}
            <TableRow hover>
                <TableCell>{provider.companyName}</TableCell>
                <TableCell>{termType[contract?.termTypeId || ''] || 'Contrato'}</TableCell>
                <TableCell>{contract?.contractNumber}</TableCell>
                <TableCell>
                    {`${t('term.from')}: ${
                        contract?.startDate ? moment(contract.startDate).format('DD/MM/YYYY') : '-'
                    }
                    ${t('term.until')} ${
                        contract?.endDate ? moment(contract.endDate).format('DD/MM/YYYY') : '-'
                    }`}
                </TableCell>
                <TableCell>
                    <ChipContractStatus status={contract?.status || ContractStatus.draft} />
                </TableCell>
                <TableCell>
                    {documentSigners?.signers?.length ? (
                        <StepSigners
                            canDeleteSigner
                            signers={
                                (documentSigners?.signers ?? []).map(
                                    ({ email, name, signatureDate, signatureStatus }) => ({
                                        email,
                                        name,
                                        signatureDate,
                                        signatureStatus,
                                    })
                                ) ?? []
                            }
                            signerId={documentSigners.id}
                            processId={contract?.auctionNoticeId}
                        />
                    ) : (
                        '-'
                    )}
                </TableCell>
                <TableCell>
                    <Box display='flex' alignItems='center' justifyContent='center' gridGap={4}>
                        {contract?.assessment ? (
                            <>
                                <Typography className={classes.ratingInfo}>
                                    {contract.assessment.generalRating}
                                </Typography>
                                <StarIcon className={classes.star} />
                            </>
                        ) : (
                            <>
                                <Typography className={classes.ratingInfo}>0</Typography>
                                <StarIcon />
                            </>
                        )}
                    </Box>
                </TableCell>
                <TableCell padding='none'>
                    {contractWithDetails.contract ? (
                        <ContractActions
                            contractWithDetails={contractWithDetails as ContractWithDetails}
                            onEditContract={() => setModalEditVisible(true)}
                            handleContractUpdated={handleContractUpdated}
                            deleteContract={deleteContract}
                            deleteContractFromPncp={deleteContractFromPncp}
                        />
                    ) : (
                        <WithoutContractActions createContract={createContract} />
                    )}
                </TableCell>
            </TableRow>
        </>
    );
};

export default ContractRowView;
