import { Box, Divider } from '@material-ui/core';
import { Modal, ModalPosition } from 'common/components';
import { Skeleton } from '@material-ui/lab';
import { createRef, FC, RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { addNotificationError } from 'common/utils';
import { Chat } from '../../../../clients/manager/interfaces/process-chats.interface';
import { processChatsRequests } from '../../../../clients/manager/process-chats.requests';
import EmptyData from '../../../../common/components/empty-data';
import { Scroll } from '../../../../common/components/base/scroll';
import { useProcessFormContext } from '../../context/process-form.context';
import ChatCardView from './chat-card-view';
import CreateChat from './create-chat';
import { ModalListChatsProps } from './props';

const ContentScroll = styled(Scroll)`
    overflow-y: auto;
    height: calc(100% - 183px);
    padding: 0 16px;
`;

const ModalListChats: FC<ModalListChatsProps> = ({ onClose }) => {
    const [chats, setChats] = useState<Chat[]>([]);
    const [fetchingChats, setFetchingChats] = useState(true);
    const [paginationLimit] = useState(100);
    const [offset, setOffset] = useState(0);
    const [hasMoreChats, setHasMoreChats] = useState(true);
    const [hasPreviousChats, setHasPreviousChats] = useState(false);

    const { process } = useProcessFormContext();
    const { t } = useTranslation();
    const messagesEnd: RefObject<any> = createRef();

    const handleOffsetPageState = (pageState: string | undefined): number => {
        let newOffset = offset;
        if (pageState === 'previousPage') newOffset = offset - paginationLimit;
        if (pageState === 'nextPage') newOffset = offset + paginationLimit;
        return newOffset;
    };

    const listChats = async (pageState?: string) => {
        if (!process?.id) {
            return;
        }

        const newOffset = handleOffsetPageState(pageState);

        try {
            setFetchingChats(true);

            const response = await processChatsRequests.listProcessChat(
                process.id,
                paginationLimit,
                newOffset
            );

            let newChats = response?.data || [];
            newChats = newChats.reverse();

            setChats(newChats);
            setHasPreviousChats(newOffset > 0);

            setHasMoreChats(newChats.length === paginationLimit);
        } catch (error) {
            addNotificationError({
                message: t('info.error.chat.request'),
                title: t('term.error'),
            });
        } finally {
            setFetchingChats(false);

            setOffset(newOffset);
        }
    };

    const handleChatCreated = (chat: Chat) => {
        setChats((chats) => [...chats, chat]);
    };

    const loadMoreChats = (pageState) => {
        if (hasMoreChats || hasPreviousChats) {
            listChats(pageState);
        }
    };

    useEffect(() => {
        if (!fetchingChats) {
            messagesEnd?.current?.scrollIntoView();
        }
    }, [chats.length, fetchingChats]);

    useEffect(() => {
        listChats();
    }, [process?.id]);

    return (
        <Modal
            position={ModalPosition.right}
            open
            onClose={(event: any) => {
                event.stopPropagation();
                onClose();
            }}
            header={<span>{t('term.messages')}</span>}
        >
            <>
                <ContentScroll>
                    <Box width={340}>
                        {hasMoreChats && !fetchingChats && (
                            <Box textAlign='center' mb={2} mt={2}>
                                <button
                                    onClick={() => loadMoreChats('nextPage')}
                                    disabled={fetchingChats}
                                >
                                    {t('term.load-more')}
                                </button>
                            </Box>
                        )}

                        {fetchingChats &&
                            new Array(4).fill(null).map(() => (
                                <>
                                    <Box width={1} mb={2} mt={2}>
                                        <Skeleton variant='circle' width={35} height={35} />
                                        <Skeleton />
                                        <Skeleton />
                                    </Box>
                                    <Divider />
                                </>
                            ))}

                        {!fetchingChats && !chats.length && (
                            <EmptyData>{t('info.empty-chat')}</EmptyData>
                        )}

                        {!fetchingChats &&
                            chats.map((chat) => (
                                <>
                                    <ChatCardView key={chat.id} chat={chat} />
                                    <Divider />
                                </>
                            ))}
                    </Box>
                    <div style={{ float: 'left', clear: 'both' }} ref={messagesEnd} />
                    {!fetchingChats && hasPreviousChats && (
                        <Box textAlign='center' mb={2} mt={2}>
                            <button
                                onClick={() => loadMoreChats('previousPage')}
                                disabled={fetchingChats}
                            >
                                {t('term.load-more')}
                            </button>
                        </Box>
                    )}
                </ContentScroll>

                <Box padding={1}>
                    {process?.id && !fetchingChats && (
                        <CreateChat processId={process.id} onChatCreated={handleChatCreated} />
                    )}
                </Box>
            </>
        </Modal>
    );
};

export default ModalListChats;
