import { AuctionTypeRule } from 'clients/manager/interfaces/auction-type-rules.interface';
import { Platform } from 'clients/manager/platforms.requests';
import { processUtils } from 'modules/process/process-utils';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { AuctionNotice } from '../../../../clients/manager/interfaces/auction-notice.interface';
import { getGeneralDataPartialValidationSchema } from './general-data-form-schema';
import { getModalityPartialValidationSchema } from './modality-form-schema';
import { getObjectDefinitionPartialValidationSchema } from './object-definition-form-schema';
import {
    getPublicationPartialUnenforceabilityValidationSchema,
    getPublicationPartialValidationSchema,
} from './publication-form-schema';
import { getSourceResourcesPartialValidationSchema } from './source-resources-form-schema';
import { getTeamPartialValidationSchema } from './team-form-schema';
import { getUnitDataPartialValidationSchema } from './unit-data-form-schema';
import { getProcessParticipantPartialValidationSchema } from './process-participant-form-schema';

const getProcessValidationSchema = (
    values?: AuctionNotice | undefined,
    auctionTypeRules?: AuctionTypeRule,
    platform?: Platform
) =>
    Yup.object({
        ...getModalityPartialValidationSchema(),
        ...getTeamPartialValidationSchema(values, auctionTypeRules),
        ...getGeneralDataPartialValidationSchema(values, auctionTypeRules),
        ...getObjectDefinitionPartialValidationSchema(values, platform, auctionTypeRules),
        ...getSourceResourcesPartialValidationSchema(values),
        ...(processUtils.isUnenforceabilityProcess(values)
            ? getProcessParticipantPartialValidationSchema()
            : {}),
        ...(processUtils.isAccreditationProcess(values)
            ? {}
            : processUtils.isUnenforceabilityProcess(values)
            ? getPublicationPartialUnenforceabilityValidationSchema()
            : getPublicationPartialValidationSchema()),
        ...getUnitDataPartialValidationSchema(),
    } as ObjectShape);

export { getProcessValidationSchema };
