import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: '8px 16px',
        position: 'relative',
        marginLeft: 24,
    },
    gridContainer: {
        minHeight: 45,
        justifyContent: 'space-between',
    },
    gridColumn: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    boxFlex: {
        display: 'flex',
        alignItems: 'center',
    },
    privateDocumentIcon: {
        fontSize: 14,
        color: theme.palette.primary.dark,
        marginRight: 4,
    },
    requiredText: {
        fontSize: 12,
        marginLeft: 4,
        color: theme.palette.error.main,
    },
    viewFileText: {
        display: 'flex',
        gap: 4,
        color: theme.palette.primary.dark,
    },
    viewHistoricText: {
        display: 'flex',
        gap: 4,
        color: theme.palette.primary.dark,
        cursor: 'pointer',
    },
    referenceDescription: {
        fontSize: 12,
        backgroundColor: theme.palette.info.light,
        color: 'white',
        padding: '2px 10px',
        textAlign: 'justify',
        borderRadius: '4px',
    },
    infoIcon: {
        marginRight: 8,
        color: theme.palette.info.light,
    },
    font12: {
        fontSize: 12,
        marginLeft: 4,
    },
    font14: {
        fontSize: 14,
    },
    cloudUploadIcon: {
        fontSize: 22,
        marginRight: 8,
    },
    marginBottom10: {
        marginBottom: 10,
    },
    gridSegment: {
        paddingRight: theme.spacing(3),
    },
    tooltipSegment: {
        cursor: 'pointer',
    },
    chipSegment: {
        backgroundColor: theme.palette.info.main,
        padding: theme.spacing(1.4),
        marginRight: theme.spacing(0.5),
        maxWidth: '100%',
    },
    updatedFile: {
        width: 100,
        color: theme.palette.grey[600],
        fontSize: 12,
        marginTop: '15px',
    },
    chipReference: {
        backgroundColor: theme.palette.warning.main,
        padding: theme.spacing(2),
    },
    textDecorationNone: {
        textDecoration: 'none',
    },
    colorPaletteDark: {
        color: theme.palette.primary.dark,
    },
    colorPaletteMain: {
        color: theme.palette.primary.main,
    },
    gridFlexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignContent: 'center',
        alignItems: 'center',
    },
    gridFlexEnd2: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    paddingBox: {
        padding: '4px 6px !important',
    },
}));

export default useStyles;
