/* eslint-disable @typescript-eslint/naming-convention */
import { supplyCategoriesRequests } from 'clients/manager/supply-categories.requests';
import { storage, addNotificationError } from 'common/utils';
import {
    createContext,
    Dispatch,
    memo,
    SetStateAction,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import { unstable_batchedUpdates as batchStates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AppState } from 'store';
import lodash from 'lodash';

type supplyLineItem = { title: string; id?: number };
type stateItem = { title: string; uf: string };
type regionItem = { title: string; codes: number[] };

export type processStage = 'all' | 'dispute' | 'decision' | 'contract' | 'published';
export type processType =
    | 'all'
    | 'trading'
    | 'auction'
    | 'dispense'
    | 'marketplace'
    | 'accreditation'
    | 'concurrence'
    | 'unenforceability'
    | 'competitiveDialogue'
    | 'contest'
    | 'inPersonConcurrence'
    | 'inPersonTrading'
    | 'expressionOfInterest'
    | 'preQualification'
    | 'inPersonAuction'
    | 'biddingInapplicability';

interface BiddingFiltersContextProps {
    searchValue?: string;
    setSearchValue: Dispatch<SetStateAction<string | undefined>>;
    onlySuggested: boolean;
    onlyFavorited: boolean;
    onlyProposal: boolean;
    state?: stateItem;
    setState: Dispatch<SetStateAction<stateItem | undefined>>;
    supplyLine: supplyLineItem[];
    setOnlySuggested: Dispatch<SetStateAction<boolean>>;
    setOnlyFavorited: Dispatch<SetStateAction<boolean>>;
    setOnlyProposal: Dispatch<SetStateAction<boolean>>;
    setSupplyLine: Dispatch<SetStateAction<supplyLineItem[]>>;
    startDate?: Date;
    endDate?: Date;
    changeDate: (dates: [startDate: Date, endDate: Date]) => void;
    processStage: processStage;
    processType: processType;
    setProcessStage: Dispatch<SetStateAction<processStage>>;
    setProcessType: Dispatch<SetStateAction<processType>>;
    region?: regionItem;
    setRegion: Dispatch<SetStateAction<regionItem | undefined>>;
    supplies: any[];
    setSupplies: Dispatch<SetStateAction<any[]>>;
    resetFilters: () => void;
    showFilters: boolean;
    setShowFilters: Dispatch<SetStateAction<boolean>>;
    startDatePublication?: Date;
    endDatePublication?: Date;
    changeDatePublication: (dates: [startDatePublication: Date, endDatePublication: Date]) => void;
}

const FILTERS_DEFAULT_VALUE = {
    processType: 'all',
    processStage: 'all',
    supplyLine: [{ title: 'Todas' }],
};

export const BiddingFiltersContext = createContext({} as BiddingFiltersContextProps);

const BiddingFiltersProvider = ({ children }) => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const { authUser, authUserTokenData } = useSelector((state: AppState) => state.authUserState);

    const [searchValue, setSearchValue] = useState<string>();
    const [processType, setProcessType] = useState<processType>('all');
    const [processStage, setProcessStage] = useState<processStage>('all');
    const [onlySuggested, setOnlySuggested] = useState(false);
    const [onlyFavorited, setOnlyFavorited] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [onlyProposal, setOnlyProposal] = useState(false);
    const [supplyLine, setSupplyLine] = useState<supplyLineItem[]>([{ title: 'Todas' }]);
    const [state, setState] = useState<stateItem>();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [region, setRegion] = useState<regionItem>();
    const [supplies, setSupplies] = useState<any[]>([]);
    const [cacheUpdated, setCacheUpdated] = useState(false);
    const [startDatePublication, setStartDatePublication] = useState();
    const [endDatePublication, setEndDatePublication] = useState();

    useEffect(() => {
        const onlyFavoritesQuery = new URLSearchParams(search).get('onlyFavorites');
        const onlySuggestedQuery = new URLSearchParams(search).get('onlySuggested');
        const onlyProposalQuery = new URLSearchParams(search).get('onlyProposal');

        const isUserProvider = authUserTokenData?.typeAccess === 'provider';

        const hasOnlyFavoritesQuery = onlyFavoritesQuery !== null && !!authUser;
        const hasOnlySuggestedQuery = onlySuggestedQuery !== null && isUserProvider;
        const hasOnlyProposalQuery = onlyProposalQuery !== null && isUserProvider;

        const hasFastFilterQuery = [
            hasOnlyFavoritesQuery,
            hasOnlyProposalQuery,
            hasOnlySuggestedQuery,
        ].includes(true);

        const filters = storage.get('search-filters', 'session');
        if (filters && filters !== null)
            batchStates(() => {
                const {
                    searchValue,
                    processType,
                    processStage,
                    supplyLine,
                    startDate,
                    endDate,
                    region,
                    supplies,
                    state,
                    startDatePublication,
                    endDatePublication,
                } = filters;

                const comparsionFilters = { ...filters };

                delete comparsionFilters.supplies;
                delete comparsionFilters.onlySuggested;
                delete comparsionFilters.onlyFavorited;
                delete comparsionFilters.onlyProposal;
                delete comparsionFilters.searchValue;

                if (!lodash.isEqual(comparsionFilters, FILTERS_DEFAULT_VALUE)) setShowFilters(true);

                setSearchValue(searchValue);
                setProcessType(processType);
                setProcessStage(processStage);
                setSupplyLine(supplyLine);
                setStartDate(startDate && new Date(startDate));
                setEndDate(endDate && new Date(endDate));
                setRegion(region);
                setSupplies(supplies);
                setState(state);
                setStartDatePublication(startDatePublication && new Date(startDatePublication));
                setEndDatePublication(endDatePublication && new Date(endDatePublication));

                if (!hasFastFilterQuery) {
                    setOnlyFavorited(filters.onlyFavorited);
                    setOnlySuggested(filters.onlySuggested);
                    setOnlyProposal(filters.onlyProposal);
                }
            });

        if (hasOnlyFavoritesQuery) setOnlyFavorited(true);
        else if (hasOnlySuggestedQuery) setOnlySuggested(true);
        else if (hasOnlyProposalQuery) setOnlyProposal(true);

        setCacheUpdated(true);
    }, []);

    useLayoutEffect(() => {
        if (cacheUpdated)
            storage.set(
                'search-filters',
                {
                    searchValue: searchValue && searchValue,
                    processType: processType && processType,
                    processStage: processStage && processStage,
                    onlySuggested: onlySuggested && onlySuggested,
                    onlyFavorited: onlyFavorited && onlyFavorited,
                    onlyProposal: onlyProposal && onlyProposal,
                    supplyLine: supplyLine && supplyLine,
                    state: state && state,
                    startDate: startDate && startDate,
                    endDate: endDate && endDate,
                    region: region && region,
                    supplies: supplies && supplies,
                    startDatePublication: startDatePublication && startDatePublication,
                    endDatePublication: endDatePublication && endDatePublication,
                },
                'session'
            );
    }, [
        searchValue,
        processType,
        processStage,
        onlySuggested,
        onlyFavorited,
        onlyProposal,
        supplyLine,
        state,
        startDate,
        endDate,
        region,
        supplies,
        cacheUpdated,
        startDatePublication,
        endDatePublication,
    ]);

    useEffect(() => {
        supplyCategoriesRequests
            .listSupplyCategories()
            .then(({ data }) => {
                setSupplies(data.map(({ id, categoryName }) => ({ id, title: categoryName })));
            })
            .catch(() => {
                addNotificationError({
                    title: t('bidding.notification-error-occurred'),
                    message: t('bidding.process.supply-line.notification-error'),
                });
            });
    }, []);

    useEffect(() => {
        if (onlyFavorited) {
            setOnlySuggested(false);
            setOnlyProposal(false);
        }
    }, [onlyFavorited]);

    useEffect(() => {
        if (onlySuggested) {
            setOnlyFavorited(false);
            setOnlyProposal(false);
        }
    }, [onlySuggested]);

    useEffect(() => {
        if (onlyProposal) {
            setOnlyFavorited(false);
            setOnlySuggested(false);
        }
    }, [onlyProposal]);

    useEffect(() => {
        if (supplyLine.length === 0) setSupplyLine([{ title: 'Todas' }]);
    }, [supplyLine]);

    useEffect(() => {
        const conditionalStatus = ['published', 'contract'];
        const isAccreditation = processType === 'accreditation';
        const isAll = processType === 'all';

        if (
            (!isAccreditation && !isAll && conditionalStatus.includes(processStage)) ||
            (isAccreditation && !conditionalStatus.includes(processStage))
        )
            setProcessStage('all');
    }, [processType]);

    const resetFilters = () => {
        setSearchValue('');
        setProcessType('all');
        setProcessStage('all');
        setOnlySuggested(false);
        setOnlyFavorited(false);
        setOnlyProposal(false);
        setSupplyLine([{ title: 'Todas' }]);
        setStartDate(undefined);
        setEndDate(undefined);
        setRegion(undefined);
        setSupplies([]);
        setState(undefined);
        setStartDatePublication(undefined);
        setEndDatePublication(undefined);
    };

    const changeDate = ([start, end]) => {
        setStartDate(start);
        setEndDate(end);
    };

    const changeDatePublication = ([start, end]) => {
        setStartDatePublication(start);
        setEndDatePublication(end);
    };

    return (
        <BiddingFiltersContext.Provider
            value={{
                state,
                setState,
                onlySuggested,
                onlyFavorited,
                onlyProposal,
                supplyLine,
                searchValue,
                setSearchValue,
                setOnlySuggested,
                setOnlyFavorited,
                setOnlyProposal,
                setSupplyLine,
                startDate,
                endDate,
                changeDate,
                processStage,
                processType,
                setProcessStage,
                setProcessType,
                setSupplies,
                supplies,
                setRegion,
                region,
                resetFilters,
                showFilters,
                setShowFilters,
                startDatePublication,
                endDatePublication,
                changeDatePublication,
            }}
        >
            {children}
        </BiddingFiltersContext.Provider>
    );
};

export default memo(BiddingFiltersProvider);
