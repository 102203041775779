import React, { FC } from 'react';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { Buffer } from 'buffer';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
    addNotificationApiError,
    addNotificationError,
    addNotificationSuccess,
    addNotificationWarning,
    getBackofficeRedirectUrl,
} from 'common/utils';
import {
    ProcessDocumentsStepsStatus,
    ProcessDocumentsStepsType,
} from 'clients/manager/interfaces/process-documents-steps.interface';
import { processDocumentStepsRequests } from 'clients/manager/documents/process-documents-steps.requests';
import { DocumentTemplateType } from 'clients/manager/interfaces/document-templates.interface';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import { generatedDocuments } from 'clients/manager/generated-documents';
import { generatedDocumentsRequests } from 'clients/manager/documents/generated-documents.requests';
import { getMostRecentDocumentGenerated } from 'common/utils/getters/get-most-recent-document-generated.utils';
import { useContractsContext } from 'modules/process/components/process-contracts-data/context/contracts.context';
import { useProcessFormContext } from '../../../../context/process-form.context';
import { StepActionsProps } from './props';

const StepActions: FC<StepActionsProps> = ({
    processDocumentsStep: step,
    onDeleteProcessDocumentStep,
    onUpdateProcessDocumentStep,
    onClickEditProcessDocumentStep,
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const history = useHistory();
    const { reFetchSteps } = useContractsContext();
    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const { process, setCheckProcess } = useProcessFormContext();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteStepClick = async () => {
        try {
            await processDocumentStepsRequests.deleteProcessStep(step._id);
            setCheckProcess('remove-document');
            reFetchSteps();
            onDeleteProcessDocumentStep();
            handleClose();
            return addNotificationSuccess();
        } catch (e) {
            addNotificationWarning({
                title: t('term.error'),
                message: t('info.action-error'),
            });
        }
    };

    const handleRedirectToDocument = async (mode: 'edit' | 'view') => {
        if (!process) {
            return;
        }

        const { refProcessDocumentTemplateId } = step;

        const data = {
            id: process.id,
            auctionNumber: process?.auctionNumber ?? process?.accreditationNumber,
        };

        history.push({
            pathname: `/processos/editor/modelo-documento/${refProcessDocumentTemplateId}`,
            search: `?auction=${Buffer.from(JSON.stringify(data)).toString('base64')}&from=${
                window.location.pathname + window.location.search
            }&mode=${mode}`,
        });
    };

    const handleEditClick = () => handleRedirectToDocument('edit');

    const handleViewClick = () => {
        if (step.type === ProcessDocumentsStepsType.document) {
            return handleRedirectToDocument('view');
        }
    };

    // Step do tipo contrato gera o contrato na aba de contratos do processo
    // pois aqui não temos o providerId, é só uma definição de documento
    const visibleGenerateDocumentOption =
        step.type === ProcessDocumentsStepsType.document &&
        step.documentType !== DocumentTemplateType.contract &&
        step.status === ProcessDocumentsStepsStatus.draft;

    const visibleReGenerateDocumentOption =
        step.type === ProcessDocumentsStepsType.document &&
        step.totalGenerated > 0 &&
        step.documentType !== DocumentTemplateType.contract;

    const visibleViewDocumentOption =
        step.type === ProcessDocumentsStepsType.document &&
        step.documentType !== DocumentTemplateType.contract;

    const visibleSendToAssignture =
        step.status === ProcessDocumentsStepsStatus.generated &&
        step.documentType !== DocumentTemplateType.contract;

    const handleSendToAssignture = async () => {
        try {
            const { data } = await generatedDocumentsRequests.listDocuments({
                params: {
                    auctionId: step.auctionId,
                    processDocumentTemplateId: step.refProcessDocumentTemplateId,
                    processDocumentStepId: step._id,
                },
            });
            if (!data.length) {
                addNotificationError({
                    message: t('document.not-generated'),
                });
            }
            const lastGenerated = getMostRecentDocumentGenerated(data);
            if (lastGenerated?._id) {
                await generatedDocuments.sendGeneratedDocumentToSign(lastGenerated._id);

                onUpdateProcessDocumentStep({
                    ...step,
                    status: ProcessDocumentsStepsStatus.awaiting_signatures,
                });
            }
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const handleGenerateClick = async (forceGenerated?: boolean) => {
        if (!step) {
            return;
        }

        const { refProcessDocumentTemplateId: docId, auctionId } = step;
        await processDocumentStepsRequests.updateProcessStep(step._id, {
            ...step,
            status: ProcessDocumentsStepsStatus.generated,
        });
        setCheckProcess('update-document');

        onUpdateProcessDocumentStep({
            ...step,
            status: ProcessDocumentsStepsStatus.generated,
            totalGenerated: step.totalGenerated + 1,
        });

        window
            .open(
                `${backofficeRedirectUrl}/imprimir/?processDocumentTemplateId=${docId}&auctionNoticeId=${auctionId}&forceGenerated=${Number(
                    forceGenerated
                )}`,
                '_blank'
            )
            ?.focus();

        handleClose();
    };

    return (
        <>
            <IconButton
                aria-label='more'
                size='small'
                aria-controls='long-menu'
                aria-haspopup='true'
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {visibleViewDocumentOption && (
                    <MenuItem onClick={handleViewClick}>{t('info.view-model-document')}</MenuItem>
                )}
                {step.type === ProcessDocumentsStepsType.document && (
                    <MenuItem onClick={handleEditClick}>{t('term.edit-doc')}</MenuItem>
                )}
                {visibleGenerateDocumentOption && (
                    <MenuItem onClick={() => handleGenerateClick(false)}>
                        {t('info.generate-doc')}
                    </MenuItem>
                )}
                {visibleReGenerateDocumentOption && (
                    <MenuItem onClick={() => handleGenerateClick(true)}>
                        {t('info.generate-doc-again')}
                    </MenuItem>
                )}
                {visibleSendToAssignture && (
                    <MenuItem onClick={() => handleSendToAssignture()}>
                        {t('info.send-for-signature')}
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        onClickEditProcessDocumentStep();
                        handleClose();
                    }}
                >
                    {t('info.edit-data')}
                </MenuItem>
                <ConfirmDialog
                    title={t('info.confirm-step-delete-title')}
                    message={t('info.confirm-step-delete.message')}
                    onConfirm={handleDeleteStepClick}
                >
                    <MenuItem onClick={handleClose}>{t('term.delete')}</MenuItem>
                </ConfirmDialog>
            </Menu>
        </>
    );
};

export default StepActions;
