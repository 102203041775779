import { doRequest, managerApiInstance } from 'clients/api';
import {
    ExistingUserVerificationCodeBody,
    ExistingUserVerificationCodeReponse,
    SendEmailVerificationCodeBody,
    UpdateLgpdLegalBasisBody,
    UpdateLgpdLegalBasisResponse,
    User,
    ValidateVerificationCodeBody,
    ValidateVerificationCodeReponse,
} from 'common/interfaces/user.interface';
import { INewUser } from 'modules/sign-up/pages/sign-up/props';
import { OkResponse } from 'clients/interfaces/ok.interface';
import { AuthorizationActionsEnum } from 'common/enums/authorization-actions.enum';
import { AxiosRequestConfig } from 'axios';
import {
    PlainGenericResponse,
    GenericResponse,
    GenericBody,
    GenericUniqueObjectResponse,
} from '../interfaces/paginated.interface';
import { ListEnvironmentsWithPermission } from './interfaces/environments-user-permission.interface';
import { NewUserCreated, UserCheck } from './interfaces/user.interface';

const BASE_PATH = '/users';

const userRequests = {
    getUser: async (userId: number) =>
        doRequest<User>(managerApiInstance.post(`${BASE_PATH}/getUser`, { userId })),
    getAuthenticatedUser: async () =>
        doRequest<User>(managerApiInstance.post(`${BASE_PATH}/getMe`)),
    listOrganizationUsers: async (body?: GenericBody<{ q?: string }>) =>
        doRequest<GenericResponse<User>>(
            managerApiInstance.post(`${BASE_PATH}/listOrganizationUsers`, body)
        ),
    listUsersBelongToOrganizationUnit: async (
        body?: GenericBody<{ q?: string; organizationUnitId: number }>,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<GenericResponse<User>>(
            managerApiInstance.post(`${BASE_PATH}/listUsersBelongToOrganizationUnit`, body, config)
        ),
    updateAuthenticatedUser: async (values) =>
        doRequest<User>(managerApiInstance.post(`${BASE_PATH}/updateMe`, values)),
    updatePassword: async (values: {
        currentPassword: string;
        newPassword: string;
        newPasswordConfirm: string;
    }) => doRequest<User>(managerApiInstance.post(`${BASE_PATH}/updatePassword`, values)),
    checkUserExist: async (body: UserCheck) =>
        doRequest<any>(managerApiInstance.post(`/auth/doCheckUserExists`, body)),
    getUserByDocAndCountry: async (body: UserCheck) =>
        doRequest<GenericUniqueObjectResponse<User>>(
            managerApiInstance.post(`${BASE_PATH}/getUserByDocAndCountry`, body)
        ),
    createUser: async (newUser: INewUser) =>
        doRequest<NewUserCreated>(managerApiInstance.post(`${BASE_PATH}/createUser`, newUser)),
    listEnvironmentsWithPermission: async () =>
        doRequest<PlainGenericResponse<ListEnvironmentsWithPermission>>(
            managerApiInstance.post(`${BASE_PATH}/listEnvironmentsWithPermission`)
        ),
    listAdminUsers: async () =>
        doRequest<GenericResponse<User>>(managerApiInstance.post(`${BASE_PATH}/listAdminUsers`)),
    updateLgpdLegalBasis: async (values: UpdateLgpdLegalBasisBody) =>
        doRequest<UpdateLgpdLegalBasisResponse>(
            managerApiInstance.post(`${BASE_PATH}/updateLgpdLegalBasis`, values)
        ),
    sendEmailVerificationCode: async (body: SendEmailVerificationCodeBody) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`${BASE_PATH}/sendEmailVerificationCode`, body)
        ),
    validateVerificationCode: async (body: ValidateVerificationCodeBody) =>
        doRequest<PlainGenericResponse<ValidateVerificationCodeReponse>>(
            managerApiInstance.post(`${BASE_PATH}/validateVerificationCode`, body)
        ),
    existingUserVerificationCode: async (body: ExistingUserVerificationCodeBody) =>
        doRequest<PlainGenericResponse<ExistingUserVerificationCodeReponse>>(
            managerApiInstance.post(`${BASE_PATH}/existingUserVerificationCode`, body)
        ),
    hasPermission: async (actions: AuthorizationActionsEnum | AuthorizationActionsEnum[]) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`${BASE_PATH}/hasPermission`, { actions })
        ),
};

export { userRequests };
