import React, { useMemo } from 'react';

import Yup from 'common/utils/yup-extended.utils';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useQueryWithBody } from 'common/hooks/use-query-with-body.hook';
import { organizationUnitsRequests } from 'clients/manager/organization.requests';
import { userRequests } from 'clients/manager/user.requests';
import { auctionLegalSupportRequests } from 'clients/manager/auction-legal-support.requests';
import { AuctionType } from 'clients/manager/interfaces/auction-notice.interface';
import { useQuery } from 'common/hooks/use-query.hook';
import AdditionalInformationFormView from './additional-information-form-view';

export interface AdditionalInformationFormValues {
    organizationUnitId?: number;
    competentAuthorityId?: number;
    deliveryDeadlineInDays?: number;
    itemDeliveryTime?: string;
    inspectorUserId?: number;
    deliveryLocation: string;
    deadlineToSendProposal: string;
    legalSupportId?: number;
    object?: string;
    purchaseJustification?: string;
}

interface AdditionalInformationFormProps {
    onSubmit?: (values: AdditionalInformationFormValues) => void;
    onClose?: () => void;
}

const AdditionalInformationForm: React.FC<AdditionalInformationFormProps> = ({
    onSubmit,
    onClose,
}) => {
    const { t } = useTranslation();

    const initialValues: AdditionalInformationFormValues = {
        organizationUnitId: undefined,
        competentAuthorityId: undefined,
        deliveryDeadlineInDays: undefined,
        itemDeliveryTime: '',
        inspectorUserId: undefined,
        legalSupportId: undefined,
        deliveryLocation: '',
        deadlineToSendProposal: '',
        object: '',
        purchaseJustification: '',
    };

    const form = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            deadlineToSendProposal: Yup.date().required(t('required-field', { ns: 'validation' })),
            organizationUnitId: Yup.number().required(t('required-field', { ns: 'validation' })),
            legalSupportId: Yup.number().required(t('required-field', { ns: 'validation' })),
            competentAuthorityId: Yup.number().required(t('required-field', { ns: 'validation' })),
            inspectorUserId: Yup.number().required(t('required-field', { ns: 'validation' })),
            deliveryDeadlineInDays: Yup.number()
                .integer()
                .required(t('required-field', { ns: 'validation' })),
            itemDeliveryTime: Yup.string()
                .required(t('required-field', { ns: 'validation' }))
                .min(5)
                .max(5),
            deliveryLocation: Yup.string()
                .required(t('required-field', { ns: 'validation' }))
                .max(150)
                .min(20),
            object: Yup.string()
                .required(t('required-field', { ns: 'validation' }))
                .max(2500)
                .min(5),
            purchaseJustification: Yup.string()
                .required(t('required-field', { ns: 'validation' }))
                .max(1500)
                .min(5),
        }),
        validateOnBlur: false,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            await onSubmit?.(values);
            onClose?.();
            setSubmitting(false);
            resetForm();
        },
    });

    const { data: legalSupportList, loading: legalSupportListLoading } = useQuery(() =>
        auctionLegalSupportRequests.listLegalSupport(AuctionType.unenforceability)
    );

    const { data: organizationUnits, loading: organizationsLoading } = useQueryWithBody(
        organizationUnitsRequests.listOrganizationUnit
    );

    const listUsersFilter = useMemo(
        () => ({ params: { organizationUnitId: form.values.organizationUnitId ?? 0 } }),
        [form.values.organizationUnitId]
    );

    const { data: organizationUsersData, loading: organizationUsersLoading } = useQueryWithBody(
        userRequests.listUsersBelongToOrganizationUnit,
        listUsersFilter,
        { skip: !form.values.organizationUnitId }
    );
    const organizationUsers = organizationUsersData?.data;

    return (
        <AdditionalInformationFormView
            onClose={onClose}
            form={form}
            legalSupportList={legalSupportList?.data}
            organizationUnits={organizationUnits}
            organizationUsers={organizationUsers}
            legalSupportListLoading={legalSupportListLoading}
            organizationsLoading={organizationsLoading}
            organizationUsersLoading={organizationUsersLoading}
        />
    );
};

export default AdditionalInformationForm;
