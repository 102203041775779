import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    boxBorder: {
        padding: theme.spacing(4),
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: theme.spacing(1),
    },
    subtitle: {
        textAlign: 'center',
        maxWidth: '60%',
    },
    checkIcon: {
        fontSize: theme.spacing(15),
    },
    invoiceButton: {
        marginTop: theme.spacing(2),
    },
}));

export default useStyles;
