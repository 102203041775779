import i18n from 'i18next';
import Yup from 'common/utils/yup-extended.utils';
import { AuctionNotice } from 'clients/manager/interfaces/auction-notice.interface';
import { processUtils } from 'modules/process/process-utils';
import { AuctionTypeRule } from 'clients/manager/interfaces/auction-type-rules.interface';

export const getTeamPartialValidationSchema = (
    values: AuctionNotice | undefined,
    auctionTypeRules?: AuctionTypeRule | undefined
) => {
    const objValidation: { [key: string]: any } = {
        presidentUserId: Yup.number()
            .nullable(true)
            .required(i18n.t('required-field', { ns: 'validation' })),

        competentAuthorityId: Yup.number()
            .nullable(true)
            .required(i18n.t('required-field', { ns: 'validation' })),

        committeeMembers: Yup.string()
            .nullable(true)
            .when([], {
                is: () => !auctionTypeRules?.generalSettings?.cadastro.permitePublicarSemEquipe,
                then: Yup.string().moreThanArraySplit({
                    min: 1,
                    message: i18n.t('min-required', { ns: 'validation', value: 1 }),
                }),
                otherwise: Yup.string().nullable(true),
            }),
    };
    if (processUtils.isUnenforceabilityProcess(values) || processUtils.isDispenseProcess(values)) {
        delete objValidation?.committeeMembers;
        return objValidation;
    }
    return objValidation;
};

export const getTeamFormLabels = ({
    committeeMembersLabel,
    competentAuthorityLabel,
    presidentUserLabel,
}: {
    presidentUserLabel: string;
    competentAuthorityLabel: string;
    committeeMembersLabel: string;
}) => ({
    presidentUserId: presidentUserLabel,
    competentAuthorityId: competentAuthorityLabel,
    committeeMembers: committeeMembersLabel,
});
