import { FC } from 'react';
import { Avatar } from '@material-ui/core';
import { stringToColor } from 'common/utils';
import { Signer } from 'clients/manager/interfaces/document-signers.interface';

interface SignerAvatarViewProps {
    signer: Signer;
}

const SignerAvatarView: FC<SignerAvatarViewProps> = ({ signer, ...rest }) => {
    const colorByStage = {
        0: '#f04f0b',
        1: '#4caf50',
    };

    const signed = !!signer.signatureDate;

    const formatTitle = () => {
        if (signer.name) {
            return `${signer.name} - ${signer.email}`;
        }

        return signer.email;
    };

    return (
        <Avatar
            {...rest}
            key={signer.email}
            style={{
                backgroundColor: stringToColor(signer.name || ''),
                cursor: 'pointer',
                border: `2px solid ${colorByStage[Number(signed)]}`,
            }}
            title={formatTitle()}
            alt={signer.name || signer.email}
        >
            {(signer.name || signer.email).charAt(0).toUpperCase()}
        </Avatar>
    );
};

export default SignerAvatarView;
