import React from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { CheckCircleIcon, LaunchOutlinedIcon } from 'common/icons';
import { PaymentStrategy } from 'common/enums';
import { useTranslation } from 'react-i18next';
import useStyles from './success-style';

interface SuccessViewProps {
    type: PaymentStrategy;
    onClick?: () => void;
}

const SuccessView: React.FC<SuccessViewProps> = ({ type, onClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Paper variant='outlined' className={classes.boxBorder}>
            <CheckCircleIcon color='secondary' className={classes.checkIcon} />
            {type === PaymentStrategy.asaas_credit_card && (
                <>
                    <Typography variant='h5'>
                        <strong>{t('bidding.checkout.conclusion.credit_card.title')}</strong>
                    </Typography>
                    <Typography className={classes.subtitle}>
                        {t('bidding.checkout.conclusion.credit_card.subtitle')}
                    </Typography>
                </>
            )}
            {type === PaymentStrategy.asaas_boleto && (
                <>
                    <Typography variant='h5'>
                        <strong>{t('bidding.checkout.conclusion.invoice.title')}</strong>
                    </Typography>
                    <Typography variant='h5' className={classes.subtitle}>
                        {t('bidding.checkout.conclusion.invoice.subtitle')}
                    </Typography>
                </>
            )}
            <Button
                size='medium'
                variant='contained'
                color='primary'
                className={classes.invoiceButton}
                startIcon={<LaunchOutlinedIcon />}
                onClick={onClick}
            >
                {t('marketplace.checkout.see-invoice')}
            </Button>
        </Paper>
    );
};

export default SuccessView;
