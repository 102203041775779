import { doRequest, managerApiInstance } from 'clients/api';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import { Chat } from './interfaces/process-chats.interface';

interface CreateProcessChat {
    processId: number;
    message: string;
}

const BASE_URL = '/process-chat';

const processChatsRequests = {
    listProcessChat: async (processId: number, limit: number, offset: number) =>
        doRequest<GenericResponse<Chat>>(
            managerApiInstance.post(`${BASE_URL}/listProcessChat`, { processId, offset, limit })
        ),
    createProcessChat: async (chat: CreateProcessChat) =>
        doRequest<PlainGenericResponse<Chat>>(
            managerApiInstance.post(`${BASE_URL}/createProcessChat`, chat)
        ),
};

export { processChatsRequests };
