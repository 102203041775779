import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from '../api';
import { GenericResponse } from '../interfaces/paginated.interface';
import { AuctionType } from './interfaces/auction-notice.interface';
import { AuctionLegalSupport } from './interfaces/legall-support.interface';

const auctionLegalSupportRequests = {
    listLegalSupport: async (auctionType: AuctionType, config?: AxiosRequestConfig<any>) =>
        doRequest<GenericResponse<AuctionLegalSupport>>(
            managerApiInstance.post(
                '/auction-legal-support/listLegalSupport',
                { auctionType },
                config
            )
        ),
};

export { auctionLegalSupportRequests };
