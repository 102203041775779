import React from 'react';
import { Button, List, ListItem, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'common/utils';
import { DiscountLabel } from 'modules/marketplace/components';
import { useStyles } from './checkout-summary-card-style';

interface CheckoutSummaryCardViewProps {
    title: string;
    contractsQuantity?: number;
    orderNumber?: number;
    totalValue: number;
    disabled?: boolean;
    loading?: boolean;
    onConfirm?: () => any;
    confirmLabel?: string;
}

const CheckoutSummaryCardView: React.FC<CheckoutSummaryCardViewProps> = ({
    title,
    contractsQuantity,
    orderNumber,
    totalValue,
    disabled,
    loading,
    onConfirm,
    confirmLabel,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <Paper variant='outlined' className={styles.container}>
            <Typography variant='h6' className={styles.title}>
                {title}
            </Typography>
            <List disablePadding>
                <ListItem disableGutters className={styles.infoListItem}>
                    <Typography variant='body1'>
                        {t('marketplace.checkout.order-number')}
                    </Typography>
                    <Typography variant='body1' className={styles.infoPriceItem}>
                        {orderNumber ?? '--'}
                    </Typography>
                </ListItem>
                <ListItem disableGutters className={styles.infoListItem}>
                    <Typography variant='body1'>
                        {t('marketplace.checkout.selected-contracts')}
                    </Typography>
                    <Typography variant='body1' className={styles.infoPriceItem}>
                        {contractsQuantity ?? '--'}
                    </Typography>
                </ListItem>
            </List>
            <div className={styles.totalPriceContainer}>
                <Typography variant='h6' className={styles.infoTotalPrice}>
                    {t('term.total')}
                </Typography>
                <Typography variant='h6' className={styles.infoTotalPrice}>
                    {totalValue ? formatCurrency(totalValue) : '--'}
                </Typography>
            </div>
            <footer className={styles.footerContainer}>
                <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    disabled={disabled || loading}
                    onClick={onConfirm}
                >
                    <Typography variant='button' color='textSecondary'>
                        {confirmLabel ?? t('term.marketplace-button-confirm-shopping')}
                    </Typography>
                </Button>
            </footer>
        </Paper>
    );
};

export default CheckoutSummaryCardView;
