import { FC, useEffect } from 'react';
import { Box, Card, Divider, Grid, Typography } from '@material-ui/core';
import { Button, Modal, ModalPosition } from 'common/components';
import { useTranslation } from 'react-i18next';
import { CloudDownload } from '@material-ui/icons';
import moment from 'moment';
import { formatDate } from 'common/utils';
import { ModalHistoricDocumentsProps } from './props';
import ChipStatusView from '../provider-document-item/chip-status-view';
import 'moment/locale/pt-br';
import { useStyles } from './styles';

const ModalHistoricDocuments: FC<ModalHistoricDocumentsProps> = ({
    onClose,
    DocumentTypesProviders,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        moment.locale('pt-br');
    }, []);

    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={(event: any) => {
                event.stopPropagation();
                onClose();
            }}
            header={<span>{t('term.historic-list')}</span>}
            className={classes.modalMargin}
        >
            <Box width='600px' maxHeight='600px' style={{ overflowY: 'scroll' }}>
                <>
                    {!DocumentTypesProviders.length && (
                        <span>{t('info.havent-document-history')}</span>
                    )}
                </>
                {DocumentTypesProviders.map((item) => (
                    <Card className={classes.cardStyle} key={item.id}>
                        <Grid container className={classes.gridContainer}>
                            <Grid item xs={12} md={12} className={classes.gridItemFlex}>
                                <Box className={classes.flexStartBox}>
                                    <a
                                        href={item.url}
                                        target='_blank'
                                        rel='noreferrer'
                                        className={classes.linkDecoration}
                                    >
                                        <Typography
                                            variant='body2'
                                            align='center'
                                            className={classes.typographyFlex}
                                        >
                                            <CloudDownload
                                                fontSize='small'
                                                className={classes.cloudDownloadIcon}
                                            />
                                            {t('term.download')}
                                        </Typography>
                                    </a>
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.gridItemFlex}>
                                <Box display='flex' alignItems='center' padding='5px'>
                                    <Typography gutterBottom className={classes.typographyBold}>
                                        {t('hiring-plans.labels.status')}:
                                    </Typography>
                                    <ChipStatusView
                                        status={item.evaluatingStatusOld}
                                        dueDate={item.dueDateOld}
                                    />
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.typographyBold}>
                                <Box display='flex' alignItems='center' padding='5px'>
                                    <Typography gutterBottom className={classes.typographyBold}>
                                        {t('hiring-plans.labels.justification')}:
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        align='center'
                                        className={classes.typographyBody2}
                                    >
                                        {item.justificationOfFailureOld}
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.gridItemFlex}>
                                <Box display='flex' alignItems='center' padding='5px'>
                                    <Typography gutterBottom className={classes.typographyBold}>
                                        {t('audit.view.date-hour')} {t('term.upload')}:
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        align='center'
                                        className={classes.typographyBody2}
                                    >
                                        {formatDate(
                                            item.dateTimeUpdateOld ?? item.dateTimeInsertOld,
                                            'DD/MM/YYYY HH:mm:ss'
                                        )}
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={12} className={classes.gridItemFlex}>
                                <Box display='flex' alignItems='center' padding='5px'>
                                    <Typography gutterBottom className={classes.typographyBold}>
                                        {t('audit.view.date-hour')} {t('term.substitution')}:
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        align='center'
                                        className={classes.typographyBody2}
                                    >
                                        {formatDate(
                                            item.evaluatingDateTimeOld,
                                            'DD/MM/YYYY HH:mm:ss'
                                        )}
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Card>
                ))}

                <Box mt={2} width={1}>
                    <Grid container spacing={2} justifyContent='flex-end'>
                        <Grid item>
                            <Button variant='outlined' size='small' onClick={onClose}>
                                {t('bidding.process.lot.provider.dialog.close')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalHistoricDocuments;
