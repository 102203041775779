import { doRequest, managerApiInstance } from 'clients/api';
import { OkResponse } from 'clients/interfaces/ok.interface';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import { Notification } from './interfaces/notification.interface';

export interface ListNotificationBodyData {
    limit?: number;
    offset?: number;
    params: { type: string };
}

const notificationsRequests = {
    getNotificationsCount: async () =>
        doRequest<PlainGenericResponse<{ count: number }>>(
            managerApiInstance.post(`/notifications/getNotificationsCount`)
        ),
    listNotifications: async (body: ListNotificationBodyData) =>
        doRequest<GenericResponse<Notification>>(
            managerApiInstance.post(`/notifications/listNotifications`, body)
        ),
    getNotificationById: async (id: number) =>
        doRequest<PlainGenericResponse<Notification>>(
            managerApiInstance.post(`/notifications/getNotificationById`, { params: { id } })
        ),
    markAllRead: async () =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.patch(`/notifications/markAllRead`)
        ),
};

export { notificationsRequests };
