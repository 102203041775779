import { contractRequests } from 'clients/manager/contract.requests';
import { processDocumentStepsRequests } from 'clients/manager/documents/process-documents-steps.requests';
import { ListContractsFromProcessResponse } from 'clients/manager/interfaces/contract.interface';
import { ProcessDocumentsSteps } from 'clients/manager/interfaces/process-documents-steps.interface';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { documentsTypesContract } from 'modules/process/document-utils';
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';

export type ContractsContextType = {
    contracts: ListContractsFromProcessResponse[];
    setContracts: React.Dispatch<React.SetStateAction<ListContractsFromProcessResponse[]>>;
    steps: ProcessDocumentsSteps[];
    fetchedData: boolean;
    reFetchSteps: () => void;
    updateContractsFromProcess: (auctionNoticeId: number) => void;
};

export const ContractsContext = createContext<ContractsContextType>({
    contracts: [],
    setContracts: () => {},
    steps: [],
    fetchedData: false,
    reFetchSteps: () => {},
    updateContractsFromProcess: () => {},
});

export const useContractsContext = () => useContext(ContractsContext);

export const ContractsContextProvider = ({ children }: { children: ReactNode }) => {
    const { process } = useProcessFormContext();
    const [contracts, setContracts] = useState<ListContractsFromProcessResponse[]>([]);
    const [steps, setSteps] = useState<ProcessDocumentsSteps[]>([]);
    const [fetchedData, setFetchedData] = useState(false);

    const contractsRef = useRef<Pick<ContractsContextType, 'contracts' | 'setContracts'>>();
    contractsRef.current = { contracts, setContracts };

    const reFetchSteps = () => {
        if (process?.id) {
            const getData = async () => {
                const response = await processDocumentStepsRequests.listProcessSteps({
                    documentTypes: documentsTypesContract,
                    auctionId: process.id,
                });

                if (response?.data) {
                    setSteps(response?.data);
                }

                setFetchedData(true);
            };
            getData();
        }
    };

    const updateContractsFromProcess = async (auctionNoticeId: number) => {
        const contractsUpdates = await contractRequests.listContractsFromProcess({
            params: { processId: auctionNoticeId },
        });
        setContracts(contractsUpdates.data);
    };

    useEffect(() => {
        reFetchSteps();
    }, [process?.id]);

    return (
        <ContractsContext.Provider
            value={{
                contracts: contractsRef.current.contracts,
                setContracts: contractsRef.current.setContracts,
                steps,
                fetchedData,
                reFetchSteps,
                updateContractsFromProcess,
            }}
        >
            {children}
        </ContractsContext.Provider>
    );
};
