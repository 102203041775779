import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { AuctionNotice } from 'clients/manager/interfaces/auction-notice.interface';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import LoadingButton from 'common/components/loading-button';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import { processUtils } from 'modules/process/process-utils';
import { BiddingStageEnum } from 'common/enums/bidding-stage.enum';
import { ProcessExceptions } from 'common/exceptions/process-exceptions';
import { userRequests } from 'clients/manager/user.requests';
import { AuthorizationActionsEnum } from 'common/enums/authorization-actions.enum';
import { Tooltip } from '@material-ui/core';
import { useProcessFormContext } from '../../context/process-form.context';
import { isValidProcessForm } from '../../context/process-render-errors';
import ModalPublicationErrors from './modal-publication-errors';
import { useProcessLotsFormContext } from '../../context/process-lots-form.context';
import ModalPermissionPublish from './modal-permission-publish';

const ButtonPublishProcess = () => {
    const {
        process,
        processForm,
        setProcessBkpBeforeSave,
        setProcess,
        publishDateError,
        accreditationDocumentsError,
        auctionTypeRules,
        warnings,
    } = useProcessFormContext();
    const [loading, setLoading] = useState(false);
    const [publishingProcess, setPublishingProcess] = useState(false);
    const [exceptionPublish, setExceptionPublish] = useState<string | undefined>();
    const [publicationErrors, setPublicationErrors] = useState<{ [key: string]: boolean }>();
    const { processLotsForm, formHasChanges: formLotsHasChanges } = useProcessLotsFormContext();
    const { t } = useTranslation();
    const isProcessFormValid = isValidProcessForm(processForm);
    const disabledPublishProcess = !!processForm?.values.auctionPublishedDate;
    const [showModalPermission, setShowModalPermission] = useState<boolean>(false);
    const [hasPermissionToPublish, setHasPermissionToPublish] = useState<boolean>(true);
    const [tooltipPublish, setTooltipPublish] = useState<JSX.Element | string>('');

    useEffect(() => {
        if (warnings && Object.keys(warnings).length) {
            setTooltipPublish(
                <ul>
                    {Object.keys(warnings).map((key) => {
                        return warnings[key] && <li>{t(`warning.${key}`)}</li>;
                    })}
                </ul>
            );
        } else {
            setTooltipPublish(t('info.publish-process').toString());
        }
    }, [warnings]);

    const handlePublishError = (error: any) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setPublishingProcess(false);

            if (error?.response?.data?.warnings) {
                setPublicationErrors(error?.response?.data?.warnings);
            }

            switch (error?.response?.data?.token) {
                case ProcessExceptions.WITHOUT_PERMISSION_TO_PUBLISH_AUCTION:
                    setExceptionPublish(ProcessExceptions.WITHOUT_PERMISSION_TO_PUBLISH_AUCTION);
                    break;
                default:
                    addNotificationApiError(error);
                    break;
            }
        });
    };

    const checkPermissionToPublish = async () => {
        const response = await userRequests.hasPermission(
            AuthorizationActionsEnum.aprovarPublicacaoDosProcessos
        );

        return (
            response.data.ok || !auctionTypeRules?.generalSettings.cadastro.exigirAprovacaoPublicar
        );
    };

    const publishProcess = async (process: AuctionNotice) => {
        await auctionNoticeRequests.publishProcess(
            process.id,
            processUtils.isUnenforceabilityProcess(process)
                ? processForm?.values.participantDocument
                : undefined
        );
    };

    const getValues = () => {
        return {
            ...(processForm?.values as AuctionNotice),
            biddingStageId: processUtils.isUnenforceabilityProcess(process)
                ? BiddingStageEnum.decision
                : BiddingStageEnum.published,
            auctionPurchaseYear: moment().year().toString(),
            sessionOpened: 0,
            auctionPublishedDate: moment().toDate().toString(),
        };
    };

    const setProcessValues = () => {
        const values: AuctionNotice = getValues();
        setProcessBkpBeforeSave(values);
        processForm?.setValues(values);
    };

    const handleClickPublishProcess = async () => {
        if (!process || Object.keys(warnings).length) return;
        setPublishingProcess(true);

        try {
            const canPublish = await checkPermissionToPublish();
            if (!canPublish) {
                setPublishingProcess(false);
                setShowModalPermission(true);
                return;
            }
            await publishProcess(process);
            setProcessValues();

            const response = await auctionNoticeRequests.getProcessFromOrganization({
                auctionId: Number(process.id),
            });
            setProcess(response.data);

            addNotificationSuccess({
                message: t('info.published-process'),
            });

            setPublishingProcess(false);
        } catch (error: any) {
            handlePublishError(error);
        }
    };

    useEffect(() => {
        if (auctionTypeRules === undefined) return;

        const checkPermissions = async () => {
            try {
                setLoading(true);
                const canPublish = await checkPermissionToPublish();
                setHasPermissionToPublish(canPublish);
            } catch (err) {
                setHasPermissionToPublish(false);
            } finally {
                setLoading(false);
            }
        };

        checkPermissions();
    }, [auctionTypeRules]);

    if (disabledPublishProcess || !process) {
        return null;
    }

    return (
        <>
            {publicationErrors && (
                <ModalPublicationErrors
                    warnings={warnings || publicationErrors}
                    onClose={() => setPublicationErrors(undefined)}
                />
            )}
            {exceptionPublish === ProcessExceptions.WITHOUT_PERMISSION_TO_PUBLISH_AUCTION && (
                <ModalPermissionPublish onClose={() => setExceptionPublish(undefined)} />
            )}
            {showModalPermission && (
                <ModalPermissionPublish onClose={() => setShowModalPermission(false)} />
            )}
            <ConfirmDialog
                disabled={warnings && Object.keys(warnings).length !== 0}
                title={t('info.publish-process-title')}
                message={t('info.publish-process-description')}
                onConfirm={handleClickPublishProcess}
            >
                <Tooltip title={tooltipPublish}>
                    <span>
                        <LoadingButton
                            color={hasPermissionToPublish ? 'primary' : 'warning'}
                            variant='contained'
                            disabled={
                                loading ||
                                publishDateError ||
                                accreditationDocumentsError ||
                                !isProcessFormValid ||
                                formLotsHasChanges ||
                                !!Object.keys(processLotsForm.errors).length ||
                                !processLotsForm.values?.lots?.length ||
                                Object.keys(warnings).length !== 0
                            }
                            size='xsmall'
                            {...(publishingProcess
                                ? {
                                      loading: {
                                          text: `${t('term.publishing')}..`,
                                      },
                                  }
                                : {})}
                        >
                            {t('term.puslish')}
                        </LoadingButton>
                    </span>
                </Tooltip>
            </ConfirmDialog>
        </>
    );
};

export default memo(ButtonPublishProcess);
