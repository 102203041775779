import { ButtonBase } from '@material-ui/core';
import styled from 'styled-components';

export const SearchFiltersAsideContainer = styled.aside<{ show?: boolean }>`
    display: ${({ show }) => (show ? 'block' : 'none')};
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    overflow-y: scroll;
    height: fit-content;
    max-height: 60vh;
    border-radius: 4px;
    position: sticky;
    width: 312px;
    top: 122px;
    z-index: 99;
    padding-left: 6px;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #0001;
        border-radius: 2px;
        transition: 0.4s;
    }

    > div {
        flex-direction: column;
        display: flex;
        padding: 16px 10px;
        width: 298px;
        gap: 16px;

        > h1 {
            line-height: normal;
            font-weight: 400;
            font-size: 16px;
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            > button {
                background-color: transparent;
                justify-content: center;
                align-items: center;
                outline: none;
                border: none;
                display: flex;
                padding: 4px;
                cursor: pointer;
            }
        }
    }

    @media (max-width: 1100px) {
        width: 100%;

        > div {
            width: 100%;
        }
    }
`;

export const SuggestedButtonFilter = styled(ButtonBase)<{ active: boolean; color?: string }>`
    border: solid 1px ${({ color = 'var(--primary-color)' }) => color};
    background-color: ${({ active, color = 'var(--primary-color)' }) =>
        active ? color : 'transparent'};
    color: ${({ active, color = 'var(--primary-color)' }) =>
        active ? 'var(--background-color)' : color};
    transition: color 0.4s, background-color 0.4s;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
    padding: 14px;
    display: flex;
    gap: 8px;
`;

export const AdvancedFiltersContainer = styled.div<{ disabled?: boolean }>`
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
    border-top: 1px solid var(--border-color);
    transition: opacity 0.4s;
    flex-direction: column;
    padding-top: 1rem;
    user-select: none;
    display: flex;
    gap: 16px;
`;

export const DatePickerContainer = styled.div`
    width: 100%;

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__aria-live {
        position: absolute;
        margin: -1px;
    }
`;
