export enum AuthorizationActionsEnum {
    verRelatoriosGerados = 'verRelatoriosGerados',
    listarFornecedores = 'listarFornecedores',
    verDadosDaOrganizacao = 'verDadosDaOrganizacao',
    alterarModelosDeDocumentos = 'alterarModelosDeDocumentos',
    verListaDePedidosDeAtualizacaoCadastral = 'verListaDePedidosDeAtualizacaoCadastral',
    listarUsuarios = 'listarUsuarios',
    incluirRelatoriosGerados = 'incluirRelatoriosGerados',
    incluirModelosDeDocumentos = 'incluirModelosDeDocumentos',
    incluirGruposDeAutorizacoes = 'incluirGruposDeAutorizacoes',
    alterarRelatoriosGerados = 'alterarRelatoriosGerados',
    excluirRelatoriosGerados = 'excluirRelatoriosGerados',
    excluirAvisosEInformacoes = 'excluirAvisosEInformacoes',
    verDadosDosSegmentosDeFornecimentos = 'verDadosDosSegmentosDeFornecimentos',
    verDadosDasConfiguracoesDaPlataforma = 'verDadosDasConfiguracoesDaPlataforma',
    exportarExcelRelatoriosGerados = 'exportarExcelRelatoriosGerados',
    listarAutomacoes = 'listarAutomacoes',
    verDadosAutomacao = 'verDadosAutomacao',
    verDadosDosUsuarios = 'verDadosDosUsuarios',
    listarAcoesDaAuditoria = 'listarAcoesDaAuditoria',
    listarUsuariosDaOrganizacao = 'listarUsuariosDaOrganizacao',
    verDadosDosUsuariosDaOrganizacao = 'verDadosDosUsuariosDaOrganizacao',
    alterarVinculoDeUsuariosDaOrganizacao = 'alterarVinculoDeUsuariosDaOrganizacao',
    excluirUsuariosDaOrganizacao = 'excluirUsuariosDaOrganizacao',
    exportarDadosDosProcessos = 'exportarDadosDosProcessos',
    listarMarketplace = 'listarMarketplace',
    gerirPrioridadeEResponsavelDoPedido = 'gerirPrioridadeEResponsavelDoPedido',
    verDadosDosFornecedores = 'verDadosDosFornecedores',
    incluirOrganizacoes = 'incluirOrganizacoes',
    incluirFeriados = 'incluirFeriados',
    verDetalhesDasAcoesNaAuditoria = 'verDetalhesDasAcoesNaAuditoria',
    listarGruposDeAutorizacoes = 'listarGruposDeAutorizacoes',
    alterarAvisosEInformacoes = 'alterarAvisosEInformacoes',
    excluirFeriados = 'excluirFeriados',
    listarModelosDeDocumentos = 'listarModelosDeDocumentos',
    incluirAvisosEInformacoes = 'incluirAvisosEInformacoes',
    listarProcessos = 'listarProcessos',
    verDadosDosProcessos = 'verDadosDosProcessos',
    aprovarPublicacaoDosProcessos = 'aprovarPublicacaoDosProcessos',
    alterarDocumentosParaCredenciamentos = 'alterarDocumentosParaCredenciamentos',
    listarDeclaracoes = 'listarDeclaracoes',
    verDadosDosModelosDeDocumentos = 'verDadosDosModelosDeDocumentos',
    alterarGruposDeAutorizacoes = 'alterarGruposDeAutorizacoes',
    verDadosDosAvisosEInformacoes = 'verDadosDosAvisosEInformacoes',
    alterarSegmentosDeFornecimentos = 'alterarSegmentosDeFornecimentos',
    excluirSegmentosDeFornecimentos = 'excluirSegmentosDeFornecimentos',
    verDadosDoModelosDeDocumento = 'verDadosDoModelosDeDocumento',
    gerarDocumentoComBaseEmModelos = 'gerarDocumentoComBaseEmModelos',
    verDadosDosGruposDeAutorizacoes = 'verDadosDosGruposDeAutorizacoes',
    listarMateriais = 'listarMateriais',
    incluirAutomacoes = 'incluirAutomacoes',
    alterarDadosDaOrganizacao = 'alterarDadosDaOrganizacao',
    incluirUnidadesCompradoras = 'incluirUnidadesCompradoras',
    listarRegrasDeModalidades = 'listarRegrasDeModalidades',
    verRegrasDeModalidades = 'verRegrasDeModalidades',
    incluirRegrasDeModalidades = 'incluirRegrasDeModalidades',
    alterarRegrasDeModalidades = 'alterarRegrasDeModalidades',
    listarAvisosEInformacoes = 'listarAvisosEInformacoes',
    listarUnidadesCompradoras = 'listarUnidadesCompradoras',
    alterarAutomacoes = 'alterarAutomacoes',
    incluirUsuarios = 'incluirUsuarios',
    excluirAutomacoes = 'excluirAutomacoes',
    listarFeriados = 'listarFeriados',
    alterarUnidadesCompradoras = 'alterarUnidadesCompradoras',
    gerarRelatorioDeAlcanceDoProcesso = 'gerarRelatorioDeAlcanceDoProcesso',
    incluirDeclaracoes = 'incluirDeclaracoes',
    alterarDeclaracoes = 'alterarDeclaracoes',
    excluirDeclaracao = 'excluirDeclaracao',
    alterarUsuarios = 'alterarUsuarios',
    alterarFeriados = 'alterarFeriados',
    verDadosDosFeriados = 'verDadosDosFeriados',
    finalizarPedidoDeAtualizacaoCadastral = 'finalizarPedidoDeAtualizacaoCadastral',
    alterarDadosDoDesignDaPlataformaNasConfiguracoes = 'alterarDadosDoDesignDaPlataformaNasConfiguracoes',
    alterarDadosDeConfiguracoesDeArquivosDaPlataforma = 'alterarDadosDeConfiguracoesDeArquivosDaPlataforma',
    incluirSegmentosDeFornecimentos = 'incluirSegmentosDeFornecimentos',
    alterarOrganizacoes = 'alterarOrganizacoes',
    verValoresDeReferenciaAntesDaAdjudicacao = 'verValoresDeReferenciaAntesDaAdjudicacao',
    validarDocumentosDosFornecedores = 'validarDocumentosDosFornecedores',
    incluirDocumentosParaCredenciamentos = 'incluirDocumentosParaCredenciamentos',
    alterarEtapasDoProcesso = 'alterarEtapasDoProcesso',
    verDocumentosDosFornecedores = 'verDocumentosDosFornecedores',
    exportarListaDePedidosDeAtualizacaoCadastral = 'exportarListaDePedidosDeAtualizacaoCadastral',
    verDadosDosLogsDeErrosDoSistema = 'verDadosDosLogsDeErrosDoSistema',
    verDadosDasOrganizacoes = 'verDadosDasOrganizacoes',
    clonarProcessos = 'clonarProcessos',
    listarLogsDeErrosDoSistema = 'listarLogsDeErrosDoSistema',
    listarPedidos = 'listarPedidos',
    alterarDadosDeConfiguracoesGeraisDaPlataforma = 'alterarDadosDeConfiguracoesGeraisDaPlataforma',
    listarSegmentosDeFornecimentos = 'listarSegmentosDeFornecimentos',
    listarOrganizacoes = 'listarOrganizacoes',
    listarDocumentosParaCredenciamentos = 'listarDocumentosParaCredenciamentos',
    alterarDadosDaPlataformaNasConfiguracoes = 'alterarDadosDaPlataformaNasConfiguracoes',
    alterarFornecedores = 'alterarFornecedores',
    listarContratos = 'listarContratos',
    alterarAcoesDosGruposDeAutorizacoes = 'alterarAcoesDosGruposDeAutorizacoes',
    verAcoesDosGruposDeAutorizacoes = 'verAcoesDosGruposDeAutorizacoes',
    listarRelatoriosGerados = 'listarRelatoriosGerados'
}
