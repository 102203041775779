import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface financialDataProps {
    id: number;
    userId: number | null;
    pixType: string | number | null;
    bankCode: string | null;
    pixValue: string | number | null;
    createdAt: string | null;
    deletedAt: string | null;
    updatedAt: string | null;
    methodType: string | null;
    providerId: string | null;
    accountType: string | null;
    accountAgency: string | null;
    accountNumber: string | null;
    docNumberReceiver?: string | null;
}

export interface financialProps {
    data: financialDataProps;
    type: string;
}

interface TableCompareFinancialDataProps {
    financialData: financialProps[];
}

const TableFinancialDataView: React.FC<TableCompareFinancialDataProps> = ({ financialData }) => {
    const { t } = useTranslation();

    return (
        <TableContainer component={Paper} style={{ marginTop: '15px' }}>
            <Table style={{ minWidth: 450 }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}>
                            {t('term.action')}
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}>
                            {t('info.financial-data')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {financialData.map((item) => {
                        const data = Array.isArray(item.data) ? item.data[0] : item.data;
                        const { bankCode = '', accountType = '', docNumberReceiver = '', accountAgency = '', accountNumber = '' } = data;

                        return (
                            <TableRow>
                                <TableCell
                                    style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}
                                >
                                    {t(`term.${item.type}`)}
                                </TableCell>
                                {data ? (<TableCell>
                                    {bankCode} - Agência {accountAgency},{' '}
                                    {accountType} {accountNumber} -{' '}
                                    {docNumberReceiver}
                                </TableCell>) : (<TableCell>
                                    -
                                </TableCell>)}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableFinancialDataView;
