export interface Signer {
    name?: string;
    email: string;
    signatureDate: Date | null;
    signatureStatus?: DocumentSignersSignatureStatus;
}

export enum DocumentSignersSignatureStatus {
    pendente = 'pendente',
    assinado = 'assinado',
}

export interface DocumentSigners {
    signers?: Signer[];
    signDocLicitarUrl?: string;
    id?: string;
}

//PATCH documentSigner/:id
export interface DocumentSignerUpdate {
    signers: Signer[];
}

export enum DocumentSignersStatus {
    pending = 'pending',
    signed = 'signed',
}
