import {
    AiOutlineDollar,
    AiOutlineFire,
    AiOutlineHeart,
    AiOutlineIdcard,
    AiOutlinePieChart,
    AiOutlineSearch,
} from 'react-icons/ai';
import { PiFiles } from 'react-icons/pi';
import {
    MdBalance,
    MdOutlineCalendarToday,
    MdOutlineHomeRepairService,
    MdOutlinePlaylistAddCheck,
    MdOutlineUpdate,
    MdPlaylistAdd,
} from 'react-icons/md';
import { RiAuctionLine, RiCodeSSlashLine, RiShoppingCart2Line } from 'react-icons/ri';
import {
    IoBusinessOutline,
    IoDocumentTextOutline,
    IoFlagOutline,
    IoLayersOutline,
    IoPeopleOutline,
    IoPersonOutline,
    IoSearchOutline,
    IoNewspaperOutline,
    IoEnterOutline,
} from 'react-icons/io5';
import { BsBoxSeam, BsPuzzle } from 'react-icons/bs';
import { FiExternalLink, FiSettings } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import { VscDashboard } from 'react-icons/vsc';
import { TbDatabaseSearch, TbWritingSign } from 'react-icons/tb';
import { TypeUser } from 'common/interfaces/user.interface';
import { featureFlagEnabled, hasPermissionToExecute } from 'common/utils';
import {
    defaultPrivacyPolicyUrl,
    defaultTermsOfServiceUrl,
} from 'common/constants/redirect-urls.constant';
import { usePlatformContext } from 'routes/platform.context';
import { HiOutlineReceiptTax, HiOutlineSparkles } from 'react-icons/hi';
import { CiBoxes } from 'react-icons/ci';
import { GiFishingNet } from 'react-icons/gi';
import { BiRadar } from 'react-icons/bi';
import { Platform } from 'clients/manager/platforms.requests';
import { OptionMenu, OptionMenuGroup, OptionMenuGroupSubItem } from './menu.interface';
import { FaBalanceScale } from 'react-icons/fa';

interface ValidateParams {
    setting?: string;
    featureFlag?: string;
}

const getOptions = (
    redirectToPhp: (path: string) => void,
    pushHistory: (path: string) => void,
    currentAccessType: TypeUser,
    isSystemAdmin: boolean
) => {
    const { t } = useTranslation();
    const { platform } = usePlatformContext();

    const getNestedProperty = (obj?: Platform, keyPath?: string) => {
        return keyPath?.split('.').reduce((acc, key) => acc?.[key], obj?.generalSettings);
    };

    const validateFeatureOrSetting = (params: ValidateParams): boolean | undefined => {
        const isSettingEnabled = params.setting
            ? getNestedProperty(platform, params.setting)
            : true;
        const isFeatureEnabled = params.featureFlag ? featureFlagEnabled(params.featureFlag) : true;
        return isSettingEnabled && isFeatureEnabled;
    };

    const linkOptions: OptionMenuGroupSubItem[] = [
        {
            icon: IoIosHelpCircleOutline,
            label: t('shared.pages.menu-header.option.system-help'),
            action: () => {
                if (currentAccessType === TypeUser.admin) {
                    window
                        .open(
                            'https://devlicitardigital.tawk.help/category/menu-de-administra%C3%A7%C3%A3o',
                            '_blank'
                        )
                        ?.focus();
                }

                if (currentAccessType === TypeUser.provider) {
                    redirectToPhp('/ajuda');
                }

                if (currentAccessType === TypeUser.organization) {
                    redirectToPhp('/ajuda');
                }

                if (currentAccessType === TypeUser.citizen) {
                    window
                        .open('https://licitardigital.tawk.help/category/cidadão', '_blank')
                        ?.focus();
                }
            },
        },
        {
            icon: IoNewspaperOutline,
            label: t('shared.pages.menu-header.option.notices-information'),
            action: () => pushHistory('/avisos-informacoes'),
            disabled: !hasPermissionToExecute('listarAvisosEInformacoes'),
        },
    ];

    const usefulLinksOptions: OptionMenuGroup[] = [
        {
            label: t('shared.pages.menu-header.option.useful-links'),
            options: [...linkOptions],
        },
    ];

    const getDashboardAction = () => {
        switch (currentAccessType) {
            case TypeUser.provider:
            case TypeUser.citizen:
                return undefined;
            default:
                return () => redirectToPhp('/painel');
        }
    };
    const getDashboardPath = () => {
        switch (currentAccessType) {
            case TypeUser.provider:
                return 'painel-fornecedor';
            case TypeUser.citizen:
                return 'painel-cidadao';
            default:
                return undefined;
        }
    };

    const dashboardGroupOptions: OptionMenuGroup = {
        label: '',
        options: [
            {
                icon: VscDashboard,
                label: t('shared.pages.menu-header.option.panel'),
                action: getDashboardAction(),
                path: getDashboardPath(),
            },
        ],
    };

    const priceBaseOptions: OptionMenuGroup[] = [
        {
            label: t('shared.pages.menu-header.option.price-bank'),
            options: [
                {
                    icon: IoSearchOutline,
                    label: t('term.search'),
                    path: 'banco-precos/pesquisa',
                },
                {
                    icon: MdPlaylistAdd,
                    label: t('shared.pages.menu-header.option.my-quotes'),
                    path: 'banco-precos/minhas-listas',
                },
            ],
        },
    ];

    const marketplaceOptions: OptionMenuGroup[] = [
        {
            label: t('term.marketplace'),
            options: [
                {
                    icon: RiShoppingCart2Line,
                    label: t('term.marketplace'),
                    action: () => pushHistory('/mercado'),
                    disabled: !hasPermissionToExecute('listarMarketplace'),
                },
                {
                    icon: PiFiles,
                    label: t('term.orders'),
                    action: () => pushHistory('/mercado/pedidos'),
                    disabled: !hasPermissionToExecute('listarMarketplace'),
                },
            ],
        },
    ];

    const getProcessSearchOptions = (): OptionMenuGroupSubItem[] => [
        {
            icon: AiOutlineSearch,
            label: t('shared.pages.menu-header.option.search'),
            action: () => pushHistory('/pesquisa'),
        },
        {
            icon: AiOutlineFire,
            label: t('shared.pages.menu-header.option.suggested'),
            action: () => pushHistory('/pesquisa?onlySuggested'),
        },
        {
            icon: AiOutlineDollar,
            label: t('shared.pages.menu-header.option.proposal'),
            action: () => pushHistory('/pesquisa?onlyProposal'),
        },
        {
            icon: AiOutlineHeart,
            label: t('shared.pages.menu-header.option.favorites'),
            action: () => pushHistory('/pesquisa?onlyFavorites'),
        },
    ];

    const getProcessesOrganizationOptions: OptionMenuGroup[] = [
        {
            label: t('shared.pages.menu-header.option.electronic-processes'),
            options: [
                {
                    icon: RiAuctionLine,
                    label: t('shared.pages.menu-header.option.in-progress-processes'),
                    action: () => pushHistory('/processos'),
                    disabled: !hasPermissionToExecute('listarProcessos') && !isSystemAdmin,
                },
                {
                    icon: IoFlagOutline,
                    label: t('shared.pages.menu-header.option.finished-processes'),
                    action: () => redirectToPhp('/processosfinalizados'),
                    disabled: !hasPermissionToExecute('listarProcessos'),
                },
            ],
        },
    ];

    const getSharedPurchasingOptions: OptionMenuGroup[] = [
        {
            label: t('shared.pages.menu-header.option.planning'),
            options: [
                {
                    icon: IoBusinessOutline,
                    label: t('shared.pages.menu-header.option.participants'),
                    action: () => redirectToPhp('/participantes'),
                    disabled: !hasPermissionToExecute('listarParticipantes') && !isSystemAdmin,
                },
                {
                    icon: GiFishingNet,
                    label: t('shared.pages.menu-header.option.generate-demands'),
                    action: () => redirectToPhp('/demandas'),
                    disabled: !hasPermissionToExecute('listarDemandas'),
                },
                {
                    icon: BiRadar,
                    label: t('shared.pages.menu-header.option.search-demands'),
                    action: () => redirectToPhp('/buscar-demandas'),
                    disabled: !hasPermissionToExecute('listarBuscaDemandas'),
                },
            ],
        },
    ];

    const getQueriesOptions: OptionMenuGroup[] = [
        {
            label: t('shared.pages.menu-header.option.queries'),
            options: [
                {
                    icon: TbDatabaseSearch,
                    label: t('shared.pages.menu-header.option.report-generator'),
                    action: () => redirectToPhp('/gerador-relatorios'),
                    visible: featureFlagEnabled('habilitarGeradorDeRelatorios'),
                    disabled: !hasPermissionToExecute('listarRelatoriosGerados'),
                },
                {
                    icon: RiCodeSSlashLine,
                    label: t('shared.pages.menu-header.option.integrations'),
                    action: () => redirectToPhp('/integracoes'),
                    disabled: !hasPermissionToExecute('verDadosDaOrganizacao'),
                },
            ],
        },
    ];

    const options: OptionMenu = {
        [TypeUser.organization]: [
            dashboardGroupOptions,
            ...(validateFeatureOrSetting({
                setting: 'purchasePlanning.enableModule',
                featureFlag: 'habilitarComprasCompartilhadas',
            })
                ? getSharedPurchasingOptions
                : []),
            ...(validateFeatureOrSetting({
                setting: 'process.enableModule',
            })
                ? getProcessesOrganizationOptions
                : []),
            ...(validateFeatureOrSetting({
                setting: 'marketplace.enableModule',
                featureFlag: 'habilitarMarketplace',
            })
                ? marketplaceOptions
                : []),
            ...(validateFeatureOrSetting({
                setting: 'priceBase.enableModule',
            })
                ? priceBaseOptions
                : []),
            ...(validateFeatureOrSetting({
                setting: 'process.enableModule',
            })
                ? getQueriesOptions
                : []),
            {
                label: t('shared.pages.menu-header.option.records'),
                path: 'processos/editor',
                options: [
                    {
                        icon: IoBusinessOutline,
                        label: t('shared.pages.menu-header.option.organization-data'),
                        action: () => pushHistory('/organizacao/editar'),
                        disabled: !hasPermissionToExecute('verDadosDaOrganizacao'),
                    },
                    {
                        icon: AiOutlinePieChart,
                        label: t('shared.pages.menu-header.option.hiring-plans'),
                        visible: validateFeatureOrSetting({
                            setting: 'hiringPlans.enableModule',
                        }),
                        action: () => pushHistory('/planos-contratacao'),
                    },
                    {
                        icon: HiOutlineSparkles,
                        label: t('shared.pages.menu-header.option.automations'),
                        visible: validateFeatureOrSetting({
                            setting: 'process.enableModule',
                            featureFlag: 'gerenciarRegrasDeAutomacoes',
                        }),
                        action: () => redirectToPhp('/automacoes'),
                        disabled: !hasPermissionToExecute('listarRegrasDeAutomacoes'),
                    },
                    {
                        icon: BsPuzzle,
                        label: t('shared.pages.menu-header.option.rules-modalities'),
                        visible: validateFeatureOrSetting({
                            setting: 'process.enableModule',
                            featureFlag: 'gerenciarRegrasEModalidades',
                        }),
                        action: () => redirectToPhp('/modalidades'),
                        disabled: !hasPermissionToExecute('listarRegrasDeModalidades'),
                    },
                    {
                        icon: IoLayersOutline,
                        label: t('shared.pages.menu-header.option.new-document-template'),
                        action: () => pushHistory('/processos/editor/modelos'),
                        visible: validateFeatureOrSetting({
                            setting: 'process.enableModule',
                        }),
                        disabled: !hasPermissionToExecute('listarModelosDeDocumentos'),
                    },
                    {
                        icon: IoPeopleOutline,
                        label: t('shared.pages.menu-header.option.authorization-groups'),
                        action: () => pushHistory('/grupo-autorizacao'),
                        disabled: !hasPermissionToExecute('listarGruposDeAutorizacoes'),
                    },
                    {
                        icon: IoPersonOutline,
                        label: t('term.users'),
                        action: () => pushHistory('/usuarios-organizacao'),
                        disabled: !hasPermissionToExecute('listarUsuariosDaOrganizacao'),
                    },
                    {
                        icon: IoDocumentTextOutline,
                        label: t('shared.pages.menu-header.option.documents-license'),
                        action: () => pushHistory('/tipos-documentos'),
                        visible: validateFeatureOrSetting({
                            setting: 'process.enableModule',
                        }),
                        disabled: !hasPermissionToExecute('listarDocumentosParaCredenciamentos'),
                    },
                    {
                        icon: TbWritingSign,
                        label: t('shared.pages.menu-header.option.contracts'),
                        action: () => pushHistory('/contratos'),
                        visible: validateFeatureOrSetting({
                            setting: 'process.enableModule',
                        }),
                        disabled: !hasPermissionToExecute('listarContratos'),
                    },
                    {
                        icon: MdOutlineCalendarToday,
                        label: t('shared.pages.menu-header.option.holidays'),
                        action: () => pushHistory('/feriados'),
                        visible: validateFeatureOrSetting({
                            setting: 'process.enableModule',
                        }),
                        disabled: !hasPermissionToExecute('listarFeriados'),
                    },
                    {
                        icon: MdOutlinePlaylistAddCheck,
                        label: t('term.declarations'),
                        action: () => redirectToPhp('/declaracoes'),
                        visible: validateFeatureOrSetting({
                            setting: 'process.enableModule',
                        }),
                        disabled: !hasPermissionToExecute('listarDeclaracoes'),
                    },
                    {
                        icon: CiBoxes,
                        label: t('material.pages.title'),
                        action: () => pushHistory('/materiais'),
                        visible: validateFeatureOrSetting({
                            setting: 'marketplace.enableModule',
                            featureFlag: 'habilitarCadastroDeMateriais',
                        }),
                    },
                ],
            },
            ...(validateFeatureOrSetting({
                setting: 'general.hideSystemHelpOrganization',
            })
                ? []
                : usefulLinksOptions),
        ],
        [TypeUser.provider]: [
            dashboardGroupOptions,
            {
                label: t('shared.pages.menu-header.option.biddings'),
                options: getProcessSearchOptions(),
            },
            {
                label: t('shared.pages.menu-header.option.my-data'),
                options: [
                    {
                        icon: IoBusinessOutline,
                        label: t('shared.pages.menu-header.option.supplier-data'),
                        action: () => pushHistory('/empresa/editar'),
                    },
                    {
                        icon: HiOutlineReceiptTax,
                        label: t('term.invoices'),
                        action: () => pushHistory('/faturas'),
                        visible: validateFeatureOrSetting({
                            setting: 'invoices.enableModule',
                        }),
                        disabled: !validateFeatureOrSetting({
                            setting: 'invoices.enableModule',
                        }),
                    },
                    {
                        icon: IoPersonOutline,
                        label: t('term.users'),
                        action: () => pushHistory('/usuarios-fornecedor'),
                    },
                    {
                        icon: IoDocumentTextOutline,
                        label: t('shared.pages.menu-header.option.my-documents'),
                        action: () => redirectToPhp('/documentacao'),
                    },
                    {
                        icon: IoPersonOutline,
                        label: t('shared.pages.menu-header.option.user-profile'),
                        action: () => pushHistory('/meu-perfil'),
                    },
                ],
            },
            ...(validateFeatureOrSetting({
                setting: 'general.hideSystemHelpProvider',
            })
                ? []
                : usefulLinksOptions),
        ],
        [TypeUser.admin]: [
            dashboardGroupOptions,
            {
                label: t('shared.pages.menu-header.option.suppliers'),
                options: [
                    {
                        icon: IoEnterOutline,
                        label: t('shared.pages.menu-header.option.access-suppliers'),
                        action: () => redirectToPhp('/adminfornecedores'),
                        disabled: !hasPermissionToExecute('listarFornecedores'),
                    },
                    {
                        icon: MdOutlineHomeRepairService,
                        label: t('shared.pages.menu-header.option.suppliers'),
                        action: () => pushHistory('/adminfornecedores'),
                        disabled: !hasPermissionToExecute('listarFornecedores'),
                    },
                ],
            },
            {
                label: t('shared.pages.menu-header.option.records'),
                options: [
                    {
                        icon: IoBusinessOutline,
                        label: t('shared.pages.menu-header.option.organizations'),
                        action: () => redirectToPhp('/adminorganizacoes'),
                        disabled: !hasPermissionToExecute('listarOrganizacoes'),
                    },
                    {
                        icon: RiAuctionLine,
                        label: t('shared.pages.menu-header.option.processes'),
                        action: () => redirectToPhp('/processosfinalizados'),
                        disabled: !hasPermissionToExecute('listarProcessos'),
                    },
                    {
                        icon: IoPersonOutline,
                        label: t('shared.pages.menu-header.option.users-administration'),
                        action: () => redirectToPhp('/acesso'),
                        disabled: !hasPermissionToExecute('listarUsuarios'),
                    },
                    {
                        icon: IoPeopleOutline,
                        label: t('shared.pages.menu-header.option.authorization-groups'),
                        action: () => pushHistory('/grupo-autorizacao'),
                        disabled: !hasPermissionToExecute('listarGruposDeAutorizacoes'),
                    },
                    {
                        icon: MdOutlineUpdate,
                        label: t('info.update-request'),
                        action: () => pushHistory('/administracao/pedidos-atualizacao-cadastral'),
                        disabled: !hasPermissionToExecute(
                            'verListaDePedidosDeAtualizacaoCadastral'
                        ),
                    },
                    {
                        icon: IoDocumentTextOutline,
                        label: t('shared.pages.menu-header.option.documents-license'),
                        action: () => redirectToPhp('/tiposdedocumentos'),
                        disabled: !hasPermissionToExecute('listarDocumentosParaCredenciamentos'),
                    },
                    {
                        icon: TbWritingSign,
                        label: t('shared.pages.menu-header.option.contracts'),
                        action: () => pushHistory('/contratos'),
                        disabled: !hasPermissionToExecute('listarContratos'),
                    },
                    {
                        icon: BsBoxSeam,
                        label: t('shared.pages.menu-header.option.supply-segments'),
                        action: () => redirectToPhp('/adminlinhasfornecimento'),
                        disabled: !hasPermissionToExecute('listarSegmentosDeFornecimentos'),
                    },
                    {
                        icon: CiBoxes,
                        label: t('material.pages.title'),
                        action: () => pushHistory('/materiais'),
                        visible: featureFlagEnabled('habilitarCadastroDeMateriais'),
                        disabled: !hasPermissionToExecute('listarMateriais'),
                    },
                    {
                        icon: MdOutlineCalendarToday,
                        label: t('shared.pages.menu-header.option.general-holidays'),
                        action: () => pushHistory('/feriados'),
                        disabled: !hasPermissionToExecute('listarFeriados'),
                    },
                    {
                        icon: IoLayersOutline,
                        label: t('shared.pages.menu-header.option.templates-library'),
                        action: () => pushHistory('/processos/editor/modelos'),
                        disabled: !hasPermissionToExecute('listarModelosDeDocumentos'),
                    },
                    {
                        icon: BsPuzzle,
                        label: 'Regras / Modalidades',
                        action: () => redirectToPhp('/modalidades'),
                        disabled: !hasPermissionToExecute('listarRegrasDeModalidades'),
                    },
                    {
                        icon: FiSettings,
                        label: t('term.settings'),
                        action: () => redirectToPhp('/configuracoes'),
                        disabled: !hasPermissionToExecute('verDadosDasConfiguracoesDaPlataforma'),
                    },
                ],
            },
            {
                label: t('shared.pages.menu-header.option.my-data'),
                options: [
                    {
                        icon: IoPersonOutline,
                        label: t('shared.pages.menu-header.option.user-profile'),
                        action: () => pushHistory('/meu-perfil'),
                    },
                ],
            },
            ...(validateFeatureOrSetting({
                setting: 'general.hideSystemHelpAdmin',
            })
                ? []
                : usefulLinksOptions),
            {
                label: t('shared.pages.menu-header.option.management'),
                options: [
                    {
                        icon: AiOutlineIdcard,
                        label: t('shared.pages.menu-header.option.invoice'),
                        action: () => redirectToPhp('/adminfaturas'),
                        disabled: !hasPermissionToExecute('listarFaturas'),
                    },
                    {
                        icon: AiOutlineIdcard,
                        label: t('shared.pages.menu-header.option.billing'),
                        action: () => redirectToPhp('/adminfaturamento'),
                        disabled: !hasPermissionToExecute('listarFaturamento'),
                    },
                    {
                        icon: RiAuctionLine,
                        label: t('shared.pages.menu-header.option.processes'),
                        action: () => redirectToPhp('/adminlicitacoes'),
                        disabled: !hasPermissionToExecute('ListarProcessos'),
                    },
                    {
                        icon: RiCodeSSlashLine,
                        label: 'logs',
                        action: () => redirectToPhp('/adminlogs'),
                        disabled: !hasPermissionToExecute('listarLogsDeErrosDoSistema'),
                    },
                    {
                        icon: RiCodeSSlashLine,
                        label: t('shared.pages.menu-header.option.audit'),
                        path: 'auditoria/lista',
                        disabled: !hasPermissionToExecute('listarAcoesDaAuditoria'),
                    },
                    {
                        icon: MdBalance,
                        label: t('shared.pages.menu-header.option.scale-dispute'),
                        action: () => redirectToPhp('/dispute-service'),
                        disabled: !hasPermissionToExecute('listarEscalaDisputa'),
                    },
                    {
                        icon: TbDatabaseSearch,
                        label: t('shared.pages.menu-header.option.api-user-management'),
                        action: () => redirectToPhp('/gestao-usuarios-api'),
                        disabled: !hasPermissionToExecute('listarUsarioDeApi'),
                    },
                ],
            },
        ],
        [TypeUser.citizen]: [
            dashboardGroupOptions,
            {
                label: t('shared.pages.menu-header.option.biddings'),
                options: [
                    {
                        icon: IoSearchOutline,
                        label: t('shared.pages.menu-header.option.search'),
                        action: () => pushHistory('/pesquisa'),
                    },
                    {
                        icon: AiOutlineHeart,
                        label: t('shared.pages.menu-header.option.favorites'),
                        action: () => pushHistory('/pesquisa?onlyFavorites'),
                    },
                ],
            },
            {
                label: t('shared.pages.menu-header.option.my-data'),
                options: [
                    {
                        icon: IoPersonOutline,
                        label: t('shared.pages.menu-header.option.user-profile'),
                        action: () => pushHistory('/meu-perfil'),
                    },
                    {
                        icon: IoBusinessOutline,
                        label: t('shared.pages.menu-header.option.register-supplier'),
                        path: 'cadastro-fornecedor',
                        visible: !validateFeatureOrSetting({
                            setting: 'general.hideSignupProvider',
                        }),
                    },
                    {
                        icon: IoBusinessOutline,
                        label: t('shared.pages.menu-header.option.register-organization'),
                        path: 'cadastro-organizacao',
                        visible: !validateFeatureOrSetting({
                            setting: 'general.hideSignupOrganization',
                        }),
                    },
                ],
            },
            {
                label: t('shared.pages.menu-header.option.useful-links'),
                options: [
                    ...(validateFeatureOrSetting({
                        setting: 'general.hideSystemHelpCitizen',
                    })
                        ? []
                        : linkOptions),
                    {
                        icon: FiExternalLink,
                        label: t('shared.pages.menu-header.option.privacy-policy'),
                        external: true,
                        action: () => {
                            const win = window.open(
                                platform?.privacyPolicyUrl
                                    ? platform?.privacyPolicyUrl
                                    : defaultPrivacyPolicyUrl,
                                '_blank'
                            );
                            win?.focus();
                        },
                    },
                    {
                        icon: FiExternalLink,
                        label: t('shared.pages.menu-header.option.terms-of-use'),
                        external: true,
                        action: () => {
                            const win = window.open(
                                platform?.termsOfService
                                    ? platform?.termsOfService
                                    : defaultTermsOfServiceUrl,
                                '_blank'
                            );
                            win?.focus();
                        },
                    },
                ],
            },
        ],
    };

    return options;
};

export default getOptions;
