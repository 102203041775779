import React from 'react';

import { FormikProps } from 'formik';
import { CheckoutFormValues } from 'modules/marketplace/pages/checkout-page';
import CreditCardView from './credit-card-view';

interface CreditCardProps {
    form: FormikProps<CheckoutFormValues>;
}

const CreditCard: React.FC<CreditCardProps> = ({ form }) => {
    return <CreditCardView form={form} />;
};

export default CreditCard;
