import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { disableField, processUtils } from 'modules/process/process-utils';
import { useProcessFormContext } from '../../../../context/process-form.context';
import HelpIconLabel from '../../../help-icon-label';

interface PhaseReversalFieldProps {
    handleChangeDefault: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PhaseReversalField: FC<PhaseReversalFieldProps> = ({ handleChangeDefault }) => {
    const { processForm, auctionTypeRules, process } = useProcessFormContext();
    const { t } = useTranslation();

    const isNewLaw = processUtils.isNewLaw(processForm?.values?.legalSupportId);

    const classificationIsEnabled =
        !auctionTypeRules?.generalSettings?.processo?.desabilitarClassificacaoPropostas;

    const phaseReversalOptions = useMemo(() => {
        const opts: ReactNode[] = [];

        if (auctionTypeRules?.generalSettings.processo?.sequenciaFases?.normal)
            opts.push(
                <MenuItem value={0}>
                    {classificationIsEnabled ? `${t('term.classification')} > ` : ''}
                    {t('term.dispute')}
                    {' >'} {t('term.qualification')}
                </MenuItem>
            );

        if (auctionTypeRules?.generalSettings.processo?.sequenciaFases?.invertida && isNewLaw)
            opts.push(
                <MenuItem value={1}>
                    {t('term.qualification')} {'>'}{' '}
                    {classificationIsEnabled ? `${t('term.classification')} > ` : ''}
                    {t('term.dispute')}
                </MenuItem>
            );

        return opts;
    }, [auctionTypeRules, isNewLaw]);

    useEffect(() => {
        if (isNewLaw === false && processForm?.values?.isPhaseReversal !== 0) {
            processForm?.setFieldValue('isPhaseReversal', 0);
        }
    }, [isNewLaw]);

    return (
        <HelpIconLabel
            title={isNewLaw ? t('info.phase-reversal') : t('info.disabled-phase-reversal')}
        >
            {isNewLaw ? (
                <TextField
                    fullWidth
                    name='isPhaseReversal'
                    color='primary'
                    label={t('info.phases-order')}
                    onChange={handleChangeDefault}
                    variant='outlined'
                    select
                    defaultValue={processForm?.values.isPhaseReversal}
                    disabled={disableField('isPhaseReversal', process)}
                >
                    {phaseReversalOptions}
                </TextField>
            ) : (
                <TextField
                    fullWidth
                    color='primary'
                    label={t('info.phases-order')}
                    variant='outlined'
                    value={`${t('term.classification')} > ${t('term.dispute')} > ${t(
                        'term.qualification'
                    )}`}
                    disabled
                />
            )}
        </HelpIconLabel>
    );
};

export default PhaseReversalField;
