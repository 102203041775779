import { doRequest, managerApiInstance } from '../api';
import { OkResponse } from '../interfaces/ok.interface';
import { GenericResponse, PlainGenericResponse } from '../interfaces/paginated.interface';
import {
    ExportProviderRegistrationRequestsExcel,
    GetRegistrationUpdateRequest,
    ListChangesToCompareRequest,
    ListProviderRequestsChangesToCompare,
    ProviderRegistrationUpdateRequest,
    RegistrationUpdateRequests,
} from './interfaces/provider-registration-update-requests.interface';
import { DataProviderUpdateRequest } from './interfaces/provider.interface';

const providerRegistrationUpdateRequests = {
    listProviderRegistrationRequests: async (filters: RegistrationUpdateRequests) =>
        doRequest<GenericResponse<ProviderRegistrationUpdateRequest>>(
            managerApiInstance.post(
                '/provider-registration-update-request/listProviderRegistrationRequests',
                filters
            )
        ),
    updateRegistrationUpdateRequest: async (data: DataProviderUpdateRequest) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(
                '/provider-registration-update-request/updateRegistrationUpdateRequest',
                data
            )
        ),
    doExportProviderRegistrationRequestsExcel: async (
        data?: ExportProviderRegistrationRequestsExcel
    ) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(
                '/provider-registration-update-request/doExportProviderRegistrationRequestsExcel',
                data,
                { responseType: 'blob' }
            )
        ),
    getRegistrationUpdateRequest: async (data?: GetRegistrationUpdateRequest) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(
                '/provider-registration-update-request/getRegistrationUpdateRequest',
                data
            )
        ),
    getRegistrationUpdateRequestById: async (providerId: number) =>
        doRequest<GenericResponse<ProviderRegistrationUpdateRequest>>(
            managerApiInstance.post(
                '/provider-registration-update-request/listProviderRegistrationRequestsById',
                { providerId }
            )
        ),
    listChangesToCompare: async (body: ListChangesToCompareRequest) =>
        doRequest<PlainGenericResponse<ListProviderRequestsChangesToCompare>>(
            managerApiInstance.post(
                '/provider-registration-update-request/listChangesToCompare',
                body
            )
        ),
    sendRegistrationUpdateRequestToReview: async (processId?: number) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(
                '/provider-registration-update-request/sendRegistrationUpdateRequestToReview',
                { processId }
            )
        ),
};

export { providerRegistrationUpdateRequests };
