import { makeStyles } from '@material-ui/core';
import { formatPxToRem } from 'common/utils';

const useStyles = makeStyles((theme) => ({
    divider: {
        height: formatPxToRem(70),
        width: '100%',
    },
    footerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        width: '1300px',
        background: '#F7F7F7',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    selectionContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    bold: {
        fontWeight: 700,
    },
}));

export { useStyles };
