import { AxiosRequestConfig } from 'axios';
import { doRequest, managerApiInstance } from 'clients/api';
import { GenericBody, GenericResponse } from 'clients/interfaces/paginated.interface';
import {
    ListContractsWithItemsBody,
    ListContractsWithItemsResponse,
} from '../interfaces/marketplace.interface';

const BASE_URL = '/contract';

const contractsRequests = {
    listContractsWithItems: async (
        body?: GenericBody<ListContractsWithItemsBody>,
        config?: AxiosRequestConfig<any>
    ) =>
        doRequest<GenericResponse<ListContractsWithItemsResponse>>(
            managerApiInstance.post(`${BASE_URL}/listContractsWithItems`, body, config)
        ),
};

export { contractsRequests };
