import { Button as DefaultButton, ButtonProps as DefaultButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';
import { useStyles } from './styles';

interface ButtonProps extends Omit<DefaultButtonProps, 'color' | 'size'> {
    color?: 'primary' | 'secondary' | 'default' | 'tertiary' | 'link' | 'basicLink' | 'warning';
    size?: 'xsmall' | 'small' | 'medium' | 'large';
}

const customColors = ['tertiary', 'link', 'basicLink', 'warning'];

const getColors = (color: any) => {
    if (customColors.some((c) => c === color)) return undefined;

    return color;
};

const Button: FC<ButtonProps> = ({
    variant,
    className,
    children,
    color = 'primary',
    size = 'medium',
    ...rest
}) => {
    const classes = useStyles();

    return (
        <DefaultButton
            {...rest}
            className={clsx(className, {
                [classes[`${variant ?? 'text'}Tertiary`]]: color === 'tertiary',
                [classes[`${variant ?? 'text'}Link`]]: color === 'link',
                [classes[`${variant ?? 'text'}BasicLink`]]: color === 'basicLink',
                [classes[`${variant ?? 'text'}Warning`]]: color === 'warning',
                [classes[`${size ?? 'medium'}Size`]]: true,
            })}
            variant={variant}
            color={getColors(color)}
            TouchRippleProps={{
                classes: { child: classes[`${color}Ripple`] ?? undefined },
            }}
        >
            {children}
        </DefaultButton>
    );
};

export type { ButtonProps };
export { Button };
