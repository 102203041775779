import { doRequest, managerApiInstance } from 'clients/api';
import { AxiosRequestConfig } from 'axios';
import { OkResponse } from '../interfaces/ok.interface';
import { PlainGenericResponse } from '../interfaces/paginated.interface';
import {
    OrganizationCheckExist,
    OrganizationCheckExistResponse,
    OrganizationData,
    OrganizationResponse,
    OrganizationUnit,
} from './interfaces/organization.interface';

const organizationRequests = {
    createOrganization: async (data: OrganizationData) =>
        doRequest<OrganizationResponse>(
            managerApiInstance.post(`/organizations/createOrganization`, data)
        ),
    getOrganization: async (organizationId?: number, config?: AxiosRequestConfig<any>) =>
        doRequest<OrganizationData>(
            managerApiInstance.post(`/organizations/getOrganization`, { organizationId }, config)
        ),
    updateOrganization: async (organizationData: OrganizationData) =>
        doRequest<OrganizationData>(
            managerApiInstance.post(`/organizations/updateOrganization`, organizationData)
        ),
    doCheckOrganizationExist: async (cpf: OrganizationCheckExist) =>
        doRequest<OrganizationCheckExistResponse>(
            managerApiInstance.post(`/organizations/doCheckOrganizationExists`, cpf)
        ),
};

const organizationUnitsRequests = {
    getOrganizationUnit: async (organizationUnitId: number) =>
        doRequest<OrganizationUnit>(managerApiInstance.get(`${organizationUnitId}`)),
    getOrganizationUnits: async () => doRequest<OrganizationUnit[]>(managerApiInstance.get(``)),
    createOrganizationUnit: async (organizationUnit: Omit<OrganizationUnit, 'id'>) =>
        doRequest<OrganizationUnit>(
            managerApiInstance.post(`/organization-units/createOrganizationUnit`, organizationUnit)
        ),
    updateOrganizationUnit: async (organizationUnit: OrganizationUnit) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/organization-units/updateOrganizationUnit`, organizationUnit)
        ),
    listOrganizationUnit: async (organizationId?: number, config?: AxiosRequestConfig<any>) =>
        doRequest<OrganizationUnit[]>(
            managerApiInstance.post(
                `/organization-units/listOrganizationUnit`,
                organizationId,
                config
            )
        ),
};

export { organizationRequests, organizationUnitsRequests };
