import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    creditCardImg: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default useStyles;
