import { doRequest, managerApiInstance } from 'clients/api';
import { GenericBody, GenericResponse } from 'clients/interfaces/paginated.interface';
import { DocumentSignerUpdate, Signer } from './interfaces/document-signers.interface';

const BASE_URL = '/documentSigner';

const documentSignerRequests = {
    updateContractSigners: async (
        body: GenericBody<DocumentSignerUpdate>,
        documentSignerId: string
    ) => {
        return doRequest<GenericResponse<Signer[]>>(
            managerApiInstance.patch(`${BASE_URL}/${documentSignerId}`, body.params)
        );
    },
};

export { documentSignerRequests };
