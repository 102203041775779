import React from 'react';
import { Link, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OrganizationData } from 'clients/manager/interfaces/organization.interface';
import { formatCpfOrCnpj } from 'common/utils';
import { FormikProps } from 'formik';
import { TbArrowBackUp } from 'react-icons/tb';
import { PaymentStrategy } from 'common/enums';
import useStyles from './confirmation-style';
import { CheckoutFormValues, CheckoutStage } from '../../../checkout-page';

interface ConfirmationViewProps {
    organization?: OrganizationData;
    form?: FormikProps<CheckoutFormValues>;
}

const ConfirmationView: React.FC<ConfirmationViewProps> = ({ organization, form }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const changeStringToAsteriskBetween = (string, startPosition, endPosition) => {
        const regex = new RegExp(`^.{${startPosition},${endPosition}}`);
        return string.replace(regex, (character) => '*'.repeat(character.length));
    };

    const Info = ({ title, value }: { title: string; value: string }) => (
        <Typography>
            <strong>{title}</strong>
            {value}
        </Typography>
    );

    const Title = ({ value }: { value: string }) => (
        <div className={classes.boxGrey}>
            <Typography variant='h5'>
                <strong>{value}</strong>
            </Typography>
        </div>
    );

    return (
        <Paper variant='outlined' className={classes.boxBorder}>
            <div className={classes.container}>
                <Link
                    onClick={() => form?.setFieldValue('stage', CheckoutStage.PAYMENT)}
                    className={classes.backToPayment}
                >
                    <TbArrowBackUp size={18} className={classes.backIcon} />
                    {t('marketplace.checkout.back-to-payment')}
                </Link>
                <div className={classes.title}>
                    <Typography variant='h5'>
                        <strong>{t('marketplace.checkout.confirmation')}</strong>
                    </Typography>
                </div>
                <Title value={t('marketplace.checkout.organization-data')} />
                <div className={classes.planText}>
                    <Info
                        title={t('bidding.checkout.confirmation.company_name')}
                        value={organization?.organizationName ?? '--'}
                    />
                    <Info
                        title={t('bidding.checkout.confirmation.cnpj')}
                        value={formatCpfOrCnpj(organization?.docNumber ?? '')}
                    />
                    <Info
                        title={t('bidding.checkout.confirmation.address')}
                        value={`${organization?.zipCode} - ${organization?.state}, ${organization?.district}, nº ${organization?.numberPlace} - ${organization?.complementPlace}`}
                    />
                </div>
                {form?.values?.type === PaymentStrategy.asaas_credit_card && (
                    <>
                        <Title value={t('marketplace.checkout.payment-data')} />
                        <div className={classes.planText}>
                            <Info
                                title={`${t('term.name')}: `}
                                value={form?.values.holderName ?? '--'}
                            />
                            <Info
                                title={`${t('term.email')}: `}
                                value={form?.values?.email ?? '--'}
                            />
                            <Info
                                title={`${t('term.cpf-cnpj')}: `}
                                value={formatCpfOrCnpj(form?.values?.cpfCnpj ?? '')}
                            />
                            <Info
                                title={`${t('term.phone')}: `}
                                value={form?.values?.phone ?? '--'}
                            />
                            <Info title='CEP: ' value={form?.values?.postalCode ?? '--'} />
                            <Info
                                title={`${t('term.number')}: `}
                                value={`nº ${form?.values?.addressNumber}`}
                            />
                            {!!form.values.addressComplement && (
                                <Info
                                    title={`${t('term.complement')}: `}
                                    value={form?.values?.addressComplement ?? '--'}
                                />
                            )}
                        </div>
                    </>
                )}
                <Title value={t('marketplace.checkout.payment-method')} />
                {form?.values?.type === PaymentStrategy.asaas_boleto && (
                    <div className={classes.planText}>
                        <Info
                            title={t('marketplace.checkout.payment-type')}
                            value={t('term.boleto')}
                        />
                        <Info
                            title={t('marketplace.checkout.due-date')}
                            value='3 dias após a confirmação'
                        />
                        <Info title={`${t('term.institute')}: `} value='Asaas' />
                        <Typography>
                            {t('marketplace.checkout.invoice-short-description')}
                        </Typography>
                    </div>
                )}
                {form?.values?.type === PaymentStrategy.asaas_credit_card && (
                    <div className={classes.planText}>
                        <Info
                            title={t('marketplace.checkout.payment-type')}
                            value={t('term.credit-card')}
                        />
                        <Info
                            title={`${t('bidding.checkout.credit_card.name')}: `}
                            value={form?.values.name ?? '--'}
                        />
                        <Info
                            title={t('bidding.checkout.confirmation.credit_card.number')}
                            value={changeStringToAsteriskBetween(form?.values?.number, 1, 14)}
                        />
                        <Info
                            title={t('bidding.checkout.confirmation.credit_card.due_date')}
                            value={changeStringToAsteriskBetween(form?.values?.expiry, 1, 2)}
                        />
                        <Info
                            title={t('bidding.checkout.confirmation.credit_card.cvv')}
                            value={changeStringToAsteriskBetween(form?.values?.cvc, 1, 3)}
                        />

                        <Typography>
                            {t('marketplace.checkout.credit-card-short-description')}
                        </Typography>
                    </div>
                )}
            </div>
        </Paper>
    );
};

export default ConfirmationView;
