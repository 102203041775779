import { FC } from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Modal, ModalPosition } from 'common/components';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { Alert } from '@material-ui/lab';
import { t } from 'i18next';

interface ModalPublicationErrorsProps {
    warnings: { [key: string]: boolean };
    onClose: () => void;
}

const ModalPublicationErrors: FC<ModalPublicationErrorsProps> = ({ warnings, onClose }) => {
    const iconProps = { style: { fontSize: 22 } };

    const documentGeneratedMandatory = () => {
        let message = '';
        if (warnings.requiredDocumentAuctionType) {
            message = t('warning.requiredDocumentAuctionType').toString();
        }
        if (warnings.requiredHiringNoticeDocument) {
            message = t('warning.requiredHiringNoticeDocument').toString();
        }
        if (warnings.requiredActAuthorizingDirectContractingDocument) {
            message = t('warning.requiredActAuthorizingDirectContractingDocument').toString();
        }
        if (message) {
            return (
                <ListItem>
                    <ListItemIcon>
                        <IoDocumentTextOutline {...iconProps} />
                    </ListItemIcon>
                    <ListItemText primary={message} secondary={null} />
                </ListItem>
            );
        }
        return <></>;
    };

    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={onClose}
            header={<span>Erro ao publicar processo</span>}
        >
            <Box minWidth='400px' maxWidth='500px'>
                <Box width={1}>
                    <Alert severity='error'>
                        Corrija os itens abaixo para continuar com publicação
                    </Alert>
                    <Box width={1} mt={1}>
                        <List dense>
                            {warnings.requiredProcessFilesSize && (
                                <ListItem>
                                    <ListItemIcon>
                                        <IoDocumentTextOutline {...iconProps} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary='Anexar 2 arquivos complementares na aba de documentos'
                                        secondary={null}
                                    />
                                </ListItem>
                            )}
                            {documentGeneratedMandatory()}
                            {warnings.invalidLotsWithMoreThaOneItem && (
                                <ListItem>
                                    <ListItemIcon>
                                        <IoDocumentTextOutline {...iconProps} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary='É permitido apenas um item por lote neste tipo de processo.'
                                        secondary={null}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalPublicationErrors;
