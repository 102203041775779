import React from 'react';

import { Skeleton } from '@material-ui/lab';
import { Contract } from 'clients/manager/interfaces/contract.interface';
import CheckoutSummaryCardView from './checkout-summary-card-view';

interface CheckoutSummaryCardProps {
    items: Contract[];
    title: string;
    orderId?: number;
    confirmLabel?: string;
    disabled?: boolean;
    loading?: boolean;
    onConfirm?: () => any;
}

const CheckoutSummaryCard: React.FC<CheckoutSummaryCardProps> = ({
    title,
    confirmLabel,
    items,
    orderId,
    onConfirm,
    loading = false,
    disabled = false,
}) => {
    const totalValue = items?.reduce((acc, contract) => acc + contract.accumulatedValue, 0) ?? 0;

    return (
        <div>
            {loading ? (
                <>
                    <Skeleton height={300} width={300} style={{ marginTop: '-70px' }} />
                </>
            ) : (
                <CheckoutSummaryCardView
                    title={title}
                    totalValue={totalValue}
                    confirmLabel={confirmLabel}
                    onConfirm={onConfirm}
                    loading={loading}
                    disabled={disabled}
                    contractsQuantity={items?.length}
                    orderNumber={orderId}
                />
            )}
        </div>
    );
};

export default CheckoutSummaryCard;
