/* eslint-disable no-unneeded-ternary */
import { addressRequest } from 'clients/manager/address.requests';
import { CircularProgress, Grid, MenuItem, Typography } from '@material-ui/core';
import { Button, MaskedTextField, TextField } from 'common/components';
import { FormikErrors, useFormik } from 'formik';
import { FocusEvent, useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DataViaCep } from '../../../../../common/interfaces/via-cep-data';
import { SignUpOrganizationContext } from '../context/SignUpOrganizationContext';
import { UFvalues } from '../ufvalues';

const Address = () => {
    const { organization, setOrganization, handleNext } = useContext(SignUpOrganizationContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [dataViaCep, setDataViaCep] = useState<DataViaCep>();
    const [errorSearchCEP, setErrorSearchCEP] = useState(false);
    const [errorLenghtCEP, setErrorLenghtCEP] = useState(false);

    const initialValues = {
        zipCode: '',
        publicPlace: '',
        numberPlace: '',
        complementPlace: '',
        district: '',
        state: '',
        cityName: '',
    };

    const { t } = useTranslation();

    const validationSchema = yup.object({
        zipCode: yup
            .string()
            .required(t('please-fill-in-the-cep', { ns: 'validation' }))
            .max(9, t('cep-min-number-character', { ns: 'validation' })),
        publicPlace: yup.string().required(t('address', { ns: 'validation' })),
        numberPlace: yup.string().required(t('number', { ns: 'validation' })),
        district: yup.string().required(t('district', { ns: 'validation' })),
        state: yup.string().required(t('state', { ns: 'validation' })),
        cityName: yup.string().required(t('city', { ns: 'validation' })),
    });

    const onSubmit = (values: any) => {
        ReactDOM.unstable_batchedUpdates(() => {
            setOrganization((prevOrganization: any) => ({
                ...prevOrganization,
                ...values,
                zipCode: values.zipCode.replaceAll('-', ''),
            }));
            handleNext();
        });
    };

    const formStep2 = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const onBlurCep = (
        e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
        setFieldValue: {
            (field: string, value: any, shouldValidate?: boolean | undefined):
                | Promise<void>
                | Promise<
                      FormikErrors<{
                          docNumber: string;
                          companyName: string;
                          tradingName: string;
                          zipCode: string;
                          publicPlace: string;
                          numberPlace: string;
                          complementPlace: string;
                          district: string;
                          state: string;
                          cityName: string;
                          //   legal_responsible_name: {};
                          ibgecode: string;
                      }>
                  >;
            (arg0: string, arg1: any): void;
        }
    ) => {
        const cepValue = e.target.value;
        const cep = cepValue?.replace(/[^0-9]/g, '');
        if (cep?.length > 8) {
            setErrorLenghtCEP(true);
            setDataViaCep(undefined);
            setTimeout(() => {
                setErrorLenghtCEP(false);
            }, 3000);
        }
        if (cep?.length !== 8) {
            return;
        }

        setLoading(true);

        const request = async () => {
            const data = await addressRequest.getAddress(cep);
            setDataViaCep(data);

            if (!data.localidade) {
                setDataViaCep(undefined);
                setErrorSearchCEP(true);
            } else {
                setErrorSearchCEP(false);
            }

            ReactDOM.unstable_batchedUpdates(() => {
                setFieldValue('publicPlace', data.logradouro);
                setFieldValue('district', data.bairro);
                setFieldValue('cityName', data.localidade);
                setFieldValue('state', data.uf);
                setLoading(false);
                setOrganization({
                    ...organization,
                    ibgeCode: data.ibge,
                    cityName: data.localidade,
                    state: data.uf,
                    zipCode: data.cep.replaceAll('-', ''),
                });
            });
        };

        request();
    };

    return (
        <Grid container>
            <form onSubmit={formStep2.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item md={2} sm={12} xs={12}>
                        <MaskedTextField
                            mask='99999-999'
                            fullWidth
                            label='CEP'
                            name='zipCode'
                            value={formStep2.values.zipCode}
                            onChange={formStep2.handleChange}
                            error={formStep2.touched.zipCode && Boolean(formStep2.errors.zipCode)}
                            helperText={formStep2.touched.zipCode && formStep2.errors.zipCode}
                            onBlur={(e) => onBlurCep(e, formStep2.setFieldValue)}
                            InputProps={{
                                endAdornment: loading && (
                                    <CircularProgress color='secondary' size={20} />
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={8} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            disabled={Boolean(dataViaCep?.logradouro && true)}
                            label={t('term.address')}
                            name='publicPlace'
                            value={formStep2.values.publicPlace}
                            onChange={formStep2.handleChange}
                            error={
                                formStep2.touched.publicPlace &&
                                Boolean(formStep2.errors.publicPlace)
                            }
                            helperText={
                                formStep2.touched.publicPlace && formStep2.errors.publicPlace
                            }
                        />
                    </Grid>
                    <Grid item md={2} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.number')}
                            name='numberPlace'
                            value={formStep2.values.numberPlace}
                            onChange={formStep2.handleChange}
                            error={
                                formStep2.touched.numberPlace &&
                                Boolean(formStep2.errors.numberPlace)
                            }
                            helperText={
                                formStep2.touched.numberPlace && formStep2.errors.numberPlace
                            }
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.complement')}
                            name='complementPlace'
                            value={formStep2.values.complementPlace}
                            onChange={formStep2.handleChange}
                            error={
                                formStep2.touched.complementPlace &&
                                Boolean(formStep2.errors.complementPlace)
                            }
                            helperText={
                                formStep2.touched.complementPlace &&
                                formStep2.errors.complementPlace
                            }
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.district')}
                            name='district'
                            disabled={Boolean(dataViaCep?.bairro && true)}
                            value={formStep2.values.district}
                            onChange={formStep2.handleChange}
                            error={formStep2.touched.district && Boolean(formStep2.errors.district)}
                            helperText={formStep2.touched.district && formStep2.errors.district}
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextField
                            select
                            fullWidth
                            disabled={Boolean(dataViaCep?.uf && true)}
                            name='state'
                            label={t('term.select-state')}
                            onChange={formStep2.handleChange}
                            error={formStep2.touched.state && Boolean(formStep2.errors.state)}
                            helperText={formStep2.touched.state && formStep2.errors.state}
                            value={formStep2.values.state}
                        >
                            {UFvalues.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            disabled={Boolean(dataViaCep?.localidade && true)}
                            label={t('term.city')}
                            name='cityName'
                            value={formStep2.values.cityName}
                            onChange={formStep2.handleChange}
                            error={formStep2.touched.cityName && Boolean(formStep2.errors.cityName)}
                            helperText={formStep2.touched.cityName && formStep2.errors.cityName}
                        />
                    </Grid>
                    {errorSearchCEP && (
                        <Typography color='error'>
                            {t('sign-up.pages.stepper-organization.address.info-error-cep')}
                        </Typography>
                    )}
                    {errorLenghtCEP && (
                        <Typography color='error'>
                            {t(
                                'sign-up.pages.stepper-organization.address.info-error-cep-min-char'
                            )}
                        </Typography>
                    )}
                </Grid>
                <div className='stepper-nav'>
                    <Button
                        disabled={errorSearchCEP}
                        size='small'
                        variant='contained'
                        color='primary'
                        type='submit'
                    >
                        {t('term.save-and-forward')}
                    </Button>
                </div>
            </form>
        </Grid>
    );
};

export default Address;
