import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            marginTop: '15px',
        },
        table: {
            minWidth: 450,
        },
        tableCellTitle: {
            backgroundColor: '#eaeaea',
            fontWeight: 'bold',
        },
        tableCellHeaderStyles: {
            backgroundColor: '#eaeaea',
            fontWeight: 'bold',
        },
        viewFileLinkSimple: {
            textDecoration: 'none',
        },
        viewFileText: {
            width: 80,
            color: theme.palette.primary.dark,
            fontSize: 14,
        },
        typoAttachment: {
            display: 'flex',
            gap: 4,
            color: theme.palette.primary.dark,
        },
        palettePrimaryDark: {
            color: theme.palette.primary.dark,
        },
        updatedFile: {
            width: 100,
            color: theme.palette.grey[600],
            fontSize: 12,
            marginTop: '15px',
        },
    })
);
