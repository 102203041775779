import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { formatPxToRem } from 'common/utils';

interface StyleProps {
    open: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    accordionContainer: {
        marginTop: 0,
        marginBottom: `${theme.spacing(5)} !important`,
        borderRadius: theme.spacing(1),
        border: ({ open }) =>
            open ? `1px solid ${theme.palette.primary.main}` : '1px solid #CCCCCC',
    },
    accordionSummary: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '64px',
        width: '64px',
        borderRadius: 900,
        marginRight: theme.spacing(5),
        background: ({ open }) => (open ? theme.palette.primary.main : '#EAEAEA'),
        color: ({ open }) => (open ? '#FFFFFF' : '#666666'),
    },
    title: {
        fontSize: formatPxToRem(20),
        fontWeight: 500,
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(6),
        borderTop: '1px solid #CCCCCC',
    },
}));

export default useStyles;
