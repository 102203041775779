import { doRequest, managerApiInstance, managerApiUploadFileInstance } from 'clients/api';
import { AxiosRequestConfig } from 'axios';
import { getNewFileName } from 'common/utils/getters/get-new-file-name.utils';
import { GenericResponse, PlainGenericResponse } from '../../interfaces/paginated.interface';
import {
    DocumentTemplate,
    ListTemplatesFilters,
    ReplaceVariables,
    TemplateVariablesReplaced,
    UpdateDocumentTemplateBody,
} from '../interfaces/document-templates.interface';

const path = '/documents/templates';

const templateRequests = {
    getTemplate: (documentTemplateId: string) =>
        doRequest<PlainGenericResponse<DocumentTemplate>>(
            managerApiInstance.post(`${path}/getTemplate`, { documentTemplateId })
        ),
    createTemplate: async (documentTemplate: DocumentTemplate | any) =>
        doRequest<PlainGenericResponse<DocumentTemplate>>(
            managerApiInstance.post(`${path}/createTemplate`, documentTemplate)
        ),
    updateTemplate: async (body: UpdateDocumentTemplateBody) =>
        doRequest<PlainGenericResponse<DocumentTemplate>>(
            managerApiInstance.post(`${path}/updateTemplate`, body)
        ),
    getTemplates: async (filters?: ListTemplatesFilters, config?: AxiosRequestConfig<any>) => {
        return doRequest<GenericResponse<DocumentTemplate>>(
            managerApiInstance.post(`${path}/listTemplates`, filters, config)
        );
    },
    uploadTemplateImage: async (documentTemplateId: string, file: File) => {
        const formData = new FormData();
        const newFileName = getNewFileName(file);
        formData.append('file', file, newFileName);

        return doRequest<PlainGenericResponse<{ url: string }>>(
            managerApiUploadFileInstance.post(`${path}/uploadFileOnTemplate`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    documentTemplateId,
                },
            })
        );
    },
    getReplacedVariables: async (data: ReplaceVariables) =>
        doRequest<PlainGenericResponse<TemplateVariablesReplaced>>(
            managerApiInstance.post('/documents/variables-replacer/getVariableValues', data)
        ),
};

export { templateRequests };
