import React from 'react';

import { PaymentStrategy } from 'common/enums';
import { useHistory } from 'react-router';
import SuccessView from './success-view';

interface SuccessProps {
    type: PaymentStrategy;
    orderId?: number;
}

const Success: React.FC<SuccessProps> = ({ type, orderId }) => {
    const history = useHistory();

    const handleBackToOrder = () => {
        history.replace(`/mercado/pedidos/empenho/${orderId}`);
    };

    return <SuccessView type={type} onClick={handleBackToOrder} />;
};

export default Success;
