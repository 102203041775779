import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { stringToColor } from 'common/utils';
import styled from 'styled-components';

const useStylesDocumentSigners = makeStyles(() =>
    createStyles({
        root: {
            '& .MuiAvatar-root': {
                fontSize: '1rem',
                width: '32px',
                height: '32px',
            },
            '& .MuiAvatar-img': {
                borderRadius: '50%',
            },
        },
    })
);

const useStylesAvatar = makeStyles<Theme, { backgroundColor: string; borderColor: string }>(() =>
    createStyles({
        avatar: {
            backgroundColor: (props) => stringToColor(props.backgroundColor),
            cursor: 'pointer',
            border: (props) => `2px solid ${props.borderColor}`,
        },
    })
);

export const BoxModalSignersList = styled.div`
    min-width: 400px;
    max-width: 400px;
`;

const useStylesModalSignersList = makeStyles(() =>
    createStyles({
        root: {
            padding: 0,
            margin: 0,
            '& .MuiListItemIcon-root': {
                minWidth: 30,
            },
        },
        icon: {
            width: 18,
            height: 18,
        },
        icon2: {
            width: 22,
            height: 22,
        },
    })
);

export { useStylesDocumentSigners, useStylesAvatar, useStylesModalSignersList };
