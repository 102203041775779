import React from 'react';

import { FormikProps } from 'formik';
import PaymentView from './payment-view';
import { CheckoutFormValues } from '../../../checkout-page';

interface PaymentProps {
    form: FormikProps<CheckoutFormValues>;
    loading?: boolean;
}

const Payment: React.FC<PaymentProps> = ({ form, loading = false }) => {
    return <PaymentView form={form} loading={loading} />;
};

export default Payment;
