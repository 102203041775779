import { Box, Typography } from '@material-ui/core';
import React, { memo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import documentTypeProviderRequests from 'clients/manager/document-type-provider.request';
import { useParams } from 'react-router';
import {
    DocumentType,
    DocumentsAndCategoryFormated,
    SegmentationTypeEnum,
} from '../../../../interfaces/document-type-provider.interface';
import { DocumentTypeProviderSegment } from '../../interfaces';
import DocumentsGroupProps from '../documents-group';

type GroupDocumentTypeProvider = {
    [key: number]: DocumentTypeProviderSegment;
};

interface AdditionalDocumentsProps {
    disabled?: boolean;
}

const AdditionalDocuments: React.FC<AdditionalDocumentsProps> = ({ disabled = false }) => {
    const [allAndNewsDocuments, setAllAndNewsDocuments] = useState<GroupDocumentTypeProvider>({});
    const [othersDocuments, setOthersDocuments] = useState<GroupDocumentTypeProvider>({});
    const { t } = useTranslation();
    const { idProvider } = useParams<{ idProvider: string }>();
    const [doNewRequest, setDoNewRequest] = useState<boolean>(false);

    const request = async () => {
        try {
            const { data } = await documentTypeProviderRequests.listDocumentTypesProvider(
                DocumentType.additional,
                Number(idProvider)
            );

            const { documents = [], segments = [] } = data;

            const allAndNewsDocuments = documents.filter(
                (docs) => docs.segmentationType === SegmentationTypeEnum.allAndNews
            );
            const othersDocuments = documents.filter(
                (docs) => docs.segmentationType !== SegmentationTypeEnum.allAndNews
            );

            ReactDOM.unstable_batchedUpdates(() => {
                setAllAndNewsDocuments(
                    ((allAndNewsDocuments as DocumentsAndCategoryFormated[]) || []).reduce(
                        (obj, doc) => {
                            if (!obj[doc.categoryDocument.id]) {
                                obj[doc.categoryDocument.id] = [];
                            }

                            obj[doc.categoryDocument.id].push({
                                ...doc,
                                segments: segments.filter(
                                    (segment) => segment.documentTypeId === doc.id
                                ),
                            });
                            return obj;
                        },
                        {}
                    )
                );
                setOthersDocuments(
                    ((othersDocuments as DocumentsAndCategoryFormated[]) || []).reduce(
                        (obj, doc) => {
                            if (!obj[doc.categoryDocument.id]) {
                                obj[doc.categoryDocument.id] = [];
                            }

                            obj[doc.categoryDocument.id].push({
                                ...doc,
                                segments: segments.filter(
                                    (segment) => segment.documentTypeId === doc.id
                                ),
                            });
                            return obj;
                        },
                        {}
                    )
                );
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        request();
    }, []);

    useEffect(() => {
        if (doNewRequest) {
            request();
            setDoNewRequest(false);
        }
    }, [doNewRequest]);

    return (
        <Box my={4}>
            <Typography variant='h5' gutterBottom>
                {t('info.complementary-register')}
            </Typography>
            {allAndNewsDocuments && (
                <Typography variant='h6' gutterBottom>
                    1. {t('info.complementary-register.general')}
                </Typography>
            )}
            {Object.keys(allAndNewsDocuments || {}).map((categoryId) => {
                const { categoryDocument } = allAndNewsDocuments[categoryId][0];

                return (
                    <DocumentsGroupProps
                        categoryName={categoryDocument.name}
                        categoryDescription={categoryDocument.description}
                        documents={allAndNewsDocuments[categoryId] || []}
                        disabled={disabled}
                        setDoNewRequest={setDoNewRequest}
                    />
                );
            })}
            <Box mt={4} />
            {othersDocuments && (
                <Typography variant='h6' gutterBottom>
                    {allAndNewsDocuments ? '2. ' : '1. '}
                    {t('info.complementary-register.specific-per-segment')}
                </Typography>
            )}
            {Object.keys(othersDocuments || {}).map((categoryId) => {
                const { categoryDocument } = othersDocuments[categoryId][0];

                return (
                    <DocumentsGroupProps
                        categoryName={categoryDocument.name}
                        categoryDescription={categoryDocument.description}
                        documents={othersDocuments[categoryId] || []}
                        disabled={disabled}
                    />
                );
            })}
        </Box>
    );
};

export default memo(AdditionalDocuments);
