import React from 'react';
import { Box, Checkbox, Grid, Paper, Typography } from '@material-ui/core';
import { formatCurrency, getProcessCategoryId, getSignatureMethod } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { ListContractsByOrderIdResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import RatingIndicator from 'modules/marketplace/components/rating-indicator';
import SearchContractsActions from 'modules/contracts/pages/search-contracts/components/search-contracts-actions/search-contracts-actions';
import { Button } from 'common/components';
import { useStyles } from './marketplace-contract-card-style';
import ContractItemsTable from './components/contract-items-table';
import ContractStatusChip from './components/contract-status-chip';

interface MarketplaceContractCardViewProps {
    isSelected?: boolean;
    onSelectContract?: () => void;
    contractWithDetails?: ListContractsByOrderIdResponse;
    loading?: boolean;
}

const MarketplaceContractCardView: React.FC<MarketplaceContractCardViewProps> = ({
    contractWithDetails,
    onSelectContract,
    isSelected = false,
    loading = false,
}) => {
    const hasInvoice = !!contractWithDetails?.contract.invoiceId;

    const { t } = useTranslation();
    const styles = useStyles({
        isFinished: hasInvoice,
    });

    return (
        <Paper
            variant='outlined'
            key={contractWithDetails?.contract.id}
            className={styles.cardContainer}
        >
            <Grid container spacing={2} direction='column' className={styles.cardContentContainer}>
                <Grid item>
                    <Box className={styles.processInfoContainer}>
                        <Checkbox
                            checked={isSelected || hasInvoice}
                            onChange={onSelectContract}
                            disabled={hasInvoice}
                        />
                        <div className={styles.actionButtonsContainer}>
                            {hasInvoice && contractWithDetails?.contract?.invoice?.invoiceUrl && (
                                <Button
                                    color='secondary'
                                    variant='outlined'
                                    size='small'
                                    onClick={() =>
                                        window.open(
                                            contractWithDetails?.contract?.invoice?.invoiceUrl,
                                            '_blank'
                                        )
                                    }
                                >
                                    {t('marketplace.checkout.see-invoice')}
                                </Button>
                            )}
                            {contractWithDetails && (
                                <SearchContractsActions
                                    contractWithDetails={contractWithDetails}
                                    actionButton={(props) => (
                                        <Button
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                            {...props}
                                        >
                                            {t('info.view-edit-contract')}
                                        </Button>
                                    )}
                                />
                            )}
                        </div>
                    </Box>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={8}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography
                                        variant='caption'
                                        className={styles.titlePriceLabel}
                                    >
                                        {t('term.supplier')}:
                                    </Typography>
                                    <Typography className={styles.title} variant='body1'>
                                        {contractWithDetails?.provider?.companyName}
                                    </Typography>
                                    <Typography variant='caption'>
                                        {contractWithDetails?.contract.additionalInformation}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={styles.titlePriceLabel} variant='body2'>
                                {t('marketplace.contract.total-value')}
                            </Typography>
                            <Typography className={styles.titlePrice} variant='body1'>
                                {contractWithDetails?.contract?.accumulatedValue
                                    ? formatCurrency(contractWithDetails.contract.accumulatedValue)
                                    : '--'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} className={styles.listInfoContainer}>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item className={styles.listInfoItem}>
                                    <Typography className={styles.infoItemLabel} variant='body2'>
                                        {t('marketplace.checkout.payment-status')}
                                    </Typography>
                                    <Typography className={styles.infoItemValue} variant='body2'>
                                        <ContractStatusChip
                                            status={
                                                contractWithDetails?.contract?.invoice
                                                    ?.statusPayment as string
                                            }
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item className={styles.listInfoItem}>
                                    <Typography className={styles.infoItemLabel} variant='body2'>
                                        {t('marketplace.contract.contract-status')}
                                    </Typography>
                                    <Typography className={styles.infoItemValue} variant='body2'>
                                        <ContractStatusChip
                                            status={contractWithDetails?.contract?.status as string}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item className={styles.listInfoItem}>
                                    <Typography className={styles.infoItemLabel} variant='body2'>
                                        {t('marketplace.contract.contract-category')}
                                    </Typography>
                                    <Typography className={styles.infoItemValue} variant='body2'>
                                        {getProcessCategoryId(
                                            contractWithDetails?.contract.processCategoryId
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item className={styles.listInfoItem}>
                                    <Typography className={styles.infoItemLabel} variant='body2'>
                                        {t('marketplace.contract.signture-method')}
                                    </Typography>
                                    <Typography className={styles.infoItemValue} variant='body2'>
                                        {getSignatureMethod(
                                            contractWithDetails?.contract.signtureMethod
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={styles.infoItemLabel} variant='body2'>
                                        {t('marketplace.contract.process-ID')}
                                    </Typography>
                                    <Typography className={styles.infoItemValue} variant='body2'>
                                        {contractWithDetails?.contract.auctionNoticeId}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography className={styles.infoItemLabel} variant='body2'>
                                {t('marketplace.contract.contract-assessment')}
                            </Typography>
                            <RatingIndicator
                                actual={
                                    contractWithDetails?.contract?.assessment?.generalRating ?? 0
                                }
                                max={5}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ContractItemsTable
                contractItems={contractWithDetails?.contract?.items}
                loading={loading}
            />
        </Paper>
    );
};

export default MarketplaceContractCardView;
