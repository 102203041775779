import React from 'react';
import { ListContractsByOrderIdResponse } from 'modules/marketplace/interfaces/marketplace.interface';
import { Skeleton } from '@material-ui/lab';
import { useStyles } from './marketplace-contract-card-style';
import MarketplaceContractCardView from './marketplace-contract-card-view';

interface MarketplaceContractCardProps {
    contract?: ListContractsByOrderIdResponse;
    loading?: boolean;
    isSelected?: boolean;
    onSelectContract?: (id: string) => void;
}

const MarketplaceContractCard: React.FC<MarketplaceContractCardProps> = ({
    contract,
    onSelectContract,
    isSelected = false,
    loading = false,
}) => {
    const styles = useStyles({});

    const handleSelectContract = () => {
        if (!contract?.contract.id) return;
        onSelectContract?.(contract?.contract.id);
    };

    if (loading) {
        return <Skeleton className={styles.skeleton} />;
    }

    return (
        <MarketplaceContractCardView
            contractWithDetails={contract}
            loading={loading}
            isSelected={isSelected}
            onSelectContract={handleSelectContract}
        />
    );
};

export default MarketplaceContractCard;
