import { alpha, makeStyles } from '@material-ui/core';
import { Theme } from 'common/theme';

export const useStyles = makeStyles((theme: Theme | any) => ({
    '& span': {
        fontFamily: 'Roboto',
    },
    // variants
    textTertiary: {
        color: theme.palette.tertiary.main,
    },
    textLink: {
        color: theme.palette.blue[500],
    },
    textBasicLink: {
        padding: '0px !important',
        color: theme.palette.blue[500],
        '&:hover': {
            background: 'transparent',
        },
    },
    containedTertiary: {
        color: theme.palette.tertiary.contrastText,
        backgroundColor: theme.palette.tertiary.main,
        '&:hover': {
            backgroundColor: theme.palette.tertiary.dark,
            '@media (hover: none)': {
                backgroundColor: theme.palette.tertiary.main,
            },
        },
    },
    containedWarning: {
        color: theme.palette.warning.contrastText,
        backgroundColor: theme.palette.warning.main,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark,
            '@media (hover: none)': {
                backgroundColor: theme.palette.warning.main,
            },
        },
    },
    containedLink: {
        color: theme.palette.text.secondary,
        backgroundColor: '#317EC5',
        '&:hover': {
            backgroundColor: theme.palette.tertiary.main,
            '@media (hover: none)': {
                backgroundColor: theme.palette.tertiary.main,
            },
        },
        '&:active': {
            backgroundColor: '#B9D2EA',
        },
    },
    containedBasicLink: {
        color: theme.palette.text.secondary,
        backgroundColor: 'transparent',
    },
    outlinedTertiary: {
        color: theme.palette.tertiary.main,
        border: `2px solid ${alpha(theme.palette.tertiary.main, 0.5)}`,
        '&:hover': {
            border: `2px solid ${theme.palette.tertiary.main}`,
            backgroundColor: alpha(theme.palette.tertiary.main, theme.palette.action.hoverOpacity),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    outlinedLink: {
        color: theme.palette.blue[500],
        border: `2px solid ${theme.palette.blue[500]}`,
        '&:hover': {
            border: `2px solid ${theme.palette.tertiary.main}`,
            color: theme.palette.tertiary.main,
            backgroundColor: alpha(theme.palette.tertiary.main, theme.palette.action.hoverOpacity),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    outlinedBasicLink: {
        color: theme.palette.blue[500],
        border: `2px solid ${theme.palette.blue[500]}`,
    },
    // sizes
    xsmallSize: {
        padding: '10px 20px',
        height: '30px',
        fontSize: '10px',
    },
    smallSize: {
        padding: '11px 22px',
        height: '40px',
        fontSize: '12px',
    },
    mediumSize: {
        padding: '16px 26px',
        height: '50px',
        fontSize: '14px',
    },
    largeSize: {
        padding: '20px 36px',
        height: '60px',
        fontSize: '16px',
    },
    //ripple
    primaryRipple: {
        backgroundColor: '#626F97',
    },
    secondaryRipple: {
        backgroundColor: '#626F97',
    },
    tertiaryRipple: {
        backgroundColor: '#626F97',
    },
    defaultRipple: {
        backgroundColor: '#DDDDDD',
    },
    linkRipple: {
        backgroundColor: 'transparent',
    },
    basicLinkRipple: {
        backgroundColor: 'transparent',
    },
}));

export default useStyles;
