import React from 'react';
import { CircularProgress, MenuItem, TextField, Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import LoadingButton from 'common/components/loading-button';
import { useTranslation } from 'react-i18next';
import { OrganizationUnit } from 'clients/manager/interfaces/organization.interface';
import { User } from 'common/interfaces/user.interface';
import { AuctionLegalSupport } from 'clients/manager/interfaces/legall-support.interface';
import useStyles from './additional-information-style';
import { AdditionalInformationFormValues } from './additional-information-form';

interface AdditionalInformationFormViewProps {
    organizationUnits?: OrganizationUnit[];
    organizationsLoading?: boolean;
    organizationUsers?: User[];
    organizationUsersLoading?: boolean;
    legalSupportList?: AuctionLegalSupport[];
    legalSupportListLoading?: boolean;
    form: FormikProps<AdditionalInformationFormValues>;
    onClose?: () => void;
}

const AdditionalInformationFormView: React.FC<AdditionalInformationFormViewProps> = ({
    form,
    onClose,
    legalSupportList,
    organizationUnits,
    organizationUsers,
    legalSupportListLoading = false,
    organizationsLoading = false,
    organizationUsersLoading = false,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <Typography variant='h6' className={styles.marginBottom}>
                {t('marketplace.cart-form.order-data')}:
            </Typography>
            <TextField
                fullWidth
                variant='outlined'
                label={t('marketplace.cart-form.deadline-to-send-proposal')}
                name='deadlineToSendProposal'
                type='datetime-local'
                value={form.values.deadlineToSendProposal}
                error={form.touched.deadlineToSendProposal && !!form.errors.deadlineToSendProposal}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                InputLabelProps={{
                    shrink: true,
                }}
                className={styles.marginBottom}
            />
            <TextField
                required
                fullWidth
                select
                color='primary'
                variant='outlined'
                label={t('term.legal-support')}
                name='legalSupportId'
                value={form.values.legalSupportId}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.touched.legalSupportId && !!form.errors.legalSupportId}
                disabled={legalSupportListLoading}
                InputProps={{
                    endAdornment: legalSupportListLoading && (
                        <CircularProgress size={20} className={styles.spinner} />
                    ),
                }}
                className={styles.marginBottom}
            >
                {legalSupportList?.map((legalSuport) => (
                    <MenuItem key={legalSuport.id} value={legalSuport.id}>
                        {legalSuport.description}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                required
                fullWidth
                select
                color='primary'
                variant='outlined'
                label={t('marketplace.cart-form.requesting-unit')}
                name='organizationUnitId'
                value={form.values.organizationUnitId}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.touched.organizationUnitId && !!form.errors.organizationUnitId}
                disabled={organizationsLoading}
                InputProps={{
                    endAdornment: organizationsLoading && (
                        <CircularProgress size={20} className={styles.spinner} />
                    ),
                }}
            >
                {organizationUnits?.map((unit) => (
                    <MenuItem key={unit.id} value={unit.id}>
                        {unit.organizationUnitName}
                    </MenuItem>
                ))}
            </TextField>
            <div className={styles.twoGridColumns}>
                <TextField
                    required
                    fullWidth
                    select
                    color='primary'
                    variant='outlined'
                    label={t('marketplace.cart-form.requesting-user')}
                    name='competentAuthorityId'
                    value={form.values.competentAuthorityId}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={form.touched.competentAuthorityId && !!form.errors.competentAuthorityId}
                    disabled={organizationUsersLoading || !form.values.organizationUnitId}
                    InputProps={{
                        endAdornment: organizationUsersLoading && (
                            <CircularProgress size={20} className={styles.spinner} />
                        ),
                    }}
                >
                    {organizationUsers?.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                            {user.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    required
                    fullWidth
                    select
                    color='primary'
                    variant='outlined'
                    label={t('marketplace.cart-form.inspector')}
                    name='inspectorUserId'
                    value={form.values.inspectorUserId}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={form.touched.inspectorUserId && !!form.errors.inspectorUserId}
                    disabled={organizationUsersLoading || !form.values.organizationUnitId}
                    InputProps={{
                        endAdornment: organizationUsersLoading && (
                            <CircularProgress size={20} className={styles.spinner} />
                        ),
                    }}
                >
                    {organizationUsers?.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                            {user.name}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <TextField
                required
                fullWidth
                color='primary'
                variant='outlined'
                label={t('marketplace.cart-form.object')}
                name='object'
                value={form.values.object}
                multiline
                minRows={3}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.touched.object && !!form.errors.object}
                className={styles.marginBottom}
            />
            <TextField
                required
                fullWidth
                color='primary'
                variant='outlined'
                label={t('marketplace.cart-form.purchase-justification')}
                name='purchaseJustification'
                value={form.values.purchaseJustification}
                multiline
                minRows={3}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.touched.purchaseJustification && !!form.errors.purchaseJustification}
                className={styles.marginBottom}
            />
            <Typography variant='h6' className={styles.marginBottom}>
                {t('marketplace.cart-form.delivery-details')}:
            </Typography>
            <TextField
                required
                fullWidth
                color='primary'
                variant='outlined'
                label={t('marketplace.cart-form.delivery-address')}
                placeholder='ex.: Rua das Flores, 123, Bairro Jardim, São Paulo - SP, 01234-567'
                name='deliveryLocation'
                value={form.values.deliveryLocation}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.touched.deliveryLocation && !!form.errors.deliveryLocation}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <div className={styles.twoGridColumns}>
                <TextField
                    required
                    fullWidth
                    type='number'
                    color='primary'
                    variant='outlined'
                    label={t('marketplace.cart-form.delivery-time-in-days')}
                    placeholder='ex.: 60'
                    name='deliveryDeadlineInDays'
                    value={form.values.deliveryDeadlineInDays}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={
                        form.touched.deliveryDeadlineInDays && !!form.errors.deliveryDeadlineInDays
                    }
                    InputProps={{ inputProps: { min: 0 } }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    required
                    fullWidth
                    type='time'
                    color='primary'
                    variant='outlined'
                    label={t('marketplace.cart-form.item-delivery-time')}
                    name='itemDeliveryTime'
                    value={form.values.itemDeliveryTime}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    error={form.touched.itemDeliveryTime && !!form.errors.itemDeliveryTime}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            <div className={styles.flex}>
                <LoadingButton
                    variant='outlined'
                    disabled={form.isSubmitting || form.isValidating}
                    onClick={onClose}
                >
                    {t('term.cancel')}
                </LoadingButton>
                <LoadingButton
                    variant='contained'
                    onClick={() => form.handleSubmit()}
                    disabled={form.isSubmitting || form.isValidating}
                    loading={
                        form.isSubmitting || form.isValidating
                            ? { text: `${t('term.loading')}...` }
                            : undefined
                    }
                >
                    {t('term.marketplace-button-confirm-shopping')}
                </LoadingButton>
            </div>
        </div>
    );
};

export default AdditionalInformationFormView;
