import { useState } from 'react';

export const useModalActions = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleToogle = () => {
        setIsOpen((state) => !state);
    };

    return { open: handleOpen, close: handleClose, toogle: handleToogle, state: isOpen };
};
