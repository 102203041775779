import { memo, useEffect, useState } from 'react';
import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Typography,
    Chip,
} from '@material-ui/core';
import { AiOutlineEdit, AiOutlineDelete, AiOutlineDownload, AiOutlineCheck } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
    HiringPlanItensResponse,
    HiringPlanResponse,
} from 'clients/manager/interfaces/hiring-plans.interfaces';
import { hiringPlansRequests } from 'clients/manager/hiring-plans.requests';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import { usePageContext } from 'common/components/base/pages/context';
import { addNotificationError, formatCurrency, formatDate } from 'common/utils';
import BreadcrumbsComponent from '../../components/breadcrumbs';
import { ChartItemCountAndTotalAmountByPCA } from '../../components/charts';
import {
    Th,
    Box,
    Stack,
    PcaCard,
    BlankArea,
    HeaderPage,
    FooterCard,
    HeaderCard,
    SkeletonBox,
    PcaCardContainer,
    BoxQuantityItens,
    BoxValueEstimated,
    PcaPlanPageWrapper,
    LoadingIndicatorContainer,
} from './style';
import useHiringPlansContext from '../../hooks/useHiringPlans';
import DialogSyncPcaPncp from './components/dialog-sync-pca-pncp';
import DialogDeleteItemPca from './components/dialog-delete-item-pca';
import DialogNewEditItemPca from './components/dialog-new-edit-item-pca';

type ConsolidateItemsByCategoryType = {
    categoriaItemId?: number;
    quantidadeItens?: number;
    valorTotal?: number;
    categoriaItemNome?: string;
};

const PcaPlanPage = () => {
    const {
        hiringPlansList,
        setHiringPlansList,
        isOpenDialogCrudItemPca,
        isOpenDialogDeleteItemPca,
        onOpenDialogSyncPcaPncp,
        onOpenDialogCrudItemPca,
        onOpenDialogDeleteItemPca,
        hiringPlanItens,
        setHiringPlanItens,
        setItemEditId,
        setItemRemoveId,
        pcaStatusEnum,
        setPcaSyncId,
        listItemCategories,
        isOpenDialogSyncPcaPncp,
    } = useHiringPlansContext();
    const { setTitle } = usePageContext();
    const [isLoading, setIsLoading] = useState(true);
    const [hiringPlan, setHiringPlan] = useState<HiringPlanResponse>();
    const [errorOnGetData, setErrorOnGetData] = useState(false);
    const [consolidateItemsByCategory, setConsolidateItemsByCategory] =
        useState<Array<ConsolidateItemsByCategoryType>>();

    const { t } = useTranslation();
    const { pcaId, organizationUnitId } = useParams<{
        pcaId: string;
        organizationUnitId: string;
    }>();
    const organizationUnitName = hiringPlan
        ? hiringPlan.organizationUnits.organizationUnitName.toUpperCase()
        : '-';
    const inDraft = hiringPlan?.status === pcaStatusEnum.draft;
    const pcaIdNumber = Number.parseInt(pcaId, 10);
    const organizationUnitIdNumber = Number.parseInt(organizationUnitId, 10);
    const pcaIsSynchronized =
        hiringPlansList.find((hiringPlan) => hiringPlan.id === pcaIdNumber)?.status ===
        pcaStatusEnum.done;
    const skeletonTableItensArray = Array(5).fill('');

    const BreadcrumbsLinks = [
        { url: '/planos-contratacao', title: t('term.hiring-plans'), active: true },
        {
            url: '/planos-contratacao/:pcaAno',
            title: `PCA ${hiringPlan ? hiringPlan.year : '-'} - ${organizationUnitName}`,
            active: false,
        },
    ];

    const getListItensHiringPlan = async () => {
        setIsLoading(true);
        try {
            const response = await hiringPlansRequests.getListItensHiringPlan({
                pcaId: pcaIdNumber,
            });
            setHiringPlanItens(response);
            setErrorOnGetData(false);
        } catch (err) {
            setErrorOnGetData(true);
            addNotificationError({
                title: t('term.error'),
                message: t('hiring-plans.info.get-itens-unit-pca-error'),
            });
        } finally {
            setIsLoading(false);
        }
    };

    const getListAllHiringPlans = async () => {
        setIsLoading(true);
        try {
            const response = await hiringPlansRequests.getAllHiringPlans();
            setHiringPlansList(response);
            setErrorOnGetData(false);
        } catch (err) {
            setErrorOnGetData(true);
            addNotificationError({
                title: t('term.error'),
                message: t('hiring-plans.info.get-itens-unit-pca-error'),
            });
        } finally {
            setIsLoading(false);
        }
    };

    const editHiringPlanUnitItem = () => {
        onOpenDialogCrudItemPca();
    };

    const removeHiringPlanUnitItem = () => {
        onOpenDialogDeleteItemPca();
    };

    const synchronizeHiringPlanWithPncp = () => {
        onOpenDialogSyncPcaPncp();
    };

    const countTotalItemsUnit = (listItens?: Array<HiringPlanItensResponse>) =>
        listItens ? listItens.filter((currentItem) => currentItem.deletedAt === null).length : 0;

    const amountTotalItemsUnit = (listItens?: Array<HiringPlanItensResponse>) =>
        listItens ? listItens.reduce((acc, currentItem) => acc + currentItem.totalValue, 0) : 0;

    useEffect(() => {
        setHiringPlan(hiringPlansList.find((hiringPlan) => hiringPlan.id === pcaIdNumber));
    }, [hiringPlansList]);

    useEffect(() => {
        const consolidateCategory: Array<ConsolidateItemsByCategoryType> = [];
        const categoriesList = hiringPlanItens?.map((listItens) => listItens.pcaItemCategory);
        _.uniq(categoriesList).forEach((category) => {
            const categoryParseInt = Number.parseInt(category, 10);
            const itensListSameCategory = hiringPlanItens?.filter(
                (item) => item.pcaItemCategory === category
            );
            consolidateCategory.push({
                categoriaItemId: categoryParseInt,
                quantidadeItens: itensListSameCategory?.reduce(
                    (acc, currentItem) => acc + currentItem.quantity,
                    0
                ),
                valorTotal: itensListSameCategory?.reduce(
                    (acc, currentItem) => acc + currentItem.totalValue,
                    0
                ),
                categoriaItemNome: listItemCategories.find(
                    (category) => category.id === categoryParseInt
                )?.name,
            });
        });

        setConsolidateItemsByCategory(consolidateCategory);
    }, [hiringPlanItens]);

    useEffect(() => {
        setTitle(t('term.hiring-plans'));
        if (hiringPlansList.length === 0) {
            getListAllHiringPlans();
        }
        getListItensHiringPlan();

        return () => {
            setPcaSyncId(undefined);
            setItemEditId(undefined);
            setItemRemoveId(undefined);
            setConsolidateItemsByCategory(undefined);
            setHiringPlanItens([]);
        };
    }, []);

    return (
        <PcaPlanPageWrapper>
            {isOpenDialogSyncPcaPncp && <DialogSyncPcaPncp />}
            {isOpenDialogCrudItemPca && (
                <DialogNewEditItemPca
                    pcaId={pcaIdNumber}
                    organizationUnitId={organizationUnitIdNumber}
                />
            )}
            {isOpenDialogDeleteItemPca && <DialogDeleteItemPca />}
            <HeaderPage>
                <BreadcrumbsComponent links={BreadcrumbsLinks} />
            </HeaderPage>
            <PcaCard>
                <HeaderCard>
                    {isLoading && hiringPlanItens.length === 0 ? (
                        <Skeleton variant='text' animation='wave' width='60%' height='56px'>
                            <h1> - </h1>
                        </Skeleton>
                    ) : (
                        <h1>
                            PCA {hiringPlan?.year} - {organizationUnitName}
                        </h1>
                    )}
                    {inDraft && (
                        <Button
                            disabled={isLoading}
                            color='secondary'
                            size='small'
                            variant='contained'
                            style={{
                                height: 'fit-content',
                                padding: '11px 22px',
                                fontSize: '0.85rem',
                            }}
                            onClick={() => {
                                setPcaSyncId(hiringPlan.id);
                                synchronizeHiringPlanWithPncp();
                            }}
                        >
                            {t('hiring-plans.labels.synchronize-pca')}
                        </Button>
                    )}
                    {hiringPlan?.status === pcaStatusEnum.done && (
                        <Button
                            disabled
                            color='secondary'
                            size='small'
                            variant='contained'
                            style={{
                                height: 'fit-content',
                                padding: '11px 22px',
                                fontSize: '0.85rem',
                            }}
                            startIcon={<AiOutlineCheck size={20} />}
                        >
                            {t('hiring-plans.labels.synchronized-pca')}
                        </Button>
                    )}
                </HeaderCard>
                <PcaCardContainer>
                    <Box variant='outlined'>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                                padding: 0,
                            }}
                        >
                            <Typography color='textPrimary' variant='subtitle2'>
                                {isLoading && hiringPlanItens.length === 0 ? (
                                    <Skeleton />
                                ) : (
                                    <>
                                        {t('hiring-plans.labels.status')}:{' '}
                                        <Chip
                                            color={`${inDraft ? 'primary' : 'secondary'}`}
                                            size='small'
                                            label={inDraft ? t('term.draft') : t('term.concluded')}
                                        />
                                    </>
                                )}
                            </Typography>
                            {isLoading && hiringPlanItens.length === 0 ? (
                                <SkeletonBox variant='rect' animation='wave' />
                            ) : (
                                <BoxValueEstimated variant='outlined'>
                                    <p>{t('hiring-plans.title.value-estimated-title')}</p>
                                    <h2>{formatCurrency(amountTotalItemsUnit(hiringPlanItens))}</h2>
                                </BoxValueEstimated>
                            )}
                            {isLoading && hiringPlanItens.length === 0 ? (
                                <SkeletonBox variant='rect' animation='wave' />
                            ) : (
                                <BoxQuantityItens variant='outlined'>
                                    <p>{t('hiring-plans.title.quantity-of-pca-items')}</p>
                                    <h2>
                                        {`${countTotalItemsUnit(hiringPlanItens)} ${t(
                                            'hiring-plans.title.units'
                                        )}`}
                                    </h2>
                                </BoxQuantityItens>
                            )}
                        </div>
                    </Box>
                    <Box variant='outlined' style={{ width: '100%', height: '100%' }}>
                        {isLoading && hiringPlanItens.length === 0 ? (
                            <SkeletonBox variant='rect' animation='wave' height='400px' />
                        ) : (
                            <ChartItemCountAndTotalAmountByPCA
                                key='chart-item-count-and-total-amount'
                                consolidatedData={consolidateItemsByCategory}
                            />
                        )}
                    </Box>
                </PcaCardContainer>
                <FooterCard>
                    {isLoading && !hiringPlan ? (
                        <Skeleton variant='text' animation='wave' width='25%' height='18px'>
                            <p> - </p>
                        </Skeleton>
                    ) : (
                        <p>{`${t('hiring-plans.labels.last-created-at')} ${
                            hiringPlan && formatDate(hiringPlan.createdAt, 'L LT')
                        }`}</p>
                    )}
                    <span>
                        <Button
                            style={{ visibility: 'hidden', display: 'none' }}
                            startIcon={<AiOutlineDownload />}
                            variant='outlined'
                            color='secondary'
                            title={t('hiring-plans.title.download-list-of-items')}
                        >
                            {t('hiring-plans.title.list-of-items')}&nbsp;
                            <span style={{ fontSize: '8px' }}>&nbsp;(.csv)</span>
                        </Button>
                    </span>
                </FooterCard>
            </PcaCard>
            <PcaCard style={{ marginTop: '16px' }}>
                <HeaderCard>
                    <h1>{t('hiring-plans.title.hiring-plan-itens-details')}</h1>
                    <Button
                        disabled={isLoading}
                        color='primary'
                        size='small'
                        variant='contained'
                        style={{
                            height: 'fit-content',
                            padding: '11px 22px',
                            fontSize: '0.85rem',
                        }}
                        onClick={onOpenDialogCrudItemPca}
                    >
                        {t('hiring-plans.labels.new-item-pca')}
                    </Button>
                </HeaderCard>
                <PcaCardContainer>
                    <TableContainer>
                        <Table size='small'>
                            <TableHead>
                                <TableRow>
                                    <Th>{t('hiring-plans.title.description')}</Th>
                                    <Th style={{ width: '176px' }}>
                                        {t('hiring-plans.labels.last-created-at')}
                                    </Th>
                                    <Th style={{ width: '160px' }}>
                                        {t('hiring-plans.title.quantity-amount')}
                                    </Th>
                                    <Th style={{ width: '208px' }}>
                                        {t('hiring-plans.title.total-value')}
                                    </Th>
                                    <Th style={{ width: '128px' }} align='center' />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading &&
                                    !errorOnGetData &&
                                    hiringPlanItens.length === 0 &&
                                    skeletonTableItensArray.map((skeletonItem, idx) => (
                                        <TableRow key={`skeleton-row-${idx + 1}`}>
                                            <TableCell>
                                                <Skeleton variant='text' />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant='text' />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant='text' />
                                            </TableCell>
                                            <TableCell>
                                                <Skeleton variant='text' />
                                            </TableCell>
                                            <TableCell align='center'>
                                                <Stack direction='row'>
                                                    <Skeleton variant='circle'>
                                                        <IconButton
                                                            disabled
                                                            size='small'
                                                            color='primary'
                                                        >
                                                            <AiOutlineEdit />
                                                        </IconButton>
                                                    </Skeleton>
                                                    <Skeleton variant='circle'>
                                                        <IconButton
                                                            disabled
                                                            size='small'
                                                            color='primary'
                                                        >
                                                            <AiOutlineDelete />
                                                        </IconButton>
                                                    </Skeleton>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {!errorOnGetData &&
                                    hiringPlanItens &&
                                    hiringPlanItens.length > 0 &&
                                    hiringPlanItens.map((item) => (
                                        <TableRow key={`item-${item.id}`}>
                                            <TableCell>{item.description}</TableCell>
                                            <TableCell>
                                                {formatDate(item.createdAt, 'L LT')}
                                            </TableCell>
                                            <TableCell>{item.quantity}</TableCell>
                                            <TableCell>{formatCurrency(item.totalValue)}</TableCell>
                                            <TableCell align='center'>
                                                <IconButton
                                                    disabled={isLoading}
                                                    size='medium'
                                                    color='primary'
                                                    onClick={() => {
                                                        setItemEditId(item.id);
                                                        editHiringPlanUnitItem();
                                                    }}
                                                >
                                                    <AiOutlineEdit />
                                                </IconButton>
                                                <IconButton
                                                    disabled={isLoading || pcaIsSynchronized}
                                                    size='medium'
                                                    onClick={() => {
                                                        setItemRemoveId(item.id);
                                                        removeHiringPlanUnitItem();
                                                    }}
                                                >
                                                    <AiOutlineDelete color='var(--danger-color)' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {!isLoading &&
                                    !errorOnGetData &&
                                    hiringPlanItens &&
                                    hiringPlanItens.length === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                <BlankArea
                                                    border
                                                    minHeight
                                                    style={{ width: '100%', height: '256px' }}
                                                >
                                                    <h1>
                                                        {t(
                                                            'hiring-plans.info.chart-empty-list-items-pca'
                                                        )}
                                                    </h1>
                                                    <h2>
                                                        {t(
                                                            'hiring-plans.info.table-empty-list-items-pca-instruction',
                                                            {
                                                                button: t(
                                                                    'hiring-plans.labels.new-item-pca'
                                                                ),
                                                            }
                                                        )}
                                                    </h2>
                                                </BlankArea>
                                            </TableCell>
                                        </TableRow>
                                    )}
                            </TableBody>
                        </Table>
                        {isLoading &&
                            !errorOnGetData &&
                            hiringPlanItens &&
                            hiringPlanItens.length > 0 && (
                                <LoadingIndicatorContainer style={{ marginTop: '24px' }}>
                                    <CircularProgress color='primary' size={24} />
                                    <Typography color='primary' variant='subtitle2'>
                                        {t('hiring-plans.info.inform-loading-pcas')}
                                    </Typography>
                                </LoadingIndicatorContainer>
                            )}
                    </TableContainer>
                </PcaCardContainer>
            </PcaCard>
        </PcaPlanPageWrapper>
    );
};

export default memo(PcaPlanPage);
