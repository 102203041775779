import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addNotificationApiError, addNotificationSuccess } from 'common/utils';
import { usePageContext } from 'common/components/base/pages/context';
import { marketplaceRequests } from 'modules/marketplace/services/marketplace.requests';
import { useMarketplaceContext } from 'modules/marketplace/context/marketplace.context';
import { isFinite } from 'lodash';
import { useHistory } from 'react-router';
import { CreateMarketplaceOrderBody } from 'modules/marketplace/interfaces/marketplace.interface';
import { useModalActions } from 'common/hooks/use-modal.hook';
import CartPageView from './cart-page-view';

const CartPage = () => {
    const history = useHistory();
    const { setTitle } = usePageContext();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const { state: additionalInformationIsVisible, open, close } = useModalActions();

    const { onAddToCart, onRemoveFromCart, onItemQuantityChange, clearCart, marketplaceCart } =
        useMarketplaceContext();

    const onCreateOrder = async (values: CreateMarketplaceOrderBody) => {
        try {
            setLoading(true);
            const response = await marketplaceRequests.createMarketplaceOrder(values);

            if (isFinite(response.data.id)) {
                history.push(`/mercado/pedidos/julgamento/${response.data.id}`);
                addNotificationSuccess({
                    message: t('marketplace.success-order-creation'),
                    title: t('term.success'),
                });
            }
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            clearCart();
            setLoading(false);
        }
    };

    useEffect(() => {
        setTitle(t('term.cart'));
    });

    return (
        <CartPageView
            marketplaceCart={marketplaceCart}
            cartItems={marketplaceCart?.items}
            onAddToCart={onAddToCart}
            onRemoveFromCart={onRemoveFromCart}
            onItemQuantityChange={onItemQuantityChange}
            onCreateOrder={onCreateOrder}
            isCreating={loading}
            additionalInformationIsVisible={additionalInformationIsVisible}
            onOpenAdditionalInformation={open}
            onCloseAdditionalInformation={close}
        />
    );
};

export default CartPage;
