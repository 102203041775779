import { FC, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { contractActions } from 'modules/process/document-utils';
import { PlainGenericResponse } from 'clients/interfaces/paginated.interface';
import { processUtils } from 'modules/process/process-utils';
import LoadingButton from '../../../loading-button';
import { contractRequests } from '../../../../../clients/manager/contract.requests';
import {
    addNotificationApiError,
    addNotificationSuccess,
    addNotificationWarning,
    getBackofficeRedirectUrl,
    sleep,
} from '../../../../utils';
import {
    ContractStatus,
    ContractWithDetails,
    SignatureMethod,
} from '../../../../../clients/manager/interfaces/contract.interface';
import { ProcessDocumentsStepsType } from '../../../../../clients/manager/interfaces/process-documents-steps.interface';
import { redirectToGenerateDocument } from '../../redirect-generate-document';
import { Button } from '../../../../theme/components';

interface ContractActionsProps {
    contractWithDetails: ContractWithDetails;
    onContractUpdated: (contract: ContractWithDetails) => void;
    formIsValid: boolean;
}

const ContractActions: FC<ContractActionsProps> = ({
    contractWithDetails,
    onContractUpdated,
    formIsValid,
}) => {
    const [sendingToSignatures, setSendingToSignatures] = useState(false);
    const [sendingToPncp, setSendingToPncp] = useState(false);
    const [generatingDocument, setGeneratingDocument] = useState(false);
    const { t } = useTranslation();
    const backofficeRedirectUrlPath = getBackofficeRedirectUrl();
    const { contract, process, processDocumentsStep, documentSigners, generatedDocument } =
        contractWithDetails;
    const canSendPncp = contractActions.canSendPncp(
        process,
        contract,
        documentSigners?.signers,
        generatedDocument?.file?.location
    );

    const handleSendToSignature = async () => {
        if (!generatedDocument?.file?.location) {
            return;
        }

        try {
            setSendingToSignatures(true);
            const response = await contractRequests.doSendContractToSignatures(contract.id);

            setSendingToSignatures(false);
            onContractUpdated({
                ...contractWithDetails,
                contract: {
                    ...contract,
                    ...response.data.contract,
                },
                documentSigners: response.data.documentSigners,
                generatedDocument: {
                    ...generatedDocument,
                    ...response.data.generatedDocument,
                },
            });
            addNotificationSuccess({
                message: t('info.contract-sent-signatures'),
                title: t('term.success'),
            });
        } catch (error) {
            addNotificationApiError(error);
        }

        setSendingToSignatures(false);
    };

    const handleSendToPncp = async () => {
        if (!canSendPncp) {
            addNotificationWarning({
                message: t('info.contract-not-sent-pncp'),
                title: t('term.warning'),
            });
            return;
        }

        try {
            setSendingToPncp(true);
            let response: PlainGenericResponse<{
                pncpLink: string;
                status: ContractStatus;
            }>;
            if (contract.contractOriginId) {
                response = await contractRequests.doSendContractTermToPncp(contract.id);
            } else {
                response = await contractRequests.doSendContractToPncp(contract.id);
            }

            if (response?.data.status) {
                setSendingToPncp(false);
                onContractUpdated({
                    ...contractWithDetails,
                    contract: {
                        ...contract,
                        ...response.data,
                    },
                });
                addNotificationSuccess({
                    message: t('info.contract-sent-pncp'),
                    title: t('term.success'),
                });
            }
        } catch (error) {
            addNotificationApiError(error);
        }

        setSendingToPncp(false);
    };

    const visibleGenerateDocument =
        !generatedDocument?.file?.location &&
        (contract.status === ContractStatus.draft ||
            contract.status === ContractStatus.generated) &&
        processDocumentsStep?.type === ProcessDocumentsStepsType.document;

    const disabledGenerateDocument = !formIsValid;

    const handleClickGenerateDocument = async (regenerate: boolean) => {
        if (disabledGenerateDocument) {
            return;
        }

        setGeneratingDocument(true);
        await redirectToGenerateDocument(contract.id, regenerate, backofficeRedirectUrlPath);
        await sleep(5000);

        const response = await contractRequests.getContract(contract.id);
        onContractUpdated(response.data);
        setGeneratingDocument(false);
    };

    const getSendToSignatureButtonRender = () => {
        if (visibleGenerateDocument || contract.signtureMethod !== SignatureMethod.internal) {
            return null;
        }

        if (generatedDocument?.sentForSignatureAt) {
            return (
                <Typography variant='body2'>
                    {t('info.sent-for-signature')}:{' '}
                    {moment(generatedDocument?.sentForSignatureAt).format('DD/MM/YYYY HH:mm:ss')}
                </Typography>
            );
        }

        return (
            <LoadingButton
                size='small'
                disabled={!generatedDocument?.file?.location}
                variant='contained'
                onClick={handleSendToSignature}
                {...(sendingToSignatures
                    ? {
                          loading: {
                              text: `${t('term.sending')}..`,
                          },
                      }
                    : {})}
            >
                {t('info.send-for-signature')}
            </LoadingButton>
        );
    };

    return (
        <Box width={1} height={1} display='flex' justifyContent='flex-end' alignItems='center'>
            {visibleGenerateDocument && (
                <LoadingButton
                    disabled={disabledGenerateDocument}
                    size='small'
                    variant='contained'
                    onClick={() => handleClickGenerateDocument(false)}
                    {...(generatingDocument
                        ? {
                              loading: {
                                  text: `${t('term.generating')}..`,
                              },
                          }
                        : {})}
                >
                    {t('info.generate-doc')}
                </LoadingButton>
            )}
            {getSendToSignatureButtonRender()}
            {contractActions.showPncpLink(process, contract) && (
                <Button
                    size='small'
                    onClick={() => {
                        window.open(contract.pncpLink, '_blank');
                    }}
                    variant='text'
                    style={{ marginLeft: 16 }}
                >
                    {t('info.pncp-link')}
                </Button>
            )}
            {processUtils.isNewLaw(process?.legalSupportId) && (
                <LoadingButton
                    size='small'
                    disabled={!canSendPncp}
                    variant='contained'
                    style={{ marginLeft: 16 }}
                    onClick={handleSendToPncp}
                    {...(sendingToPncp
                        ? {
                              loading: {
                                  text: `${t('term.sending')}..`,
                              },
                          }
                        : {})}
                >
                    {t('info.send-to-pncp')}
                </LoadingButton>
            )}
        </Box>
    );
};

export default ContractActions;
