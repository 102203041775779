import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { GrCreditCard } from 'react-icons/gr';
import { LiaBarcodeSolid } from 'react-icons/lia';
import { PaymentStrategy } from 'common/enums';
import { Skeleton } from '@material-ui/lab';
import CreditCard from './components/credit-card';
import Accordion from './components/accordion';
import { CheckoutFormValues } from '../../../checkout-page';

interface PaymentViewProps {
    form: FormikProps<CheckoutFormValues>;
    loading?: boolean;
}

const PaymentView: React.FC<PaymentViewProps> = ({ form, loading = false }) => {
    const { t } = useTranslation();

    const handleChangePaymentType = (type?: string) => {
        form.setFieldValue('type', type);
    };

    if (loading) {
        return (
            <div>
                <Skeleton variant='rect' width='100%' height={88} />
                <Box width={0} height={25} />
                <Skeleton variant='rect' height={88} />
            </div>
        );
    }

    return (
        <div>
            <Accordion
                title={t('term.credit-card')}
                onChange={handleChangePaymentType}
                open={form.values.type === PaymentStrategy.asaas_credit_card}
                value={PaymentStrategy.asaas_credit_card}
                icon={<GrCreditCard size={30} color='inhert' />}
            >
                <CreditCard form={form} />
            </Accordion>
            {/* <Accordion
                title={t('term.pix')}
                onChange={handleChangePaymentType}
                open={form.values.type === PaymentType.PIX}
                value={PaymentType.PIX}
                icon={<FaPix size={30} color='inhert' />}
            >
                <Typography>{t('marketplace.checkout.pix-description')}</Typography>
            </Accordion> */}
            <Accordion
                title='Boleto'
                onChange={handleChangePaymentType}
                open={form.values.type === PaymentStrategy.asaas_boleto}
                value={PaymentStrategy.asaas_boleto}
                icon={<LiaBarcodeSolid size={30} color='inhert' />}
            >
                <Typography>{t('marketplace.checkout.boleto-description')}</Typography>
            </Accordion>
        </div>
    );
};

export default PaymentView;
