import { AuctionTypeEnum } from 'common/enums';
import { RenderDocType } from '.';

const getMinutes = (): RenderDocType => {
    const processTypes = Object.keys(AuctionTypeEnum);
    const processTypesWithoutAccreditation = processTypes.filter((e) => e !== 'accreditation');

    return {
        langKey: 'bidding.process.section.documents.minutes',
        loading: false,
        data: [
            {
                id: 1,
                langKey: 'info.publication-extract-minute',
                openDoc: 'minutePublicationExtract',
                type: 'PDF',
                processTypes: processTypes,
            },
            {
                id: 2,
                langKey: 'info.dispute-room-minute',
                openDoc: 'minuteDisputeRoom',
                type: 'PDF',
                processTypes: processTypesWithoutAccreditation,
            },
            {
                id: 3,
                langKey: 'info.proposals-minute',
                openDoc: 'minuteOfProposals',
                type: 'PDF',
                processTypes: processTypesWithoutAccreditation,
            },
            {
                id: 4,
                langKey: 'info.enabled-suppliers',
                openDoc: 'enableSuppliers',
                type: 'PDF',
                processTypes: processTypesWithoutAccreditation,
            },
            {
                id: 5,
                langKey: 'info.adjudication-term',
                openDoc: 'adjudicationTerm',
                type: 'PDF',
                processTypes: processTypesWithoutAccreditation,
            },
            {
                id: 6,
                langKey: 'info.homologation-term',
                openDoc: 'homologationTerm',
                type: 'PDF',
                processTypes: processTypesWithoutAccreditation,
            },
            {
                id: 7,
                langKey: 'info.accreditation-minute',
                openDoc: 'minuteAccreditation',
                type: 'PDF',
                processTypes: ['accreditation'],
            },
            {
                id: 8,
                langKey: 'info.desert-minute',
                openDoc: 'minuteDesert',
                type: 'PDF',
                processTypes: processTypesWithoutAccreditation,
            },
            {
                id: 9,
                langKey: 'info.failed-minute',
                openDoc: 'minuteFrustrated',
                type: 'PDF',
                processTypes: processTypesWithoutAccreditation,
            },
        ],
    };
};

export default getMinutes;
