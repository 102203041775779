import {
    ListContractsBodyData,
    ListContractsFromProcessResponse,
} from 'clients/manager/interfaces/contract.interface';
import React, { createContext, ReactNode, useContext, useState } from 'react';

type ContractsContextType = {
    contracts: ListContractsFromProcessResponse[] | [];
    setContracts: React.Dispatch<React.SetStateAction<ListContractsFromProcessResponse[] | []>>;
    requestsCount: number;
    setRequestsCount: React.Dispatch<React.SetStateAction<number>>;
    requestFilters: ListContractsBodyData;
    setRequestFilters: React.Dispatch<React.SetStateAction<ListContractsBodyData>>;
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    handleChangePage: any;
    handleChangeContractsPerPage: any;
    handleChangeFilter: any;
    refreshAndResetTable: Function;
    refreshContracts(): void;
};

const initialBody = {
    limit: 10,
    offset: 0,
    params: {
        searchedText: '',
    },
};

export const SearchContractsContext = createContext<ContractsContextType>({
    contracts: [],
    setContracts: () => {},
    requestsCount: 0,
    setRequestsCount: () => {},
    requestFilters: initialBody,
    setRequestFilters: () => {},
    page: 0,
    setPage: () => {},
    handleChangePage: () => {},
    handleChangeContractsPerPage: () => {},
    handleChangeFilter: () => {},
    refreshAndResetTable: () => {},
    refreshContracts: () => {},
});

export const SearchContractsContextProvider = ({ children }: { children: ReactNode }) => {
    const [contracts, setContracts] = useState<ListContractsFromProcessResponse[] | []>([]);

    const [requestsCount, setRequestsCount] = useState<number>(0);
    const [requestFilters, setRequestFilters] = useState<ListContractsBodyData>(initialBody);
    const [page, setPage] = useState<number>(0);

    const refreshAndResetTable = () => {
        setRequestFilters(initialBody);
        setPage(0);
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
        setRequestFilters((prevState) => ({
            ...prevState,
            offset: (requestFilters.limit || 0) * newPage,
        }));
    };

    const handleChangeContractsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRequestFilters((prevState) => ({
            ...prevState,
            limit: parseInt(event.target.value, 10),
        }));
        setPage(0);
    };

    const handleChangeFilter = (event) => {
        if (event.key === 'Enter') {
            setRequestFilters({
                limit: 10,
                offset: 0,
                params: {
                    searchedText: event.target.value,
                },
            });
        }
    };

    const refreshContracts = () => {
        setRequestFilters((state) => ({ ...state }));
    };

    return (
        <SearchContractsContext.Provider
            value={{
                contracts,
                setContracts,
                requestsCount,
                setRequestsCount,
                requestFilters,
                setRequestFilters,
                page,
                setPage,
                handleChangePage,
                handleChangeContractsPerPage,
                handleChangeFilter,
                refreshAndResetTable,
                refreshContracts,
            }}
        >
            {children}
        </SearchContractsContext.Provider>
    );
};

export const useSearchContractsContext = () => useContext(SearchContractsContext);
